























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { MARITAL_STATUS, MARITAL_STATUS_UNMARRIED, REFINANCE_EFFORT, RESIDENCE_BASIS_TYPE, UNMARRIED_RELATIONSHIP, MISMO_TAXPAYER_IDENTIFICATION_TYPE, MILITARY_STATUS_TYPE } from "@/constants";
import Address from "@/views/LosAddress.vue";
import LOSMixin from "@/mixins/los.mixin";
import { Dependents } from "@/models/pos/borrower-info.model";
import EmploymentHistory from "./EmploymentHistory.vue";
import Datepicker from "vuejs-datepicker";
import _ from "lodash";

@Component({ components: { Address, EmploymentHistory, Datepicker } })
export default class BasicInfoComponent extends mixins(LOSMixin) {
  @Prop()
  public marriedTo;

  @Prop()
  public borrowerInfo;

  @Prop()
  public activeIndex;

  @Prop()
  public primaryBorrowerIndex;

  @Prop()
  public borrowerList;

  @Prop()
  public coBorrowerIndex;

  @Prop()
  public isSubmitToWemlo;

  @Prop() public loanType;

  @Prop({default: false})
  public loanIsLocked: boolean;

  public residenceType = RESIDENCE_BASIS_TYPE;
  public maritalStatus = MARITAL_STATUS;
  public maritalStatusUnmarried = MARITAL_STATUS_UNMARRIED;
  public UnmarriedRelationship = UNMARRIED_RELATIONSHIP;
  public taxpayerIdentificationType = MISMO_TAXPAYER_IDENTIFICATION_TYPE;
  public militaryStatusType = MILITARY_STATUS_TYPE;

  public refinaceEffort = REFINANCE_EFFORT;
  public name = null;
  public changeRelationStatus = false;

  public get stateList() {
    return this.$store.state.sessionObject.states;
  }

  showMilitaryType(borrower) {

    /**
     * The rule is that the Military Service Type field is only available when
     * The loan type is VA, the borrower is a self declared military
     */

    const isVA = this.loanType === 'VA';
    const isSelfDeclaredMilitary = _.get(borrower, 'personalInfo.militaryService.selfDeclaredMilitaryServiceIndicator', false);

    return isVA && isSelfDeclaredMilitary;


  }

  isBoolean(value) {
    return _.isBoolean(value);
  }

  get dependentsOptions() {
    const number = [];

    for (let i: any = 1; i < 13; i++) {
      number.push(i);
    }

    return number;
  }

  public async isMarriedToOther() {
    if(!this.borrowerInfo[this.activeIndex].personalInfo.maritalStatus.status) return;
    if (this.borrowerInfo[this.activeIndex].personalInfo.maritalStatus.status.name != "Married") {
      this.borrowerInfo[this.activeIndex].personalInfo.isMarriedTo.firstName = null;
      this.borrowerInfo[this.activeIndex].personalInfo.isMarriedTo.middleName = null;
      this.borrowerInfo[this.activeIndex].personalInfo.isMarriedTo.lastName = null;
    }
  }

  public changeRelation() {
    try {
      if (this.borrowerInfo[this.activeIndex].personalInfo.relationWithBorrower != "Spouse") {
        this.borrowerInfo[this.activeIndex].personalInfo.jointWithCoBorrowerIndex = null;
        this.borrowerInfo[this.activeIndex].personalInfo.maritalStatus.status = null;
        this.changeRelationStatus = true;
      } else {
        this.borrowerInfo[this.activeIndex].personalInfo.maritalStatus.status = {
          name: "Married",
          ediValue: "M",
          mismoValue: "Married",
          value: "Married"
        };
      }
    } catch (error) {
      console.log(error);
    }
  }

  public addDependents(number) {
    let length = this.borrowerInfo[this.activeIndex].personalInfo.dependents.details.length;
    if (number > length) {
      let count = number - length;
      for (let i = 0; i < count; i++) {
        let dependents = JSON.parse(JSON.stringify(new Dependents()));
        this.borrowerInfo[this.activeIndex].personalInfo.dependents.details.push(dependents);
      }
    }else{
      for (let i = length; number <= i; i--)
        this.borrowerInfo[this.activeIndex].personalInfo.dependents.details.splice(i, 1);
    }
  }

  public removeDependents(dependentsIndex) {
    this.borrowerInfo[this.activeIndex].personalInfo.dependents.details.splice(dependentsIndex, 1);
    this.borrowerInfo[this.activeIndex].personalInfo.dependents.number = this.borrowerInfo[this.activeIndex].personalInfo.dependents.details.length > 0 ? this.borrowerInfo[this.activeIndex].personalInfo.dependents.details.length : null;
  }

  public get canShowMarriedTo() {
    return this.marriedTo && (this.coBorrowerIndex || !this.changeRelationStatus);
  }

  setDependentsNumber() {
    this.borrowerInfo[this.activeIndex].personalInfo.dependents.number = this.borrowerInfo[this.activeIndex].personalInfo.dependents.details.length;
  }

  public setMailingAddress() {
    this.$store.commit('SET_WEMLO_LOADER', true);
    this.borrowerInfo[this.activeIndex].personalInfo.address.mailingAddress = _.cloneDeep(this.borrowerInfo[this.activeIndex].personalInfo.address.currentAddress.address);
    this.$nextTick(() => {
      this.$store.commit('SET_WEMLO_LOADER', false);
    });
  }

  public reSetMailingAddress() {
    this.borrowerInfo[this.activeIndex].personalInfo.address.mailingAddress = {street: null, unit: null,city: null,state: null,zip: null,county: null,country: null};
  }
  
  public getGrossMonthlyIncome(data){
    let total = 0;
    Object.values(data.grossMonthlyIncome).forEach((element: any) => {
      total = total + parseFloat(element.monthlyIncome);
    });
    return total;
  }

  public async validateAll() {

    const employmentHistoryComponent = _.get(this.$refs, 'employmentHistory', null);

    if (employmentHistoryComponent) {
      try {
        employmentHistoryComponent.validateIncomeValues();
      } catch (error) {
        console.error(error);
      }
    }

    return await this.$validator.validateAll();
  }

}
