




































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";

@Component
export default class CustomInput extends Vue {
   public errorName=null;
    @Prop()
    public datavvas;
    @Prop()
    public origin;
    @Prop()
    public type;
    @Prop()
    public inputClass;
    @Prop()
    public name;
    @Prop()
    public disabled;
    @Prop()
    public vmodel;
    @Prop()
    public validate;
    @Prop()
    public isSelectField;
    @Prop()
    public typeConstant;
    @Prop()
    public isSubmitToWemlo;

    public async validateAll() {
        let valid = await this.$validator.validateAll();
        if (!valid) {
        return false;
        } else {
        return true;
        }
    }
public emptyError(){
        this.errorName=null;
        this.validateAll();
    }
public showError(){
    this.errorName = this.name;
    this.validateAll()
}


  mounted() {  
  }
}
