

import Axios from "axios";
import { Component, Watch, Prop } from "vue-property-decorator";
import { DEMOGRAPHY_CHANNEL_TYPE, MISMO_DEMOGRAPHY_CHANNEL_TYPE, DEMOGRAPHIC_EHINICITY,DEMOGRAPHIC_GENDER,DEMOGRAPHIC_RACE, MISMO_DEMOGRAPHIC_GENDER } from "@/constants";
import { BASE_API_URL } from "@/config";
import LosRequiredField from "@/views/LosRequiredField.vue";
import { DemographicInformation, EthnicityData, RaceData, SubType } from "@/models/pos/demographic-info.model";
import _ from "lodash";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
import {mixins} from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";

@Component({
  components: {
    LosRequiredField
  }
})
export default class DemographicComponent extends mixins(LOSMixin, SaveInformationMixin){
  @Prop({default: false})
  public isMismo: boolean;
  @Prop()
  public borrowerCoBorrowerIndex: number;
  @Prop()
  public isSubmitToWemlo: boolean;
  @Prop()
  public loanIsLocked;
  
  public isFieldMissing = false;
  public borrowerData: any = null;
  public borrowerIndex: any = null;
  public demographicData: any = null;
  private initialState: any = {};
  public monitoringRequiredList = [];
  public gender: any = {};
  public demographicChanneltype: any = {};
  public ethnicity = _.clone(DEMOGRAPHIC_EHINICITY);
  public race = _.clone(DEMOGRAPHIC_RACE);
  public asianSubTypes = ['asianIndian','chinese','filipino','japanese','korean','vietnamese','otherAsian'];
  public pacificIslanderSubTypes = ['nativeHawaiian','guamanianOrChamorro','samoan','otherPacificIslander'];
  public hispanicOrLatinoSubTypes = ['mexican','puertoRican','cuban','other'];

  public async getInformation() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "los/getDemographicData",
        { loanTxnId: this.$route.query.id });
      this.borrowerData = response.data.borrowerData;
      await response.data.demographicData.map(demographicData => this.createFrontObject(demographicData));
      this.demographicData = await this.checkException(response.data.demographicData);
      this.changeBorrower(this.borrowerCoBorrowerIndex ? this.borrowerCoBorrowerIndex : this.borrowerData.primaryBorrowerIndex);
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

/*********************************************************
 *  If Api response is null or Have Exception.
 */
  public async checkException(data){
    try{
      let demographic:any = new DemographicInformation();
      demographic = demographic.borrowerInfo[0];
      if(!data){
        data = [];
        this.borrowerData.relationshipMapping.forEach(el=> {
          data.push(demographic);
          if(el.coBorrowerIndex.length>0)
            data.push(demographic);
        });
      }
      let haveException = data.filter(a=> !a);
      if(haveException.length>0)
        data.forEach((el,index)=>{ if(!el) data[index] = demographic;});
      data.forEach(element => {
        if(!element.race)
          element.race = {name: null, ediValue: null, mismoValue: null};
        if(!element.ethnicity)
        element.ethnicity = {name: null, ediValue: null, mismoValue: null};
      });
    }catch(error){
      console.log(error);
    }
    return data;
  }

  public async createFrontObject(demographicData) {
    demographicData.ethnicity = this.createEthnicityFrontObject(demographicData);
    demographicData.race = this.createRaceFrontObject(demographicData);
    return demographicData;
  }

  private createEthnicityFrontObject(demographicData) {
    let ethnicity = {
      isHispanicOrLatino: !!_.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'HispanicOrLatino'),
      notHispanicOrLatino: !!_.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'NotHispanicOrLatino'),
      notApplicable: !!_.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'NotApplicable'),
      informationNotProvidedByApplicantMailInternetOrTelephoneApplication: !!_.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication'),
      mexican: false,
      puertoRican: false,
      cuban: false,
      other: false,
      description: null
    };
    if(ethnicity.isHispanicOrLatino) {
      const hispanicOrLatino = _.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'HispanicOrLatino');
      this.hispanicOrLatinoSubTypes.forEach(subType => {
        ethnicity[subType] = !!_.get(hispanicOrLatino, 'subtypes', []).find(subtype => subtype.name == subType);
        if(ethnicity.other) ethnicity.description = _.get(_.get(hispanicOrLatino, 'subtypes', []).find(subtype => subtype.name == 'other'), 'description', null);
      });
    }
    return ethnicity;
  }

  private createRaceFrontObject(demographicData) {
    let race = {
      americanIndianOrAlaskaNative: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'AmericanIndianOrAlaskaNative'),
      isAsian: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'Asian'),
      blackOrAfrican: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'BlackOrAfricanAmerican'),
      isNativeHawaiianOrOtherPacific: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'NativeHawaiianOrOtherPacificIslander'),
      white: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'White'),
      informationNotProvidedByApplicantMailInternetOrTelephoneApplication: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication'),
      notApplicable: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'NotApplicable'),
      americanIndianOrAlaskaNativeDescription: null,
      asianIndian: false,
      chinese: false,
      filipino: false,
      japanese: false,
      korean: false,
      vietnamese: false,
      otherAsian: false,
      asianDescription: null,
      nativeHawaiian: false,
      guamanianOrChamorro: false,
      samoan: false,
      otherPacificIslander: false,
      otherPacificIslanderDescription: null
    };
    if(race.americanIndianOrAlaskaNative) {
      const americanIndianOrAlaskaNative = _.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'AmericanIndianOrAlaskaNative');
      race.americanIndianOrAlaskaNativeDescription = _.get(_.get(americanIndianOrAlaskaNative, 'subtypes', []).find(subtype => subtype.description != null), 'description', null);
    }
    if(race.isAsian) {
      const isAsian = _.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'Asian');
      this.asianSubTypes.forEach(subType => {
        race[subType] = subType == 'otherAsian' ? !!_.get(isAsian, 'subtypes', []).find(subtype => subtype.name == 'other') : !!_.get(isAsian, 'subtypes', []).find(subtype => subtype.name == subType);
        if(race.otherAsian) race.asianDescription = _.get(_.get(isAsian, 'subtypes', []).find(subtype => subtype.name == 'other'), 'description', null);
      });
    }
    if(race.isNativeHawaiianOrOtherPacific) {
      const isNativeHawaiianOrOtherPacific = _.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'NativeHawaiianOrOtherPacificIslander');
      this.pacificIslanderSubTypes.forEach(subType => {
        race[subType] = subType == 'otherPacificIslander' ? !!_.get(isNativeHawaiianOrOtherPacific, 'subtypes', []).find(subtype => subtype.name == 'other') : !!_.get(isNativeHawaiianOrOtherPacific, 'subtypes', []).find(subtype => subtype.name == subType);
        if(race.otherPacificIslander) race.otherPacificIslanderDescription = _.get(_.get(isNativeHawaiianOrOtherPacific, 'subtypes', []).find(subtype => subtype.name == 'other'), 'description', null);
      });
    }
    return race;
  }

    public getUpdateRequestBody() {
        return {
            loanTxnId: this.$route.query.id,
            demographicData: this.demographicData
        }
    }

  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {

      this.saveInProcess = true;

      await this.$validator.validateAll();

      this.demographicData.map(demographicData => this.createBackendObject(demographicData));
      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "los/saveDemographicInfo",
          requestBody
      );

      if(tosty){
        this.$snotify.clear();
        this.$snotify[response.data.statusCode == 200 ? "success" : "error"](response.data.message);
      }
      if (response.data.statusCode === 200) {
        this.initialState = this.cloneObject(requestBody);
        this.$emit("saveEvent");
      }
      if (!fromParent && this.$parent["checkLosFormCompleted"]) this.$parent["checkLosFormCompleted"](true);

      return {
        status: true,
        requiredFields: response.data.monitoringError || [],
      };
    } catch (error) {
      console.log(error);
      return { status: false };
    } finally {
      this.$store.state.wemloLoader = false;
      this.saveInProcess = false;
    }
  }

  private createBackendObject(demographicData) {
    demographicData.ethnicities = this.createEthnicityBackendObject(demographicData);
    demographicData.races = this.createRaceBackendObject(demographicData);
    return demographicData;
  }

  private createRaceBackendObjectAsian(demographicData) {
    let race = new RaceData();
    race.race = this.race.asian;
    this.asianSubTypes.forEach(subType => {
      if(demographicData.race[subType]) {
        let subTypeObj = new SubType();
        subTypeObj.name = subType;
        if(subTypeObj.name == 'otherAsian') {
          subTypeObj.name = 'other';
          subTypeObj.description = demographicData.race.asianDescription;
        }
        race.subtypes.push(subTypeObj);
      }
    });
    return race;
  }

  private createRaceBackendObjectNativeHawaiianOrOtherPacific(demographicData) {
    let race = new RaceData();
    race.race = this.race.nativeHawaiianOrOtherPacific;
    this.pacificIslanderSubTypes.forEach(subType => {
      if(demographicData.race[subType]) {
        let subTypeObj = new SubType();
        subTypeObj.name = subType;
        if(subTypeObj.name == 'otherPacificIslander') {
          subTypeObj.name = 'other';
          subTypeObj.description = demographicData.race.otherPacificIslanderDescription;
        }
        race.subtypes.push(subTypeObj);
      }
    });
    return race;
  }

  private createEthnicityBackendObject(demographicData) {
    const ethnicities = [];
    if(demographicData.ethnicity.isHispanicOrLatino) {
      let ethnicity = new EthnicityData();
      ethnicity.ethnicity = this.ethnicity.hispanicOrLatino;
      this.hispanicOrLatinoSubTypes.forEach(subType => {
        if(demographicData.ethnicity[subType]) {
          let subTypeObj = new SubType();
          subTypeObj.name = subType;
          if(subTypeObj.name == 'other') {
            subTypeObj.description = demographicData.ethnicity.description;
          }
          ethnicity.subtypes.push(subTypeObj);
        }
      });
      ethnicities.push(ethnicity);
    }
    if(demographicData.ethnicity.notHispanicOrLatino) {
      let ethnicity = new EthnicityData();
      ethnicity.ethnicity = this.ethnicity.notHispanicOrLatino;
      ethnicities.push(ethnicity);
    }
    if(demographicData.ethnicity.notApplicable) {
      let ethnicity = new EthnicityData();
      ethnicity.ethnicity = this.ethnicity.notApplicable;
      ethnicities.push(ethnicity);
    }
    if(demographicData.ethnicity.informationNotProvidedByApplicantMailInternetOrTelephoneApplication) {
      let ethnicity = new EthnicityData();
      ethnicity.ethnicity = this.ethnicity.informationNotProvidedByApplicantMailInternetOrTelephoneApplication;
      ethnicities.push(ethnicity);
    }
    return ethnicities;
  }

  private createRaceBackendObject(demographicData) {
    const races = [];
    if(demographicData.race.americanIndianOrAlaskaNative) {
      let race = new RaceData();
      race.race = this.race.americanIndian;
      let subType = new SubType();
      subType.description = demographicData.race.americanIndianOrAlaskaNativeDescription;
      race.subtypes.push(subType);
      races.push(race);
    }
    if(demographicData.race.isAsian) {
      races.push(this.createRaceBackendObjectAsian(demographicData));
    }
    if(demographicData.race.blackOrAfrican) {
      let race = new RaceData();
      race.race = this.race.blackOrAfrican;
      races.push(race);
    }
    if(demographicData.race.isNativeHawaiianOrOtherPacific) {
      races.push(this.createRaceBackendObjectNativeHawaiianOrOtherPacific(demographicData));
    }
    if(demographicData.race.white) {
      let race = new RaceData();
      race.race = this.race.white;
      races.push(race);
    }
    if(demographicData.race.informationNotProvidedByApplicantMailInternetOrTelephoneApplication) {
      let race = new RaceData();
      race.race = this.race.informationNotProvidedByApplicantMailInternetOrTelephoneApplication;
      races.push(race);
    }
    if(demographicData.race.notApplicable) {
      let race = new RaceData();
      race.race = this.race.notApplicable;
      races.push(race);
    }
    return races;
  }

  /**
   *   Change Current Borrower Index on click
   */
  public changeBorrower(index) {
    this.borrowerIndex = index;
  }

  public resetObject(type, data) {
    if(type == 'race.informationNotProvidedByApplicantMailInternetOrTelephoneApplication') {
      data.americanIndianOrAlaskaNative = false;
      data.isAsian = false;
      data.blackOrAfrican = false;
      data.isNativeHawaiianOrOtherPacific = false;
      data.white = false;
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = true;
      data.notApplicable = false;
      data.americanIndianOrAlaskaNativeDescription = null;
      data.asianIndian = false;
      data.chinese = false;
      data.filipino = false;
      data.japanese = false;
      data.korean = false;
      data.vietnamese = false;
      data.otherAsian = false;
      data.asianDescription = null;
      data.nativeHawaiian = false;
      data.guamanianOrChamorro = false;
      data.samoan = false;
      data.otherPacificIslander = false;
      data.otherPacificIslanderDescription = null;
    } else if (type == 'race.notApplicable') {
      data.americanIndianOrAlaskaNative = false;
      data.isAsian = false;
      data.blackOrAfrican = false;
      data.isNativeHawaiianOrOtherPacific = false;
      data.white = false;
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = false;
      data.notApplicable = true;
      data.americanIndianOrAlaskaNativeDescription = null;
      data.asianIndian = false;
      data.chinese = false;
      data.filipino = false;
      data.japanese = false;
      data.korean = false;
      data.vietnamese = false;
      data.otherAsian = false;
      data.asianDescription = null;
      data.nativeHawaiian = false;
      data.guamanianOrChamorro = false;
      data.samoan = false;
      data.otherPacificIslander = false;
      data.otherPacificIslanderDescription = null;
    } else if(type == 'ethnicity.informationNotProvidedByApplicantMailInternetOrTelephoneApplication') {
      data.isHispanicOrLatino = false;
      data.notHispanicOrLatino = false;
      data.notApplicable = false;
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = true;
      data.mexican = false;
      data.puertoRican = false;
      data.cuban = false;
      data.other = false;
      data.description = null;
    } else if(type == 'ethnicity.notApplicable') {
      data.isHispanicOrLatino = false;
      data.notHispanicOrLatino = false;
      data.notApplicable = true;
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = false;
      data.mexican = false;
      data.puertoRican = false;
      data.cuban = false;
      data.other = false;
      data.description = null;
    } else {
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = false;
      data.notApplicable = false;
    }
  }

  public resetAsianSubType(type, data) {
    this.asianSubTypes.forEach(subType => {
      if(type !== 'otherAsian') data.asianDescription = null;
      if(subType === type) data[subType] = true;
      data[subType] = false;
    });
  }

  public resetPacificIslanderSubType(type, data) {
    this.pacificIslanderSubTypes.forEach(subType => {
      if(type !== 'otherPacificIslander') data.otherPacificIslanderDescription = null;
      if(subType === type) data[subType] = true;
      data[subType] = false;
    });
  }

  async mounted() {
    await this.getInformation();

    this.gender = _.clone(this.isMismo ? MISMO_DEMOGRAPHIC_GENDER : DEMOGRAPHIC_GENDER);
    this.demographicChanneltype = _.clone(this.isMismo ? MISMO_DEMOGRAPHY_CHANNEL_TYPE : DEMOGRAPHY_CHANNEL_TYPE);
    this.initialState = this.cloneObject(this.getUpdateRequestBody());
  }
}
