

import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import Axios from "axios";
import _ from "lodash";
import addressComponent from "@/views/LosAddress.vue";
import AdditionalLoan from "@/views/LosAdditionalLoan.vue"
import { BASE_API_URL } from "@/config";
import { LoanAndProperty, AdditionalLoans} from "@/models/pos/loan-and-property.model";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import { StateLicense } from '@/models/state-license.model';
import LoanStateWarning from "@/views/LoanStateWarning.vue"
import Multiselect from 'vue-multiselect'

Vue.component('multiselect', Multiselect);

import {
  borrowerData,
  loanInfo,
  realtorInformation
} from "@/models/borrowerData.model";
import { 
  PROPERTY_TYPE, 
  OCCUPANCY, 
  TITLE_MANNER_HELD,
  MISMO_TITLE_MANNER_HELD,
  CONSTRUCTION_CLOSING_TYPE ,
  MISMO_INDIAN_COUNTRY_LAND_TENURE,
  MISMO_INDIAN_COUNTRY_LAND_TENURE_OTHER,
  ATTACHMENT_TYPE,
  MISMO_PROPERTY_ESTATE_TYPE,
  MISMO_CONSTRUCTION_METHOD_TYPE,
  MISMO_CONSTRUCTION_METHOD_TYPE_OTHER_DESCRIPTION,
  PROJECT_LEGAL_STRUCTURE_TYPE,
  REFINANCE_PURPOSE_MISMO,
  PROJECT_DESIGN_TYPE
} from "@/constants";
import LosRequiredField from "@/views/LosRequiredField.vue";
import LOSMixin from "@/mixins/los.mixin";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";

@Component({ components: { addressComponent, LosRequiredField, AdditionalLoan, LoanStateWarning } })
export default class LoanAndPropertyComponent extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop()
  public isSubmitToWemlo;
  public loanAndProperty: any = null;
  public borrowerData = new borrowerData();
  public propertyType = PROPERTY_TYPE;
  public closingTypes = CONSTRUCTION_CLOSING_TYPE;
  public occupancyType = OCCUPANCY;
  public attachmentType = ATTACHMENT_TYPE;
  public indianCountryLandTenure = MISMO_INDIAN_COUNTRY_LAND_TENURE;
  public indianCountryLandTenureOther = MISMO_INDIAN_COUNTRY_LAND_TENURE_OTHER;
  public constructionMethodList = MISMO_CONSTRUCTION_METHOD_TYPE;
  public constructionMethodOtherDescriptionList = MISMO_CONSTRUCTION_METHOD_TYPE_OTHER_DESCRIPTION;
  public loanInformation: any = new loanInfo();
  public transactionDetails = { purchasePrice: 0 };
  public realtorInformation = new realtorInformation();
  public additionalLoans = [new AdditionalLoans()]
  public viewPropertyInfo = true;
  public viewRealtorInfo = false;
  public viewRentalIncome = false;
  public viewAdditionalLoans = false;
  public propertyRequiredList = [];
  public isFieldMissing = false;
  public currentAddress: any = null;
  public financialInfo = new FinancialInfo();
  public titleMannerHeld = [];
  public isCurrentTitleMannerSelected: boolean = false;
  public isMismo = false;
  public isFHA = false;
  public isVA = false;
  public propertyEstateType = MISMO_PROPERTY_ESTATE_TYPE;
  public stateLicenses: Array<StateLicense> = [];
  private initialState: any = {};
  public projectLegalStructureTypes = PROJECT_LEGAL_STRUCTURE_TYPE;
  public refinancePrimaryPurposeTypes = REFINANCE_PURPOSE_MISMO;
  public projectDesignTypes = PROJECT_DESIGN_TYPE;
  public expirationYears = this.getExpirationYears();
  public userAddedExpirationYears = [];

  @Prop()
  public loanIsLocked;

  public getExpirationYears() {
    let years = this.years(new Date().getFullYear()-1, new Date().getFullYear() + 100).reverse();
    return years.concat(this.userAddedExpirationYears);
  }

  public addExpirationYear(value) {
    value = Number(value);
    if(_.isNaN(value) || !_.inRange(value, 1900, 2999)) return;
    this.userAddedExpirationYears.push(value);
    this.expirationYears = this.getExpirationYears();
    this.loanAndProperty.titleInformation.estate.expirationDate.year = value;
  }

  get isPurchase() {
    return _.get(this, 'loanInformation.purpose.name', '') === 'Purchase';
  }

  get propertyValue() {
    const appraisedValue = _.get(this.loanAndProperty, 'appraisedValue', 0);
    const estimatedPropertyValue = _.get(this.loanInformation, 'estimatedPropertyValue', 0);
    const purchasePrice = _.get(this.transactionDetails, 'purchasePrice', 0);

    if (this.isPurchase) {
      return purchasePrice;
    }

    if (_.isNumber(appraisedValue) && appraisedValue) {
      return appraisedValue;
    }

    if (_.isNumber(estimatedPropertyValue) && estimatedPropertyValue) {
      return estimatedPropertyValue;
    }

    return 0;
  }

  public async getLoanAndPropertyData() {
    try {
      this.$store.commit('SET_WEMLO_LOADER', true);
      let response = await Axios.post(
        BASE_API_URL + "los/getLoanAndPropertyData",
        {
          loanTxnId: this.$route.query.id
        }
      );
      this.loanAndProperty = await this.checkException(response.data.loanAndPropertyData);
      this.loanInformation = response.data.borrowerData.loanInformation;
      this.transactionDetails = _.get(response, 'data.transactionDetails', {});
      this.isFHA = (this.loanInformation && this.loanInformation.productType &&
          this.loanInformation.productType.value == 'FHA') || false;
      this.isVA = (this.loanInformation && this.loanInformation.productType &&
          this.loanInformation.productType.value == 'VA') || false;
      this.isMismo = (this.loanInformation && this.loanInformation.enumerationType === 'mismo') || false;

      this.titleMannerHeld = this.isMismo ? MISMO_TITLE_MANNER_HELD : TITLE_MANNER_HELD;

      this.realtorInformation = response.data.borrowerData.realtorInformation;
      this.additionalLoans = this.loanAndProperty.additionalLoans.map(loan => {                
        return new AdditionalLoans(loan);
      })
      this.currentAddress = response.data.currentAddress;
      this.setToBeDecided();
      this.initialState = this.cloneObject(this.getUpdateRequestBody());
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.commit('SET_WEMLO_LOADER', false);
    }
  }

  public async checkException(data) {
    try {
      if (!data) {
        data = new LoanAndProperty();
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  public setToBeDecided() {
    if (
      this.loanInformation.purpose &&
      this.loanInformation.purpose.ediValue == "05"
    ) {
      this.loanAndProperty.toBeDecidedProperty = false;
    }
  }

  public getUpdateRequestBody() {
    return {
        loanTxnId: this.$route.query.id,
        loanAndPropertyDetails: this.loanAndProperty,
        loanInformation: this.loanInformation,
        realtorInformation: this.realtorInformation,
        transactionDetails: this.transactionDetails,
    }
  }

  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {
      await this.$validator.validateAll();

      this.loanAndProperty.additionalLoans = this.additionalLoans;

      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };

      this.saveInProcess = true;
      this.$store.commit('SET_WEMLO_LOADER', true);
      let response = await Axios.post(
        BASE_API_URL + "los/saveLoanAndPropertyData",
          requestBody,
        );
      if(tosty){
        this.$snotify.clear();
        this.$snotify[response.data.statusCode == 200 ? "success" : "error"](response.data.message);
      }
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.commit('SET_WEMLO_LOADER', false);
      if (response.data.statusCode === 200) {
          this.initialState = this.cloneObject(requestBody);
      }
      if(!fromParent && this.$parent["checkLosFormCompleted"]){
          this.$parent["checkLosFormCompleted"](true);
      }
      return response.data.statusCode == 200 &&
        response.data.propertyError.length > 0
        ? { status: true, requiredFields: response.data.propertyError }
        : { status: true, requiredFields: [] };
    } catch (error) {
      this.$store.commit('SET_WEMLO_LOADER', false);
      this.saveInProcess = false;
      console.log(error);
      return { status: false };
    }
  }

  resetRentalIncome() {
    this.loanAndProperty.expectedRentalIncome = 0;
    this.loanAndProperty.occupancyRate = "0";
  }

  public years(start: number, end: number) {
    let years = [];
    for (let i = end; i > start; i--) {
      years.push(i);
    }
    return years;
  }
  

  public units(start: number, end: number) {
    let unit = [];
    for (let i = start; i <= end; i++) {
      unit.push(i);
    }
    return unit;
  }

  public disableAddress() {
    try {
      this.loanAndProperty.propertyAddress.address.zip = null;
      this.loanAndProperty.propertyAddress.address.street = null;
      this.loanAndProperty.propertyAddress.address.city = null;
      this.loanAndProperty.propertyAddress.address.county = null;
      this.loanAndProperty.propertyAddress.address.country = null;
      this.loanAndProperty.propertyAddress.address.state = null;
      this.loanAndProperty.propertyAddress.address.unit = null;
      this.loanInformation.propertyType = null;
      this.loanAndProperty.propertyAddress.numberOfUnits = null;
      // this.loanAndProperty.toBeDecidedProperty = true;
    } catch (error) {
      console.log(error);
    }
  }

  public async setCurrentAddressAsSubjectAddress() {
    this.loanAndProperty.propertyAddress.address.zip = this.currentAddress.address.zip;
    this.loanAndProperty.propertyAddress.address.street = this.currentAddress.address.street;
    this.loanAndProperty.propertyAddress.address.city = this.currentAddress.address.city;
    this.loanAndProperty.propertyAddress.address.state = this.currentAddress.address.state;
    this.loanAndProperty.propertyAddress.address.unit = this.currentAddress.address.unit;
  }

  public addAdditionalLoan() {        
    this.additionalLoans.push(new AdditionalLoans())
  }

  public removeAdditionalLoan(index) {
    this.additionalLoans.splice(index, 1)
    if (!this.additionalLoans.length) {      
      this.addAdditionalLoan();
    }        
  }

  public async getValidAdditionalLoans() {
    const additionalLoansRefs: any = this.$refs.additionalLoansRef
    const valid_loans = await Promise.all(additionalLoansRefs.map(async ref => await ref.validate()))      
    return this.additionalLoans.filter((_loan, i) => valid_loans[i]);
  }

  updateLosHeaderInfo() {
    let loanAmount: any = parseFloat(this.loanAndProperty.loanAmount);
    let propertyValue: any = parseFloat(
      this.loanAndProperty.propertyAddress.propertyValue
    );
    let data = {
      loanAmount: loanAmount.toFixed(2),
      purchasePrice: propertyValue.toFixed(2)
    };
    this.$emit("callMountFunction", data);
  }

  openTitleMannerModal() {
    this.isCurrentTitleMannerSelected = false;
    this.$modal.show('dropdown');
  }

  openCurrentTitleMannerModal() {
    this.isCurrentTitleMannerSelected = true;
    this.$modal.show('dropdown');
  }

  setTitleMannerHeld(data) {
    if (!this.loanAndProperty) {
      this.$modal.hide("dropdown");
      return;
    }

    if (this.isCurrentTitleMannerSelected) {
      this.loanAndProperty.currentTitleInformation.manner = data;
    } else {
      this.loanAndProperty.titleInformation.manner = data;
    }

    this.$modal.hide("dropdown");
  }

  get days() {
      let days = [];
      for (let i: any = 1; i < 32; i++) {
          i = i < 10 ? "0" + i : i;

          days.push(i);
      }
      return days;
  }

  get months() {
      let months = [];
      for (let i: any = 1; i < 13; i++) {
          i = i < 10 ? "0" + i : i;
          months.push(i);
      }
      return months;
  }

  get yearsPeriod() {
      let period = [];
      for (let i: any = 1; i < 101; i++) {
          i = i < 10 ? "0" + i : i;
          period.push(i);
      }
      return period;
  }

  async getStateLicenses() {
    const { data: { stateLicenses } } = await Axios.post( `${BASE_API_URL}broker/getStateLicenses`, {}) || {};

    this.stateLicenses = stateLicenses;
  }

  async mounted() {
    await this.getLoanAndPropertyData();
    await this.getStateLicenses();
  }
}
