

import { Component, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { BASE_API_URL } from "@/config";

import Axios from "axios";
import {
    TITLE_HOLD_TYPE,
    ANOTHER_PROPERTY_TYPE,
    CITIZEN_SHIP,
    DOWN_PAYMENT_TYPE,
} from "@/constants";
import LosRequiredField from "@/views/LosRequiredField.vue";
import { Declaration } from "@/models/pos/declaration.model";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
import Datepicker from "vuejs-datepicker";
import _ from "lodash";
import LOSMixin from "@/mixins/los.mixin";

@Component({
  components: {
    LosRequiredField,
    Datepicker
  },
})
export default class DeclarationComponent extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public borrowerCoBorrowerIndex;
  @Prop()
  public loanIsLocked;

  public titleHoldType = TITLE_HOLD_TYPE;
  public anotherPropertyType = ANOTHER_PROPERTY_TYPE;
  public citizenShip = CITIZEN_SHIP;
  public downpaymentType = DOWN_PAYMENT_TYPE;
  public declarationData: any = null;
  public borrowerInfo: any = null;
  private initialState: any = {};
  public borrowerData: any = null;
  public borrowerIndex: any = null;
  public isMismo: boolean = null;

  public async getInformation() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "los/getDeclarationData",
        {
          loanTxnId: this.$route.query.id
        });
      this.borrowerData = response.data.borrowerData;
      this.borrowerInfo = response.data.borrowerInfo.borrowerInfo;
      this.declarationData = await this.checkException(response.data);
      this.isMismo = _.get(response, 'data.borrowerData.loanInformation.enumerationType', '') === 'mismo';
      //Fallback code for affiliationSellerProperty model not exists for older loans
      this.declarationData.forEach(declaration => {
          if(!declaration.affiliationSellerProperty){
            declaration.affiliationSellerProperty = {
                hasAffiliationSellerProperty :  null,
                hasGiftedTransaction : null
            }
          }
      });
      this.changeBorrower( this.borrowerCoBorrowerIndex ? this.borrowerCoBorrowerIndex : this.borrowerData.primaryBorrowerIndex);
      this.initialState = this.cloneObject(this.getUpdateRequestBody());
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

/*********************************************************
 *  If Api response is null or Have Exception.
 */
  public async checkException(data){
    try {
      data = data.declarationData.borrowerInfo;
      let declaration:any = new Declaration();
      declaration = declaration.borrowerInfo[0];
      let haveDecleration = data.filter(a=> !a);
      if(haveDecleration.length>0)
        data.forEach((el, index)=>{
          if(!el)
            data[index] = declaration;
        });
      if(!data){
        data = [];
        this.borrowerInfo.forEach(element => {data.push(declaration);});
      }
    } catch (error) {
      console.log(error);
    }
    return data;
  }

  public getUpdateRequestBody() {
      return {
          loanTxnId: this.$route.query.id,
          declarationData: this.declarationData,
          borrowerInfo: this.borrowerInfo,
          borrowerIndex: this.borrowerIndex,
      }
  }

  public async saveInformation(tosty,fromParent, checkUnsaved = false) {
    try {
      await this.$validator.validateAll();
      

      this.saveInProcess = true;
      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "los/saveDeclaration",
          requestBody,
      );
      if(tosty){
        this.$snotify.clear();
        this.$snotify[response.data.statusCode == 200 ? "success" : "error"](response.data.message);
      }
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.state.wemloLoader = false;

      if (response.data.statusCode === 200) {
          this.initialState = this.cloneObject(requestBody);
      }

      /**
       * @TODO: side effect investigate, we are saving twice
       */
       if(!fromParent && this.$parent["checkLosFormCompleted"]){
           this.$parent["checkLosFormCompleted"](true);
       }
      return response.data.statusCode == 200 &&
        response.data.declarationError.length > 0
        ? { status: true, requiredFields: response.data.declarationError }
        : { status: true, requiredFields: [] };
    } catch (error) {
      this.$store.state.wemloLoader = false;
      this.saveInProcess = false;
      console.log(error);
      this.$snotify.error('Failed to save declarations')
      return { status: false };
    }
  }
  /**
   *  Change Current Borrower Index on click
   */
  public changeBorrower(index) {
    this.borrowerIndex = index;
  }

  public clearData(origin) {
    if(origin == 'propertyType'){
      this.declarationData[this.borrowerIndex][origin].typeOfProperty = null;
      this.declarationData[this.borrowerIndex][origin].typeOfPropertyTitle = null;
    }else if(origin == 'bankruptcyDeclaration')
      this.declarationData[this.borrowerIndex][origin].typesOfBankruptcy = [];
    else if(origin == 'borrowedForDownpayment')
      this.declarationData[this.borrowerIndex][origin].explanation = 0;
    else
      this.declarationData[this.borrowerIndex][origin].explanation = null;
  }

  async mounted() {
    await this.getInformation();
    // this.$parent["checkLosFormCompleted"](true);
  }
}
