


import { Component, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import DisplayFiles from "@/views/DisplayDocument.vue";
import Multiselect from "vue-multiselect";
import { CreditReport } from "@/models/order-service.model";
import LosRequiredField from "@/views/LosRequiredField.vue";
import PDFViewer from "@/components/pdf/PDFViewer.vue";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import _ from "lodash"; 
import ConfirmModal from "@/components/urla/los/generic/ConfirmModal.vue";

@Component({ components: { Multiselect, DisplayFiles, LosRequiredField, PDFViewer, ConfirmModal } })
export default class RunausRequest extends mixins(CommonMixin) {
  @Prop({ required: true }) creditVendors;
  @Prop({ required: true }) ausFindings;
  @Prop({ required: true }) ausCasefileId;
  @Prop({ required: true }) hasCredential;
  @Prop({ required: true }) isAuthenticationFail;
  @Prop({ required: true }) borrowers;
  @Prop({ required: true }) isFHAOrVA;
  @Prop({ default: false }) blockSubmit;
  @Prop({ required: true }) lastSuccessfulFinding;
  @Prop({ required: true }) mostUsedCreditAgenciesIds;
  @Prop({ required: true }) vendorsCredentials;
  @Prop({ required: false }) superAdminBrokerId;
  @Prop({ required: true }) showChangeLender;

  showError = false;
  hasError = false;
  creditAgencies = null;
  lenders = [];
  selectedLenderInstitutionIdentifier = null;
  vendorInfo = new CreditReport();
  public vendorInfoIsLoaded = true;

  public losRequiredList = null;
  public loanTxnId = null;
  public sendCallBack = this.generateRunAusFannieMae;
  resetAusCaseFile = false;

  public ausVendorsCredentials: any = [];
  public creditVendorsCredentials: any = [];
  public ausCredential = null;
  public ausCredentialLoaded = true;
  public showAusPassword = false;
  public creditCredential = null;
  public creditCredentialLoaded = true;
  public showCreditPassword = false;
  public showAddNewCredential = false;

  public newCredentialModalType = null;
  public newCredentialUserName = null;
  public newCredentialPassword = null;
  public newCredentialVendorName = null;
  
  componentKey = 0;

  async vendorMethodChanged() {
    await this.checkUserNameAndPassword();
  }

  async checkUserNameAndPassword() {
    if (!this.vendorInfo.userName || !this.vendorInfo.password || !this.vendorInfo.method) {
      return;
    }

    await this.getCreditAgencies();
    await this.getLenders();
  }

  async getCreditAgencies() {
    try {
      this.$store.state.wemloLoader = true;
      const body = {
        userId: this.$userId,
        brokerId: this.$brokerId,
        userType: this.$userType,
        vendorType: "AUS Vendor",
        userName: this.vendorInfo.userName,
        password: this.vendorInfo.password,
      };
      const response = await Axios.post(BASE_API_URL + "integrationFannieMae/getCreditAgencies", body, { timeout: 30000 });
      const responseError = _.get(response, 'data.error', '');

      if (!_.isEmpty(responseError)) {
        this.creditAgencies = [];
        this.$snotify.error(`Failed to load credit agencies. ${responseError}`);
        this.$store.state.wemloLoader = false;
        return;
      }

      this.creditAgencies = _.get(response, 'data.co.GET_CREDIT_AGENCY_RESPONSE.CREDIT_BUREAU', []).map(data => {
        return {
          name: _.get(data, '_attributes._Name', ''),
          code: _.get(data, '_attributes._Number')
        };
      });

      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      this.$snotify.error(`Failed to load credit agencies.`);
      console.log(error);
    }
  }

  get getMethods() {
    const preliminary = {
      name: 'Preliminary',
      disabled: (this.ausCasefileId && !this.resetAusCaseFile) || this.showLenderDisabled ? _.get(this, 'ausFindings.underwritingStatus', 'Preliminary') != 'Preliminary' : false
    };
    const interim = {
      name: 'Interim',
      disabled: this.ausCasefileId && !this.resetAusCaseFile ? !(_.get(this, 'ausFindings.underwritingStatus') != 'Final' || (!this.isFHAOrVA && (_.get(this, 'ausFindings.underwritingStatus', 'Preliminary') == 'Preliminary') || _.get(this, 'ausFindings.underwritingStatus') == 'Interim')) : false
    };
    const final = {
      name: 'Final',
      disabled: this.ausCasefileId && !this.resetAusCaseFile ? _.get(this, 'ausFindings.underwritingStatus') == 'Final' : false
    };
    if(this.isFHAOrVA) {
      return [ interim, final ];
    } else {
      return [ preliminary, interim, final ];
    }
  }

  get orderedCreditVendors() {
    return _.sortBy(_.get(this, 'creditVendors', []), 'name');
  }

  get showLenderSelection() {
    const selectedMethod = this.vendorInfo.method;
    const method = _.get(this.lastSuccessfulFinding, 'requestDetails.method', null);
    const methods = [ 'Interim', 'Final' ];
    return (this.ausCasefileId && methods.includes(method)) 
    || methods.includes(selectedMethod)
    || (this.selectedLenderInstitutionIdentifier && this.$userType === 'Wemlo Processor');
  }

  get showLenderDisabled() {
    const disabled = !this.resetAusCaseFile && !!_.get(this, 'lastSuccessfulFinding.requestDetails.lender', false);
    if(!disabled) {
      this.selectedLenderInstitutionIdentifier = null;
    }
    return disabled;
  }
  
  get orderedCreditAgencies() {
    const ordered = _.orderBy(this.creditAgencies, 'name');

    if (!_.isEmpty(this.creditAgencies)) {
      this.mostUsedCreditAgenciesIds.forEach(id => {
        // Find the credit agency obejct by id 
        const creditAgency = this.creditAgencies.find(credit => credit.code === id);
        if (!_.isEmpty(creditAgency)) {
          const index = ordered.indexOf(creditAgency);
          // Remove it from array 
          ordered.splice(index, 1);
          // Add it again on the top of array
          ordered.unshift(creditAgency);
        }
      });
    }

    return ordered;
  }

  async getLenders() {
    try {
      if ((!this.showLenderSelection || !!this.selectedLenderInstitutionIdentifier) 
        && !(_.get(this.selectedLenderInstitutionIdentifier,'name') && _.get(this.selectedLenderInstitutionIdentifier, 'institutionIdentifier', true))) {
        this.lenders = [];
      } else {
        this.$store.state.wemloLoader = true;

        const body = {
          userId: this.$userId,
          brokerId: this.$brokerId,
          userType: this.$userType,
          vendorType: "AUS Vendor",
          userName: this.vendorInfo.userName,
          password: this.vendorInfo.password,
        };

        const response = await Axios.post(
          BASE_API_URL + "integrationFannieMae/getLenders",
          body,
          { timeout: 30000 }
        );
        const responseError = _.get(response, 'data.error', '');
        const lenders = _.get(response, 'data', null);

        if (!_.isEmpty(responseError)) {
          this.$snotify.error(`Failed to load lenders. ${responseError}`);
          this.lenders = [];
          this.selectedLenderInstitutionIdentifier = null;
          this.$store.state.wemloLoader = false;
          return;
        }

        if (_.get(this.selectedLenderInstitutionIdentifier,'name') && _.get(this.selectedLenderInstitutionIdentifier, 'institutionIdentifier', true)) {
          this.lastSuccessfulFinding.requestDetails.lender = lenders.find(lender => _.get(this.selectedLenderInstitutionIdentifier, 'mortgageClause', '').toUpperCase().includes(lender.name) || _.get(this.selectedLenderInstitutionIdentifier, 'name', '').toUpperCase().includes(lender.name));
          this.selectedLenderInstitutionIdentifier = this.lastSuccessfulFinding.requestDetails.lender;
        } else {
          this.lenders = lenders;
          this.selectedLenderInstitutionIdentifier = null;
        }

        this.$store.state.wemloLoader = false;
      }
    } catch (error) {
      this.$store.state.wemloLoader = false;
      this.$snotify.error(`Failed to load lenders.`);
      console.log(error);
    }
  }

  get ausFilepath() {
    return _.get(this.ausFindings, 'files.pdf.path', '');
  }

  public saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {type: "application/pdf"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  public base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }

  public async checkLosFormCompleted() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/checkLosFormCompleted",
        { loanTxnId: this.loanTxnId });
      if (response.data.status == "error") {
        return response.data.errorList;
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  public async runAusFannieMae() {
    try {
      const validations = await this.$validator.validateAll();
      if (!validations) {
        this.$store.state.wemloLoader = false;
        return;
      }

      /**
       * checking by user type for now, to be fixed into a better solution
       */
      if (this.session.type === 'Wemlo Processor') {
        await this.generateRunAusFannieMae();
      } else {
        this.$emit('losMissingFields', this.generateRunAusFannieMae);
      }
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async generateRunAusFannieMae() {
    try {
      this.$modal.hide('showLosRequiredField');
      this.showError = false;
      this.$store.state.wemloLoader = true;
      const body = {
        userId: this.$userId,
        brokerId: this.$brokerId,
        userType: this.$userType,
        vendorType: "AUS Vendor",
        loanTxnId: this.$route.query.id,
        userName: this.vendorInfo.userName,
        password: this.vendorInfo.password,
        fannieMaeId: this.vendorInfo.creditAgency,
        userNameCredit: this.vendorInfo.userNameCredit,
        passwordCredit: this.vendorInfo.passwordCredit,
        autoPopulateLiabilities: this.vendorInfo.autoPopulateLiabilities,
        requestType: this.vendorInfo.requestType,
        borrower: this.vendorInfo.borrower,
        lender: this.selectedLenderInstitutionIdentifier,
        creditUnderwritingOptions: this.vendorInfo.creditUnderwritingOptions,
        borrowers: this.borrowers,
        method: this.vendorInfo.method
      };

      const response = await Axios.post(BASE_API_URL + "integrationFannieMae/runAUS", body);
      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        this.$snotify.success('AUS ran successfully');
      } else {
        this.$snotify.error(`Something went wrong: ${message}`);
      }

      this.$store.state.wemloLoader = false;
      this.isAuthenticationFail = false;
      this.$emit('onSuccess');
      
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public selectBorrower(index) {
    this.borrowers[index].isSelected = !this.borrowers[index].isSelected;
  }

  public resetAusCaseFileIdAndEmitEvent() {
    this.resetAusCaseFile = true; 
    this.selectedLenderInstitutionIdentifier = null;
    this.ausVendorsCredentials = _.get(this.vendorsCredentials, 'ausCredentials', []);
    this.checkUserNameAndPassword();
    this.$emit('onResetAusCaseFileId');
  }

  /**
  * Fired when aus credentials select changes. 
  * It will fill DO/DU username and password fields
  */

  public async setAusUsernamePassword() {
    _.set(this.vendorInfo, 'userName', _.get(this.ausCredential, 'userName', null));
    _.set(this.vendorInfo, 'password', _.get(this.ausCredential, 'password', null));
    await this.checkUserNameAndPassword();
  }

  /**
  * Fired credit aus credentials select changes. 
  * It will fill credit username and password fields
  */

  public async setCreditUsernamePassword() {
    _.set(this.vendorInfo, 'userNameCredit', _.get(this.creditCredential, 'userName', null));
    _.set(this.vendorInfo, 'passwordCredit', _.get(this.creditCredential, 'password', null));
  }

  get selfAusCredential() {
    return _.get(this.vendorsCredentials, 'selfAusCredential', []);
  }

  /**
  * It sets the initial values of aus and credit credentials select field when modal opens.
  * The priority is what is already saved successfully on database
  */
  public async setInitialCredentials() {
    const savedAusUsername = _.get(this.lastSuccessfulFinding, 'requestDetails.userName', null);
    const savedAusCredential = savedAusUsername ? this.ausVendorsCredentials.find(credential => credential.userName === savedAusUsername) : null;
    const initialAusCredential = _.get(this.vendorsCredentials, 'initialAusCredential', null);
    this.ausCredential = savedAusCredential || initialAusCredential;
    this.$nextTick(() => {
        this.ausCredentialLoaded = false;
    });
    if (this.ausCredential) {
      await this.setAusUsernamePassword();
    }

    // In case we already have something registered successfully, we disallow user to change aus credentials
    if (savedAusCredential && this.ausCasefileId) {
      this.ausVendorsCredentials = [ savedAusCredential ];
    }

    const initialCreditCredential = _.get(this.vendorsCredentials, 'initialCreditCredential', null);
    this.creditCredential = initialCreditCredential;
    this.$nextTick(() => {
        this.creditCredentialLoaded = false;
    });
    if (this.creditCredential) {
      await this.setCreditUsernamePassword();
    }
  }

  public showConfirmModal() {
    this.$modal.show("confirmModal");
  }

  public confirmModalYes() {
    this.$modal.hide("confirmModal");
    this.resetAusCaseFileIdAndEmitEvent();
  }

  public confirmModalNo() {
    this.$modal.hide("confirmModal");
  }

  get userType() {
    return this.$userType;
  }

  get session() {
    return this.$store.state.sessionObject;
  }

  public openNewCredentialsModal(type) {
    this.newCredentialUserName = null;
    this.newCredentialPassword = null;
    this.newCredentialModalType = type;
    this.$modal.show("addNewCredentialsModal");
  }

  /**
  * Method to add new Fannie Mae AUS vendor credentials or Credit vendor credentials
  */
  public async sendNewCredentials() {
    try {
      this.$store.state.wemloLoader = true;
      const validations = await this.$validator.validateAll(this.newCredentialModalType);
      
      if (!validations) {
        this.$store.state.wemloLoader = false;
        return;
      }

      const brokerId = this.$brokerId || this.superAdminBrokerId;
      
      const body = {
        'addedBy': this.$userId,
        'addedByUserType': this.$userType,
        'brokerId': brokerId,
        'createdOn': new Date(),
        'vendorType': this.newCredentialModalType === 'aus' ? 'AUS Vendor' : 'Credit Vendor',
        'vendorName': !_.isEmpty(this.newCredentialVendorName) ? this.newCredentialVendorName : 'Fannie Mae',
        'userName': this.newCredentialUserName,
        'password': this.newCredentialPassword,
        'fannieMae': this.newCredentialModalType === 'aus' ? 
          {
            'vendorName': 'Fannie Mae',
            'addedBy': this.$userId,
            'addedByUserType': this.$userType,
            'brokerId': brokerId,
            'createdOn': new Date(),
            'vendorType': 'AUS Vendor',
            'userName': this.newCredentialUserName,
            'password': this.newCredentialPassword,
          } : {}
      };

      const response = await Axios.post(`${BASE_API_URL}wemloStaff/addBrokerVendor`, body);
      const status = _.get(response, 'status', null);

      if (status === 200) {
        this.$snotify.success('Vendor added successfully');

        if (this.newCredentialModalType === 'aus') {
          await this.setNewAusCredentials(body);
        }

        if (this.newCredentialModalType === 'credit') {
          this.setNewCreditCredentials(body);
        }
      } else {
        this.$snotify.error(`Something went wrong`);
      }

      this.$store.state.wemloLoader = false;
      this.$modal.hide('addNewCredentialsModal');
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async setNewAusCredentials(newAusCredentials) {
    let userLoggedName;

    // Get first and last name from session
    const firstName = _.get(this.session, 'userInfo.firstName', null);
    const lastName = _.get(this.session, 'userInfo.lastName', null);

    if (this.userType === 'Super Admin' || !firstName || !lastName) {
      userLoggedName = this.userType;
    } else {
      userLoggedName = `${firstName} ${lastName}`;
    }

    const addedAusCredential = { 
      ...newAusCredentials, 
      addedByUserName: userLoggedName,
      userName: this.newCredentialUserName,
      password: this.newCredentialPassword
    };

    // Add this new one to the aus vendors select options
    this.ausVendorsCredentials = [...this.ausVendorsCredentials, addedAusCredential];
    // Set it as selected
    this.ausCredential = addedAusCredential;

    // Set aus credentials fields with new values
    this.vendorInfo.userName = this.newCredentialUserName;
    this.vendorInfo.password = this.newCredentialPassword;

    // Check and update again
    await this.checkUserNameAndPassword();
  }

  public setNewCreditCredentials(newCreditCredentials) {
    // Add this new one to the credit vendors select options
    this.creditVendorsCredentials = [...this.creditVendorsCredentials, newCreditCredentials];
    // Set it as selected
    this.creditCredential = newCreditCredentials;

    // Set credit credentials fields with new values
    this.vendorInfo.userNameCredit = this.newCredentialUserName;
    this.vendorInfo.passwordCredit = this.newCredentialPassword;
  }

  async mounted() {
    const cleanUnderwrittingStatus = _.get(this, 'ausFindings.underwritingStatus', 'Preliminary').split('(Obsolete)').join('').trim();
    _.set(this.vendorInfo, 'method', cleanUnderwrittingStatus);
    _.set(this.vendorInfo, 'autoPopulateLiabilities', true);
    this.$nextTick(() => {
        this.vendorInfoIsLoaded = false;
    });
    this.loanTxnId = this.$route.query.id;
    this.selectedLenderInstitutionIdentifier = _.get(this.lastSuccessfulFinding, 'requestDetails.lender');
    this.ausVendorsCredentials = _.get(this.vendorsCredentials, 'ausCredentials', []);
    this.creditVendorsCredentials = _.get(this.vendorsCredentials, 'creditCredentials', []);
    this.showAddNewCredential = this.userType === 'Broker' || this.userType === 'MLO' || this.userType === 'Super Admin';
    await this.setInitialCredentials();
  }
}
