import { Component } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import _ from "lodash";
import { CreditReport, Borrower } from "@/models/order-service.model";
import CommonMixin from "@/mixins/common.mixin";
import moment from "moment";

@Component
export default class RunAusMixin extends CommonMixin {
  vendorInfo = new CreditReport();
  showError = false;
  isAuthenticationFail = false;
	ausCasefileId = null;
	ausFindings = null;
	borrowers: Array<Borrower> = new Array<Borrower>();
	creditReportPdf = null;
	creditReportStatus = null;
	creditVendors = [];
	isFHAOrVA = false;
	lastSuccessfulFinding = null;
	mostUsedCreditAgenciesIds: any = [];
  superAdminBrokerId = null;
  relationshipMapping = null;
  vendorsCredentials: any = [];
  recommendation = {
    text: null,
    color: null
  };

  get canShowChangeLender() {
    return this.$userType !== 'Wemlo Processor'
  }

  get ausFilepath() {
    return _.get(this.ausFindings, 'files.pdf.path', '');
  }

  get ausUnderwritingStatus() {
    return _.get(this.ausFindings, 'underwritingStatus', null);
  }

  get ausStatus() {
    return _.capitalize(_.get(this.ausFindings, 'status', ''));
  }

  get ausWasSuccessful() {
    return this.ausStatus === 'Success';
  }

  get ausHasErrors() {
    return _.get(this.ausFindings, 'responseDetails.findingErrors', []).length;
  }

	async getCreditVendors() {
    try {
      this.$store.state.wemloLoader = true;
      const response = await Axios.get(
        BASE_API_URL + "creditReport/getCreditVendors",
        {
          params: { loanTxnId: this.$route.query.id }
        }
      );

      this.creditVendors = response.data.creditVendors;
      this.borrowers = response.data.borrowers;
      this.creditReportStatus = response.data.resStatus;
      this.ausCasefileId = response.data.ausCasefileId;
      this.creditReportPdf = response.data.creditReportPdf;

      const borrowerResponse = await Axios.post(
        BASE_API_URL + "borrower/getBorrowerInfo",
        {
          loanTxnId: this.$route.query.id
        }
      );
      this.isFHAOrVA = (_.get(borrowerResponse,'data.loanInformation.productType') && 
        (_.get(borrowerResponse,'data.loanInformation.productType.value') == 'FHA' ||
        _.get(borrowerResponse,'data.loanInformation.productType.value') == 'VA')) || false;
      this.getAusFindings();
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

	public async getAusFindings() {
    try {
      this.$store.state.wemloLoader = true;
      const response = await Axios.post(
        BASE_API_URL + "ausFindings/getAusFindings",
        {
          loanTxnId: this.$route.query.id
        }
      );
      const findings = _.get(response, 'data.findings', [{}]);
      this.ausFindings = _.last(findings);
      this.getRecommendation(_.get(this.ausFindings, 'recommendation'));

      const resp = await Axios.post(
        BASE_API_URL + "los/getGeneralData",
        {
          loanTxnId: this.$route.query.id
        }
      );

      this.mostUsedCreditAgenciesIds = await this.getMostUsedCreditAgencies(resp);

      if (this.ausCasefileId != null) {
        this.ausFindings.createdOn = moment.utc(this.ausFindings.createdOn).format('MM/DD/YYYY HH:mm:ss');
        this.lastSuccessfulFinding = _.last(findings.filter(finding => finding.caseFileId == this.ausCasefileId && finding.status == 'SUCCESS'));
      } else {        
        if (resp.status == 200 && resp.data.loanInformation) {
          if(_.get(resp, 'data.loanInformation.lenderName', null)) {
            const lender = await Axios.post(
              BASE_API_URL + "lender/getLenderByName",
              {
                loanTxnId: this.$route.query.id,
                lenderName: _.get(resp, 'data.loanInformation.lenderName', null)
              });
            this.lastSuccessfulFinding = {
              requestDetails: {
                method: 'Interim',
                lender: {
                  name: _.get(resp, 'data.loanInformation.lenderName'),
                  mortgageClause: _.get(lender, 'data.lenderMortgageeClause')
                }
              }
            };
            if (this.ausFindings) {
              this.ausFindings.createdOn = moment.utc(this.ausFindings.createdOn).format('MM/DD/YYYY HH:mm:ss');
              this.ausFindings.requestDetails.method = 'Interim';
            } else {
              this.ausFindings = {
                underwritingStatus: 'Interim'
              };
            }
          }
        }
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  getRecommendation(recommendation) {
    if (recommendation) {
      this.recommendation.text = recommendation;
      switch (recommendation.trim().toUpperCase()) {
        case 'APPROVE/ELIGIBLE':
          this.recommendation.color = '#32b508';
          break;
        case 'APPROVE/INELIGIBLE':
          this.recommendation.color = '#EFD088';
          break;
        case 'REFER WITH CAUTION':
          this.recommendation.color = '#ff7f00';
          break;
        default:
          this.recommendation.color = '#ef5c55';
      }
    }
  }

	/**
  * Get a list of most used credit agencies by user (this.$userId)
  */
  async getMostUsedCreditAgencies(borrowerData) {
    // Retrieve all aus findings that this.$userId added
    const ausFindingsByUserData = 
      await Axios.post(BASE_API_URL + "ausFindings/getAusFindingsByUser", { 'addedBy': _.get(borrowerData, 'data.addedBy', this.$userId) });
    const runAusData = _.get(ausFindingsByUserData, 'data.runAusData', []);

    if (_.isEmpty(runAusData)) return [];

    // Find the credit agencies ids that this.$userId most used
    const creditAgenciesIds = 
      runAusData.reduce((accumulator, ausFinding) => {
        const creditAgencies = ausFinding.findings.map(finding => {
          const userId = _.get(finding, 'userId', null);
          if (userId === _.get(borrowerData, 'data.addedBy', this.$userId) ) {
            return _.get(finding, 'requestDetails.creditAgency', null);
          }
        }).filter(f => f);
        accumulator.push(...creditAgencies);
        return accumulator;
      }, []);
    
    if (_.isEmpty(creditAgenciesIds)) return [];

    // Get count of how many of each credit was used
    const groupedValues = _.countBy(creditAgenciesIds, (id) => id);

    // Return sorted values in asc order
    return Object.keys(groupedValues).sort((f, s) => groupedValues[f] - groupedValues[s]);
  }

  async getBorrowerByLoan(loanTxnId) {
    const response = await Axios.post(BASE_API_URL + 'borrower/getBorrowerInfo', { loanTxnId });
    return _.get(response, 'data', null);
  }

  async getVendorsCredentials() {
    try {
      let response;
      let credentials;
      const loanTxnId = this.$route.query.id;
      const borrower = await this.getBorrowerByLoan(loanTxnId);
      this.relationshipMapping = _.get(borrower, 'relationshipMapping', []);
      this.superAdminBrokerId = _.get(borrower, 'brokerId', null);

      if (this.$userType === 'Super Admin') {

        response = await Axios.post(BASE_API_URL + 'superAdmin/getBrokerAllDetails', { userId: this.superAdminBrokerId });

        credentials = _.get(response, 'data.vendorData', []);
      } else if (this.$userType === 'Wemlo Processor') {
        response = await Axios.post(
          BASE_API_URL + 'wemloprocessor/getVendorsList/',
          {
            userId: this.$userId,
            brokerId: this.superAdminBrokerId
          }
        );

        credentials = _.get(response, 'data.brokerVendors', []);
      }  else {
        response = await Axios.post(
            BASE_API_URL + 'broker/getVendorsList/',
            {
              userId: this.$userId,
              userType: this.$userType,
              brokerId: this.$brokerId
            }
          );

        credentials = _.get(response, 'data.brokerVendors', []);
      }

      // Broker can't see MLO credentials
      if (this.$userType === 'Broker') {
        credentials = [...credentials.filter(credential => credential.addedByUserType !== 'MLO')];
      }
      
      const ausCredentials = 
        credentials.filter(credential => credential.vendorType === 'AUS Vendor' && credential.vendorName === 'Fannie Mae');

      const creditCredentials = 
        credentials.filter(credential => credential.vendorType === 'Credit Vendor');
      
      // Try to set as initial value the credentials registered by current user. The default is one registered by super admin
      const selfAusCredential = ausCredentials.find(credential => credential.addedBy === this.$userId);
      const superAdminAusCredential = ausCredentials.find(credential => credential.addedByUserType === 'Super Admin');
      const initialAusCredential = selfAusCredential || superAdminAusCredential;

      // Try to set as initial value the credentials registered by current user. The default is one registered by super admin
      const selfCreditCredential = creditCredentials.find(credential => credential.addedBy === this.$userId);
      const superAdminCreditCredential = creditCredentials.find(credential => credential.addedByUserType === 'Super Admin');
      const initialCreditCredential = selfCreditCredential || superAdminCreditCredential;

      return { ausCredentials, initialAusCredential, creditCredentials, initialCreditCredential, selfAusCredential };
    } catch (error) {
      console.log(error);
    }
  }

  async runAusRequestComplete() {
    this.showError = false;
    this.isAuthenticationFail = false;

    this.$modal.hide("creditRunAusFannieMaeRequest");
    this.getCreditVendors();
    this.getAusFindings();
  }

  async resetAusCaseFile() {
    try {
      this.$store.state.wemloLoader = true;
      await this.resetAusCaseFileId();
      this.$store.state.wemloLoader = false;
      await this.getCreditVendors();
      await this.getAusFindings();
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  showRunAusFile() {
    if (!this.ausFilepath) { return }
    this.$modal.show('runAusFile');
  }

  runAusRequestError() {
    this.$modal.hide("creditRunAusFannieMaeRequest");
  }

  async showRunAusFannieMaeRequestPopup() {
    this.vendorsCredentials = await this.getVendorsCredentials();
    this.vendorInfo = new CreditReport();
    await this.getAusFindings();
    this.$modal.show("creditRunAusFannieMaeRequest");
  }
}
