

















































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import {
  COUNSELING_EVENT_TYPE,
  COUNSELING_EVENT_FORMAT_TYPE,
} from "@/constants";
@Component({ components: { Datepicker } })
export default class CounselingEventComponent extends Vue {
  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public counselingEvent;
  public counselingEventTypes = COUNSELING_EVENT_TYPE;
  public counselingEventFormatTypes = COUNSELING_EVENT_FORMAT_TYPE;

  @Prop({default: false})
  public loanIsLocked: boolean;

  public async validate() {
    return await this.$validator.validateAll();
  }
}
