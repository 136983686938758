
























import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";
@Component({})
export default class fhaCalculations extends mixins(LOSMixin) {
  @Prop()
  public fhaData:any;
  public finalFhaCalculation:any = null;

  public closeModal() {
    this.$modal.hide("modalForFhaCalcultions");
    this.$emit("hideModal");
  }

  mounted() {
    this.$modal.show("modalForFhaCalcultions");
    this.finalFhaCalculation =  this.calculateMortgageInsurance(this.fhaData);
  }
}
