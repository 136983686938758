





































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { LIEN_TYPE,MISMO_FUNDS_SOURCE_TYPE,MISMO_FUNDS_SOURCE_TYPE_OTHER } from "@/constants";
@Component({})
export default class LosAdditionalLoanComponent extends Vue {
  @Prop()
  public additionalLoan;

  @Prop({ default: false })
  public isMismo: boolean;

  @Prop({ default: false })
  public isFHA: boolean;

  @Prop({ default: false })
  public isSubmitToWemlo: boolean;

  @Prop({default: false})
  public loanIsLocked: boolean;

  public lienTypes = LIEN_TYPE;
  public fundsSourceType = MISMO_FUNDS_SOURCE_TYPE;
  public fundsSourceTypeOther = MISMO_FUNDS_SOURCE_TYPE_OTHER;

  public async validate(){    
    return await this.$validator.validateAll()    
  }

  public async mounted(){  
    if (!this.additionalLoan.lienType){
      this.additionalLoan.lienType = "";
    }
  }

  public get showOtherDescription() {
    if (this.additionalLoan.originationFundsSource && this.additionalLoan.originationFundsSource.mismoValue === 'Other') {
      this.additionalLoan.originationFundsSourceOtherDescription = this.fundsSourceTypeOther[0];
      return true;
    }
    return false;
  }
}
