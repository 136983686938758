
























































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import LosAddress from "@/views/LosAddress.vue";
import LOSMixin from "@/mixins/los.mixin";
import { CardPlugin } from "bootstrap-vue";
import {
  SELF_EMPLOYED_STATUS,
  SELF_EMPLOYED_STATUS_LABELS,
} from "@/constants";
import {BASE_API_URL, EventBus} from "@/config";
import Axios from "axios";
import {
  MISMO_INCOME_SOURCE
} from "@/constants";
import { Income } from "@/models/borrowerInfo.model";
import { Address } from '@/models/pos/common.model';
import _ from "lodash";

@Component({
  components: {
    LosAddress,
  },
})
export default class EmploymentHistoryComponent extends mixins(LOSMixin) {
  @Prop()
  public currentEmploymentData;

  @Prop()
  public employmentType;

  @Prop()
  public currentIndex;
  
  @Prop()
  public isSubmitToWemlo;

  @Prop({default: false})
  public loanIsLocked: boolean;

  @Prop()
  public isLessThanThreeYear;

  public incomes: Array<Income> = [];

  public selfEmployedType = SELF_EMPLOYED_STATUS;
  public selfEmployedTypeLabel = SELF_EMPLOYED_STATUS_LABELS;

  public isMismo: boolean = true;

  public showIncomeMessage: boolean = false;

  public highLightLosRequiredField(key){
    return !!(
        !this.currentEmploymentData.isRetired &&
        !this.currentEmploymentData.isNotEmployed &&
        !this.currentEmploymentData[key] &&
        this.isSubmitToWemlo &&
        (this.employmentType == 'current' || (this.employmentType == 'previous' && this.isLessThanThreeYear)));
  }

  public disableEmploymentFields(){
      this.currentEmploymentData.employerName = null;
      this.currentEmploymentData.address = new Address();
      this.currentEmploymentData.phone = null;
      this.currentEmploymentData.industry = null;
      this.currentEmploymentData.selfEmployedIndicator.isSelfEmployed = false;
      this.currentEmploymentData.selfEmployedIndicator.selfEmployedType = null;
      this.currentEmploymentData.startDate.day = null;
      this.currentEmploymentData.startDate.month = null;
      this.currentEmploymentData.startDate.year = null;
      this.currentEmploymentData.position = null;
      this.currentEmploymentData.workingPeriod.years = null;
      this.removeAllIncomesFromList();
  }

  public isSelfEmployedChange() {
    this.incomes = [];
    this.currentEmploymentData.selfEmployedIndicator.monthlyIncome = 0;
    this.currentEmploymentData.selfEmployedIndicator.selfEmployedType = null;
    this.currentEmploymentData.selfEmployedIndicator.ownershipPercentage = null;
    this.currentEmploymentData.foreignIncomeIndicator = null;
    this.currentEmploymentData.seasonalIncomeIndicator = null;
    this.currentEmploymentData.militaryIndicator = null;
  }

  public handleHasNoJobCheckbox(e, type) {
    if (!this.hasNoJob) {
      e.preventDefault()
      e.stopImmediatePropagation()
      this.$modal.show('lostEmploymentInfoModal', { type })
    }
  }

  public setHasNoJobCheckbox(selectedHasNoJobCheckbox) {
      this.currentEmploymentData[selectedHasNoJobCheckbox] = true
  }

  async getEnumerationType() {
    try {
      const { data: { enumerationType } } = await Axios.post(
        BASE_API_URL + 'los/getEnumerationType',
        { loanTxnId: this.$route.query.id, }
      );

      return enumerationType;
    } catch(error) {
      console.log(error);
    }
  }

  public getIncomeType() {
    return MISMO_INCOME_SOURCE.filter(income => income.isMilitary == !!this.currentEmploymentData.militaryIndicator).map(income => {
      income.disabled = !!this.incomes.find(inc => inc.type == income.value);
      return income;
    });
  }

  @Watch('currentEmploymentData.isNotEmployed')
  notEmployedChangeHandler(val: boolean) {
    this.$set(this.currentEmploymentData, 'isNotEmployed', val);
    if (val) {
      this.currentEmploymentData.isRetired = false
      this.disableEmploymentFields()
    }
  }

  @Watch('currentEmploymentData.isRetired')
  isRetiredHandler(val: boolean) {
    this.$set(this.currentEmploymentData, 'isRetired', val);
    if (val) {
      this.currentEmploymentData.isNotEmployed = false
      this.disableEmploymentFields()
    }
  }

  @Watch('currentEmploymentData')
  async onChangeCurrenIndex() {
    await this.generateListFromGrossMonthlyObject();
  }

  @Watch('incomes')
  public onChangeIncomes() {
    this.validateIncomeValues();
  }

  get hasNoJob() {
    return this.currentEmploymentData.isRetired || this.currentEmploymentData.isNotEmployed
  }


  async generateListFromGrossMonthlyObject() {
    this.incomes = [];

    /**
     * Cannot read property 'base' of undefined
     */
    if (!this.currentEmploymentData.grossMonthlyIncome) {
      return;
    }

    if(!this.currentEmploymentData.militaryIndicator) {
      if(this.currentEmploymentData.grossMonthlyIncome.base.monthlyIncome > 0)
        this.incomes.push(new Income('base', this.currentEmploymentData.grossMonthlyIncome.base.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.bonus.monthlyIncome > 0)
        this.incomes.push(new Income('bonus', this.currentEmploymentData.grossMonthlyIncome.bonus.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.commission.monthlyIncome > 0)
        this.incomes.push(new Income('commission', this.currentEmploymentData.grossMonthlyIncome.commission.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.contractBasis.monthlyIncome > 0)
        this.incomes.push(new Income('contractBasis', this.currentEmploymentData.grossMonthlyIncome.contractBasis.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.overtime.monthlyIncome > 0)
        this.incomes.push(new Income('overtime', this.currentEmploymentData.grossMonthlyIncome.overtime.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.other.monthlyIncome > 0)
        this.incomes.push(new Income('other', this.currentEmploymentData.grossMonthlyIncome.other.monthlyIncome));
    } else {
      if(this.currentEmploymentData.grossMonthlyIncome.militaryBasePay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryBasePay', this.currentEmploymentData.grossMonthlyIncome.militaryBasePay.monthlyIncome));
      
      if(this.currentEmploymentData.grossMonthlyIncome.militaryClothesAllowance.monthlyIncome > 0)
        this.incomes.push(new Income('militaryClothesAllowance', this.currentEmploymentData.grossMonthlyIncome.militaryClothesAllowance.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.militaryCombatPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryCombatPay', this.currentEmploymentData.grossMonthlyIncome.militaryCombatPay.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.militaryFlightPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryFlightPay', this.currentEmploymentData.grossMonthlyIncome.militaryFlightPay.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.militaryHazardPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryHazardPay', this.currentEmploymentData.grossMonthlyIncome.militaryHazardPay.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.militaryOverseasPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryOverseasPay', this.currentEmploymentData.grossMonthlyIncome.militaryOverseasPay.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.militaryPropPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryPropPay', this.currentEmploymentData.grossMonthlyIncome.militaryPropPay.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome > 0)
        this.incomes.push(new Income('militaryQuartersAllowance', this.currentEmploymentData.grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.militaryRationsAllowance.monthlyIncome > 0)
        this.incomes.push(new Income('militaryRationsAllowance', this.currentEmploymentData.grossMonthlyIncome.militaryRationsAllowance.monthlyIncome));

      if(this.currentEmploymentData.grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome > 0)
        this.incomes.push(new Income('militaryVariableHousingAllowance', this.currentEmploymentData.grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome));
    }
  }

  public addIncome(hasNoJob) {
    if(!hasNoJob) {
      this.incomes.push(new Income(null,0));
    }
  }

  public changeIncomeValue(index, value) {
    if (this.incomes[index].type) {
      this.currentEmploymentData.grossMonthlyIncome[this.incomes[index].type].monthlyIncome = value;
    }
  }

  public removeIncomeFromList(index, splice) {
    if (this.incomes[index].type) {
      this.currentEmploymentData.grossMonthlyIncome[this.incomes[index].type].monthlyIncome = 0;
    }
    this.incomes.splice(index, splice);
  }

  public removeAllIncomesFromList() {
      this.incomes.forEach(income => this.currentEmploymentData.grossMonthlyIncome[income.type].monthlyIncome = 0);
      this.incomes = [];
  }

  public validateIncomeValues() {
    this.showIncomeMessage = false;
    if (_.get(this.currentEmploymentData, 'selfEmployedIndicator.isSelfEmployed') && _.get(this.currentEmploymentData, 'selfEmployedIndicator.monthlyIncome') <= 0) {
      this.showIncomeMessage = true;
    } else if(!_.get(this.currentEmploymentData, 'selfEmployedIndicator.isSelfEmployed')) {
      const incomesAux = this.incomes.filter(income => income.value > 0);
      if (incomesAux.length == 0) {
        this.showIncomeMessage = true;
      }
    }  
    if (this.hasNoJob) {
      this.showIncomeMessage = false;
    }
  }

  async mounted() {
    EventBus.$on('has-no-job-change', this.setHasNoJobCheckbox)
    if (this.$route.query.id) {
      this.isMismo = (await this.getEnumerationType()) === 'mismo';
    }
    if(this.currentIndex == 0 && this.employmentType=='current' && this.hasNoJob) {
      this.disableEmploymentFields()
    }
    await this.generateListFromGrossMonthlyObject();
  }

  beforeDestroy() {
    EventBus.$off('has-no-job-change', this.setHasNoJobCheckbox)
  }

}
