

import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import { mixins } from "vue-class-component";
import { LIABILITIES_TYPE, MISMO_LIABILITIES_TYPE, LIABILITIES_MORTGAGE_TYPE } from "@/constants";
import { Liabilities } from "@/models/pos/financial-info.model";
import customInput from "@/views/CustomInput.vue";
import LosRequiredField from "@/views/LosRequiredField.vue";
import LOSMixin from "@/mixins/los.mixin";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
import _ from "lodash";

@Component({ components: { customInput, LosRequiredField } })
export default class LiabilitiesComponent extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop()
  public loanId;
  @Prop()
  public type;
  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public loanIsLocked;

  public loanTxnId: any = null;
  public financialInfo: any = {liabilities: []};
  public borrowerMapping: any = {
    relationshipMapping : []
  };
  public liabilitiesTypeConstant = [];
  public liabilitiesMortgageTypeConstant = [];
  public liabilitiesRequiredList = [];
  public isFieldMissing = false;
  public enumerationType:string = null;
  public showAllLiabilities: boolean = false;
  public isFHA: boolean = false;
  public isRefinance: boolean = false;
  private initialState: any = {};
  borrowers = [];

  get isMismo() {
    return this.enumerationType === "mismo";
  }

  get hiddenLiabilities() {
    return _.get(this.financialInfo, 'liabilities', [])
      .filter(liability => {
        return !_.get(liability, 'newItem', false);
      })
      .filter(liability => {
        return _.get(liability, 'unpaidBalance.amount') === 0;
      })
  }

  public getMonthLeftToPay(liability) {
    return !liability.unpaidBalance.amount || !liability.monthlyPayment ? null : Math.ceil(liability.unpaidBalance.amount/liability.monthlyPayment);
  }
  
  showLiability(liability) {

    if (this.showAllLiabilities) {
      return true;
    }

    const wasAddedNow = _.get(liability, 'newItem', false);
    const isUnpaidBalanceZero = _.get(liability, 'unpaidBalance.amount') === 0;

    if (wasAddedNow) {
      return true;
    }

    if (isUnpaidBalanceZero) {
      return false;
    }

    return true;
  }

  toggleShowAllLiabilities() {
    if (!_.isBoolean(this.showAllLiabilities)) { return; }

    this.showAllLiabilities = !this.showAllLiabilities;
  }

  public async getLiabilitiesData() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "los/getLiabilitiesData",
        {
          loanTxnId: this.loanTxnId
        });
      this.financialInfo = response.data.financialInfo;
      this.borrowerMapping = response.data.borrowerMapping.relationshipMapping;
      this.enumerationType = response.data.borrowerMapping.loanInformation.enumerationType;
      this.borrowers = _.get(response, 'data.borrowerInfo.borrowerInfo', []);
      this.isFHA = _.get(response, 'data.borrowerMapping.loanInformation.productType.mismoValue', null) === 'FHA';
      this.isRefinance = _.get(response, 'data.loanAndProperties.loanPurpose.mismoValue', null) === 'Cash-OutRefinance';

      this.liabilitiesTypeConstant = this.isMismo ? MISMO_LIABILITIES_TYPE : LIABILITIES_TYPE;

      this.liabilitiesMortgageTypeConstant = LIABILITIES_MORTGAGE_TYPE;

      this.financialInfo.liabilities.forEach((element, i) => {
        this.borrowerMapping.forEach(el => {
          if (element.liabilitiesMapping.length == 0) {
            element.liabilitiesMapping.push({
              borrowerIndex: el.borrowerIndex,
              jointStatus: el.borrowerIndex == 0 ? true : false
            });
          } else {
            let result = element.liabilitiesMapping.find(
              a => a.borrowerIndex == el.borrowerIndex
            );
            if (!result) {
              element.liabilitiesMapping.push({
                borrowerIndex: el.borrowerIndex,
                jointStatus: false
              });
            }
          }
          if (el.coBorrowerIndex.length > 0) {
            el.coBorrowerIndex.forEach(e => {
              let result = element.liabilitiesMapping.find(
                a => a.borrowerIndex == e
              );
              if (!result) {
                element.liabilitiesMapping.push({
                  borrowerIndex: e,
                  jointStatus: false
                });
              }
            });
          }
        });
      });
      this.financialInfo.liabilities.forEach((el,index)=> {this.setAmount('amount',index)});
      this.initialState = this.cloneObject(this.getUpdateRequestBody());
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }
public getUpdateRequestBody() {
  return {
      loanTxnId: this.loanTxnId,
      financialInfo: this.financialInfo
  }
}

public checkLiabilities(): void {
    /**
     *  if liabilities are not joint with other borrowers and CoBorrower's(Not checked any joint button) then liabilitie active index is zero index;
     */
    this.financialInfo.liabilities.forEach(el => {
        let jointStatus = el.liabilitiesMapping.filter(
            a => a.jointStatus == true
        );
        if (jointStatus.length == 0) {
            el.borrowerIndex = 0;
            el.liabilitiesMapping.forEach(e => {
                if (e.borrowerIndex == 0) e.jointStatus = true;
            });
        }
    });
}

  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {
      this.checkLiabilities()
      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };
      this.$store.state.wemloLoader = true;
      this.saveInProcess = true;
      let response = await Axios.post(
        BASE_API_URL + "los/saveLiabilitiesData",
          requestBody,
        );
      if(tosty){
        this.notifyTosty(response.data.statusCode, response.data.message)
      }
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.state.wemloLoader = false;
      if (response.data.statusCode === 200) {
          this.initialState = this.cloneObject(requestBody);
      }
      if (!fromParent && this.$parent["checkLosFormCompleted"]) {
          this.$parent["checkLosFormCompleted"](true);
      }
      return response.data.statusCode == 200 &&
        response.data.liabilitiesError.length > 0
        ? { status: true, requiredFields: response.data.liabilitiesError }
        : { status: true, requiredFields: [] };
    } catch (error) {
      this.$store.state.wemloLoader = false;
      this.saveInProcess = false;
      console.log(error);
      return {status: false};
    }
  }

  /**
   *   Add more liabilities
   */
  addLiabilities() {
    let liabilities = new Liabilities();
    liabilities['newItem'] = true;
    liabilities["liabilitiesMapping"] = [];
    this.borrowerMapping.forEach(el => {
      liabilities.liabilitiesMapping.push({
        borrowerIndex: el.borrowerIndex,
        jointStatus: el.borrowerIndex == 0 ? true : false
      });
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach(e => {
          liabilities.liabilitiesMapping.push({
            borrowerIndex: e,
            jointStatus: false
          });
        });
      }
    });
    this.financialInfo.liabilities.push(liabilities);
  }

  public updateLosHeaderInfo() {
    let liabilities = this.financialInfo.liabilities.filter(
      a =>
        !a.unpaidBalance.toBeExclude && !a.unpaidBalance.toBePaidAtOrOffClosing
    );
    let total = this.getTotal(liabilities, "monthlyPayment");
    let helocLiabilities = this.financialInfo.liabilities.filter(
      a =>
        !a.unpaidBalance.toBeExclude &&
        !a.unpaidBalance.toBePaidAtOrOffClosing &&
        a.accountType &&
        a.accountType.value == "HELOC"
    );
    let mortgageLoanLiabilities = this.financialInfo.liabilities.filter(
      a =>
        !a.unpaidBalance.toBeExclude &&
        !a.unpaidBalance.toBePaidAtOrOffClosing &&
        a.accountType &&
        a.accountType.value == "Mortgage Loan"
    );
    let data = {
      liabilitiesTotal: total,
      helocLiabilitiesMaxAmount: this.getSum(helocLiabilities, "unpaidBalance.maxAmount"),
      helocLiabilitiesAmount: this.getSum(helocLiabilities, "unpaidBalance.amount"),
      mortgageLoanLiabilitiesAmount: this.getSum(mortgageLoanLiabilities,"unpaidBalance.amount")
    };
    this.$emit("callMountFunction", data);
  }

  /**
   *    Remove Libilities
   */
  removeLiabilities(index, liabiliy) {
    this.financialInfo.realEstate.properties.forEach(el => {
      if (index == el.liabilitiesIndex) el.liabilitiesIndex = null;
    });
    this.financialInfo.liabilities.splice(index, 1);
    this.updateLosHeaderInfo()
  }

  getTotal(data, origin) {
    let total = 0;
    if (origin == "monthlyPayment") {
      data.forEach(element => {
        total = total + parseFloat(element[origin]);
      });
    } else {
      data.forEach(element => {
        total = total + parseFloat(element[origin].payOffAmount);
      });
    }
    return total;
  }
  /**
   *    On UnCheck PayOff (Set PayOffAmount zero)
   */
  setPayOffAmount(index) {
    if (
      !this.financialInfo.liabilities[index].unpaidBalance.toBePaidAtOrOffClosing) {
      this.financialInfo.liabilities[index].unpaidBalance.payOffAmount = 0;
    } else {
      // this.financialInfo.liabilities[index].monthlyPayment = 0;
      this.financialInfo.liabilities[index].unpaidBalance.payOffAmount = this.financialInfo.liabilities[index].unpaidBalance.amount;
    }
  }

  havecoBorrower(index) {
    let haveBorrower = false;
    this.borrowerMapping.forEach(e => {
      if (index == e.borrowerIndex) {
        haveBorrower = true;
      }
    });
    return haveBorrower ? "borrower" : "co-borrower";
  }

  setAmount(type, index){
    if(type == 'amount' && this.financialInfo.liabilities[index].unpaidBalance.toBePaidAtOrOffClosing){
      this.financialInfo.liabilities[index].unpaidBalance.payOffAmount = this.financialInfo.liabilities[index].unpaidBalance.amount
    }else if(type == 'payOffAmount' && this.financialInfo.liabilities[index].unpaidBalance.toBePaidAtOrOffClosing){
      this.financialInfo.liabilities[index].unpaidBalance.amount = this.financialInfo.liabilities[index].unpaidBalance.payOffAmount
    }
    this.updateLosHeaderInfo();
  }

  setExclude(index) {
    this.financialInfo.liabilities[index].unpaidBalance.excludeType = null;
  }

  async mounted() {
    this.loanTxnId = this.loanId;
    await this.getLiabilitiesData();
    // this.$parent["checkLosFormCompleted"](true);
  }
}
