

import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import moment from "moment";

import Datepicker from "vuejs-datepicker";
import { mixins } from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";
import { loanInfo } from "@/models/borrowerData.model";
import {
  REFINANCE_PURPOSE_MISMO,
  REFINANCE_IMPROVEMENT_MISMO,
  AMORTIZATION_TYPE,
  PRODUCT_TYPE,
  LOAN_TERM,
  LOAN_PURPOSE,
  REFINANCE_PURPOSE,
  REFINANCE_PROGRAM,
  MISMO_REFIANCE_PROGRAM_OTHER,
  MISMO_AMORTIZATION_ARM_TYPES,
  MISMO_AMORTIZATION_ARM_OTHER_DESCRIPTION,
  MISMO_ACT_TYPE,
  MISMO_INVESTOR_PRODUCT_PLAN_IDENTIFIER,
  MISMO_COMMUNITY_LENDING_PRODUCT_TYPE,
  MISMO_COMMUNITY_SECONDS_REPAYMENT_TYPE,
} from "@/constants";
import LosRequiredField from "@/views/LosRequiredField.vue";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import { LogIn } from "../../../../models/login.model";
import Fha from "@/views/FhaCalculation.vue";
import _ from "lodash";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";

@Component({ components: { Datepicker, LosRequiredField, Fha } })
export default class GeneralComponent extends mixins(LOSMixin, SaveInformationMixin) {
  public loanTxnId: any = null;
  public loanInformation: any = {
    ...new loanInfo(),
    interestRate: 0,
    propertyAddress: { address: null },
    refinanceCase: {
      purposeOfRefinance: null
    },
  };
  public loanTerm = LOAN_TERM;
  public amortization = AMORTIZATION_TYPE;
  public loanPurpose = LOAN_PURPOSE;
  public refinanceTypes = REFINANCE_PURPOSE;
  public refinancePrograms = REFINANCE_PROGRAM;
  public otherRefinancePrograms = MISMO_REFIANCE_PROGRAM_OTHER;
  public amortizationARMTypes = MISMO_AMORTIZATION_ARM_TYPES;
  public amortizationARMOtherDescription = MISMO_AMORTIZATION_ARM_OTHER_DESCRIPTION;  
  public investorTypes = MISMO_INVESTOR_PRODUCT_PLAN_IDENTIFIER;
  public refinancePrimaryPurposeTypes = REFINANCE_PURPOSE_MISMO;
  public refinanceImprovementsTypes = REFINANCE_IMPROVEMENT_MISMO;
  public lendingProducTypes = MISMO_COMMUNITY_LENDING_PRODUCT_TYPE;
  public secondRepaymentTypes = MISMO_COMMUNITY_SECONDS_REPAYMENT_TYPE;
  public disabledDate = null;
  public productType = PRODUCT_TYPE;
  public actType = MISMO_ACT_TYPE;
  public generalInfoRequiredList = [];
  public financialInfo = new FinancialInfo();
  public fundingFee: any = 0;
  public openFhaModal = false;
  public fhaData: any = null;
  public VAdata: any = null;
  public enumerationType: string = null;
  public brokerDetail: object = null;
  public ausCasefileId: string = null;
  public loanNumber: string = null;
  private initialState: any = {};
  public constructionLoanType = null;

  @Prop()
  public isSubmitToWemlo;
  @Prop({ required: true }) public generalData: any;
  private invalidLoanTypes = ['USDA Rural', 'Other'];
  @Prop()
  public loanIsLocked;

  get showCreditReportFirstPulledDate() {
    return _.get(this.brokerDetail, 'mortgageCallReportsSetting.useCreditReportedDate', false);
  }

  get actTypeList() {
    if(this.enumerationType === 'mismo') {
      return PRODUCT_TYPE.filter(product => !product.name.includes('FHA '));
    }
    return PRODUCT_TYPE;
  }

  get loanType() {
    return _.get(this, 'loanInformation.productType.mismoValue', null);
  }

  get isInvalidProductType() {
    return this.invalidLoanTypes.includes(this.loanType);
  }

  get isAllowedProductType() {
    return _.get(this.brokerDetail, 'allowedProductType', []).includes(this.loanType);
  }

  get isRefinance() {
    return _.get(this, 'loanInformation.loanPurpose.ediValue') === "05";
  }

  get isFHAOrVA() {
    const productType = _.get(this, 'loanInformation.productType', {});
    return productType.mismoValue == 'FHA' || productType.mismoValue == 'VA';
  }

  get amortizationType() {
    return _.get(this, 'loanInformation.amortizationType', null);
  }

  get amortizationTypeName() {
    return _.get(this, 'loanInformation.amortizationType.name', null);
  }

  get purposeOfRefinance() {
    return _.get(this, 'loanInformation.refinanceCase.purposeOfRefinance', null);
  }

  @Watch('generalData', { immediate: true })
  onGeneralDataChange(data) {
    if (data) {
      this.loanInformation = data.loanInformation;
      this.loanNumber = data.loanNumber;
      this.disabledDate = new Date(this.loanInformation.createdOn);
      this.calculator();
      this.enumerationType = this.loanInformation.enumerationType;
      this.ausCasefileId = data.ausCasefileId;
      this.initialState = this.cloneObject(this.getUpdateRequestBody());
    }
  }

  public getUpdateRequestBody() {
      return {
          loanTxnId: this.$route.query.id,
          loanInformation: _.cloneDeep(this.loanInformation),
          ausCasefileId: this.ausCasefileId,
          loanNumber: this.loanNumber,
      }
  }

  public onChangeClosingDate(closingDate) {
    if (closingDate instanceof Date) {
      this.loanInformation.closingDate = moment(closingDate).utc().hours(12).minutes(0).toDate();
    }
  }

  /********************************************************************************************************************************
   *                              This Function Save Loan General Information
   *                             used manager :-> generalInfo.manager.js
   ********************************************************************************************************************************/
  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {
      /**
       * Just trigger the validation
       */
      await this.$validator.validateAll();

      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };

      this.$store.commit('SET_WEMLO_LOADER', true);
      this.saveInProcess = true;

      let response = await Axios.post(
        BASE_API_URL + "los/updateGeneralData",
        requestBody);
      if (tosty) {
        this.$snotify.clear();
        this.$snotify[response.data.statusCode == 200 ? "success" : "error"](
          response.data.message
        );
      }
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.commit('SET_WEMLO_LOADER', false);
      if (response.data.statusCode === 200) {
        this.initialState = this.cloneObject(requestBody);
      }
      if(!fromParent && this.$parent["checkLosFormCompleted"])  this.$parent["checkLosFormCompleted"](true);
      return response.data.statusCode == 200 &&
        response.data.generalError.length > 0
        ? { status: true, requiredFields: response.data.generalError }
        : { status: true, requiredFields: [] };
    } catch (error) {
      this.$store.commit('SET_WEMLO_LOADER', false);
      this.saveInProcess = false;
      console.log(error);
      return { status: false };
    }
  }

  public toCheckRefinanceType(type) {
    return type;
  }

  /**
   *  Caluclate monthly payment
   */

  public async calculator() {
    let interestRate = this.loanInformation.interestRate;
    let loanAmount = this.loanInformation.loanAmount;
    let termMonth = this.loanInformation.term
      ? this.loanInformation.term.value
      : null;
    this.loanInformation.refinanceCase.purposeOfRefinance = this.loanInformation.loanPurpose && this.loanInformation.loanPurpose.value == "Purchase" ? null : this.loanInformation.refinanceCase.purposeOfRefinance;
    if (
      this.loanInformation.productType &&
      (this.loanInformation.productType.ediValue == "03" ||
        this.loanInformation.productType.ediValue == "02")
    ) {
      let data = {
        mortgageAppliedFor: this.loanInformation.productType,
        purchasePrice: this.loanInformation.propertyValue,
        loanAmount: this.loanInformation.loanAmount,
        loanPurpose: this.loanInformation.loanPurpose,
        veteranStatus: this.loanInformation.veteranStatus,
        refinanceCase: this.loanInformation.refinanceCase,
        purposeOfRefinance: this.loanInformation.refinanceCase.purposeOfRefinance
      };
      let info: any = this.calculateFundingFee(data);
      this.fundingFee = info.fundingFee;
    } else {
      this.fundingFee = 0;
    }
    if (this.fundingFee)
      loanAmount = parseFloat(loanAmount) + parseFloat(this.fundingFee);
    let dpt = this.loanInformation.minDownPaymentType;
    let pv: any = parseFloat(this.loanInformation.propertyValue);
    let la: any = parseFloat(this.loanInformation.loanAmount);
    let monthlyPayment: any = 0;
    if (interestRate && loanAmount && termMonth) {
      interestRate = interestRate / 100 / 12;
      let newInterest = interestRate + 1;
      let numerator = Math.pow(newInterest, termMonth);
      let newValue = (numerator * interestRate) / (numerator - 1);
      let calculateValue = newValue * loanAmount;
      monthlyPayment = Math.round(calculateValue * 100) / 100;
    }
    if (dpt == "$") {
      this.loanInformation.minDownPayment = pv - la;
    } else {
      this.loanInformation.minDownPayment = ((pv - la) / pv) * 100;
    }
    monthlyPayment = isNaN(monthlyPayment) ? 0 : monthlyPayment;
    this.loanInformation.mortgageMonthlyPayment = monthlyPayment;
    await this.calculateLTVAndCLTV();
  }

  public calculateLoanAmount() {
    try {
      let propertyValue = this.loanInformation.propertyValue;
      let LTV = this.loanInformation.LTV;
      if (propertyValue && LTV) {
        let lm = (LTV * propertyValue) / 100;
        this.loanInformation.loanAmount = lm % 1 ? lm.toFixed(2) : lm;
      }
      this.calculator();
    } catch (error) {}
  }

  public calculateLTVAndCLTV() {
    let propertyValue = this.loanInformation.propertyValue || this.loanInformation.estimatedPropertyValue;
    let loanAmount = this.loanInformation.loanAmount;
    if (propertyValue && loanAmount && loanAmount <= propertyValue) {
      this.loanInformation.LTV = (loanAmount / propertyValue) * 100;
      this.loanInformation.LTV = parseFloat(this.loanInformation.LTV.toFixed(2));
    } else this.loanInformation.CLTV = this.loanInformation.LTV = 0;
    this.updateLosHeaderInfo();
  }

  /****************************************************************************************************************************************************************
   *                                   This function emit general component changes on LOS-Header component                                                       *
   ****************************************************************************************************************************************************************/
  updateLosHeaderInfo() {
    let loanAmount: any = parseFloat(this.loanInformation.loanAmount);
    let propertyValue: any = parseFloat(this.loanInformation.propertyValue);
    let interestRate: any = parseFloat(this.loanInformation.interestRate);
    let data: any = {
      loanAmount: loanAmount.toFixed(2),
      purchasePrice: propertyValue.toFixed(2),
      interestRate: interestRate.toFixed(3),
      FICO: this.loanInformation.FICO,
      loanPurpose: this.loanInformation.loanPurpose,
      LTV: this.loanInformation.LTV,
      loanTerm: this.loanInformation.term
    };
    this.$emit("callMountFunction", data);
  }

  public showStreamLine(type) {
    if (type && type.ediValue == 'F1' && this.loanInformation)
      return this.loanInformation.productType
        ? this.loanInformation.productType.ediValue == "01"
          ? false
          : true
        : true;
    else return true;
  }

  public reSetFields() {
    if (
      this.loanInformation &&
      this.loanInformation.productType &&
      this.loanInformation.productType.ediValue == "01" &&
      this.loanInformation.refinanceCase.purposeOfRefinance &&
      this.loanInformation.refinanceCase.purposeOfRefinance.ediValue == "F1"
    )
      this.loanInformation.refinanceCase.purposeOfRefinance = null;

    if (
      this.loanInformation &&
      this.loanInformation.productType &&
      this.loanInformation.productType.ediValue != "02"
    )
      this.loanInformation.veteranStatus = null;
  }

  fhaCalculation() {
    this.openFhaModal = true;
    this.fhaData = {
      loanAmount: this.loanInformation.loanAmount,
      LTV: this.loanInformation.LTV,
      term: this.loanInformation.term.value / 12
    };
  }

  fundingFeeCalculation() {
    this.$modal.show("modalForFundingFee");
    this.VAdata = {
       mortgageAppliedFor: this.loanInformation.productType,
        purchasePrice: this.loanInformation.propertyValue,
        loanAmount: this.loanInformation.loanAmount,
        loanPurpose: this.loanInformation.loanPurpose,
        veteranStatus: this.loanInformation.veteranStatus,
        refinanceCase: this.loanInformation.refinanceCase,
        purposeOfRefinance: this.loanInformation.refinanceCase.purposeOfRefinance,
    };
    let data: any = this.calculateFundingFee(this.VAdata);
    this.fundingFee = data.fundingFee;
    this.VAdata["fundingFeePercentage"] = (data.feePercentage * 100).toFixed(2);
  }

  public async getBrokerDetails() {
    try {
      this.$store.commit('SET_WEMLO_LOADER', true);

      let response = await Axios.get(BASE_API_URL + "broker/getBrokerDetails", {
        params: { brokerId: this.$brokerId }
      });

      this.brokerDetail = response.data.brokerDetail;
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.commit('SET_WEMLO_LOADER', false);
    }
  }
  
  public async getConstructionLoanType() {
    try {
      this.$store.commit('SET_WEMLO_LOADER', true);

      let response = await Axios.get(BASE_API_URL + "pos/getLoanAndPropertyDetail", {
        params: { loanTxnId: this.loanTxnId }
      });
      this.constructionLoanType = _.get(response.data, ['propertyDetail', 'constructionCase', 'constructionLoanType'], '');
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.commit('SET_WEMLO_LOADER', false);
    }
  }

  async mounted() {
    this.loanTxnId = this.$route.query.id;
    await this.getBrokerDetails();
    await this.getConstructionLoanType();
    // TODO FIXME @pyadala @qcu / @craig -- we should check router history and if (prev != LoanDocs && prev != AnLOStab), then execute checkLosFormCompleted server-side validation -- https://github.com/vuejs/vue-router/issues/953
    // this.$parent["checkLosFormCompleted"](true); // TODO FIXME @pyadala -- FYI @qcu decided to disable this for smartdocs MVP, as clicking on the LOS link from the modal in the LoanDocuments view was immediately popping the validation modal in the LOS, jarring, and we are protected by Save & Submit
  }
}
