

import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { BASE_API_URL } from "@/config";

import Axios from "axios";
import { OTHER_INCOME_SOURCE, MISMO_OTHER_INCOME_SOURCE, } from "@/constants";
import { IncomeSource } from "@/models/pos/borrower-info.model";
import LosRequiredField from "@/views/LosRequiredField.vue";
import LOSMixin from "@/mixins/los.mixin";
import customInput from "@/views/CustomInput.vue";
import _ from "lodash";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";

@Component({
  components: { LosRequiredField, customInput }
})
export default class IncomeComponent extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop()
  public loanId;

  @Prop()
  public type;
  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public loanIsLocked;

  public incomeData: any = null;
  public borrowerMapping: any = null;
  public saveInProcess = false;
  public incomeRequiredList = [];
  public isFieldMissing = false;
  public primaryBorrowerIndex: any = null;
  public havePosValidation = false;
  public enumerationType: string = null;
  public currentEmploymentData: any = [];
  private initialState: any = {};


  get isMismo() {
    return this.enumerationType === "mismo";
  }

  public async getIncomeData() {
    try {
      this.$store.commit('SET_WEMLO_LOADER', true);
      let response = await Axios.post(
        BASE_API_URL + "los/getIncomeData",
        { loanTxnId: this.loanId });
      this.incomeData = response.data.incomeData.borrowerInfo;
      this.primaryBorrowerIndex =
        response.data.borrowerMapping.primaryBorrowerIndex;
      this.borrowerMapping = response.data.borrowerMapping.relationshipMapping;
      this.primaryBorrowerIndex =
        response.data.borrowerMapping.primaryBorrowerIndex;
      this.enumerationType =
        response.data.borrowerMapping.loanInformation.enumerationType;

      this.fixIncomeFromOtherSources();
      this.sumCurrentIncomes();
      this.initialState = this.cloneObject(this.getUpdateRequestBody());
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.commit('SET_WEMLO_LOADER', false);
    }
  }

  /**
   * This will sync incomeSource, incomeFromOtherSources array length should be the same for all borrowers and coBorrowers
   */
  fixIncomeFromOtherSources() {
    let incomeSourceLength = this.incomeData[0].incomeFromOtherSources.incomeSource.length;

    if (incomeSourceLength > 1) {
      this.borrowerMapping.forEach(borrower => {
        let borrowerIncomeSource = this.incomeData[borrower.borrowerIndex].incomeFromOtherSources.incomeSource;

        if (borrowerIncomeSource.length < incomeSourceLength) {
          for (let i = borrowerIncomeSource.length; i <= incomeSourceLength; i++) {
            borrowerIncomeSource.push(new IncomeSource());
          }
        }

        if (borrower.coBorrowerIndex.length > 0) {
          borrower.coBorrowerIndex.forEach(coBorrowerIndex => {
            let coborrowerIncomeSource = this.incomeData[coBorrowerIndex].incomeFromOtherSources.incomeSource;

            if (coborrowerIncomeSource.length < incomeSourceLength) {
              for (let i = coborrowerIncomeSource.length; i <= incomeSourceLength; i++) {
                coborrowerIncomeSource.push(new IncomeSource());
              }
            }
          });
        }
      });
    }
  }

  sumCurrentIncomes() {
    if(this.isMismo) {
      const types = [
        'base',
        'overtime',
        'bonus',
        'commission',
        'dividends',
        'netRentalIncome',
        'other',
        'contractBasis',
        'militaryBasePay',
        'militaryClothesAllowance',
        'militaryCombatPay',
        'militaryFlightPay',
        'militaryHazardPay',
        'militaryOverseasPay',
        'militaryPropPay',
        'militaryQuartersAllowance',
        'militaryRationsAllowance',
        'militaryVariableHousingAllowance',
        'selfEmployedIndicator'
      ];
      this.borrowerMapping.forEach(el => {
        this.currentEmploymentData[el.borrowerIndex] = this.generateEmptyEmployment();
        types.forEach(type => {
          if('selfEmployedIndicator' == type) {
            this.incomeData[el.borrowerIndex].currentEmployment.forEach(employment => {
              if(employment.selfEmployedIndicator.monthlyIncome) {
                this.currentEmploymentData[el.borrowerIndex].selfEmployedIndicator.monthlyIncome = this.currentEmploymentData[el.borrowerIndex].selfEmployedIndicator.monthlyIncome + employment.selfEmployedIndicator.monthlyIncome;
              }
            });
            if (el.coBorrowerIndex.length > 0) {
              el.coBorrowerIndex.forEach(e => {
                this.incomeData[e].currentEmployment.forEach(cEmployment => {
                  if(cEmployment.selfEmployedIndicator.monthlyIncome){
                    this.currentEmploymentData[e].selfEmployedIndicator.monthlyIncome = this.currentEmploymentData[e].selfEmployedIndicator.monthlyIncome + cEmployment.selfEmployedIndicator.monthlyIncome;
                  }
                });
              });
            }
          } else {
            this.incomeData[el.borrowerIndex].currentEmployment.forEach(employment => {
              if(employment.grossMonthlyIncome[type].monthlyIncome) {
                this.currentEmploymentData[el.borrowerIndex].grossMonthlyIncome[type].monthlyIncome = this.currentEmploymentData[el.borrowerIndex].grossMonthlyIncome[type].monthlyIncome + employment.grossMonthlyIncome[type].monthlyIncome;
              }
            });
            if (el.coBorrowerIndex.length > 0) {
              el.coBorrowerIndex.forEach(e => {
                if(!this.currentEmploymentData[e]) {
                  this.currentEmploymentData[e] = this.generateEmptyEmployment();
                }
                this.incomeData[e].currentEmployment.forEach(cEmployment => {
                  if(cEmployment.grossMonthlyIncome[type].monthlyIncome) {
                    this.currentEmploymentData[e].grossMonthlyIncome[type].monthlyIncome = this.currentEmploymentData[e].grossMonthlyIncome[type].monthlyIncome + cEmployment.grossMonthlyIncome[type].monthlyIncome;
                  }
                });
              });
            }
          }
        });
      });      
    }
  }

  generateEmptyEmployment() {
    return {
      'grossMonthlyIncome': {
        'base': {
          'monthlyIncome': 0
        },
        'overtime': {
          'monthlyIncome': 0
        },
        'bonus': {
          'monthlyIncome': 0
        },
        'commission': {
          'monthlyIncome': 0
        },
        'dividends': {
          'monthlyIncome': 0
        },
        'netRentalIncome': {
          'monthlyIncome': 0
        },
        'other': {
          'monthlyIncome': 0
        },
        'contractBasis': {
          'monthlyIncome': 0
        },
        'militaryBasePay': {
          'monthlyIncome': 0
        },
        'militaryClothesAllowance': {
          'monthlyIncome': 0
        },
        'militaryCombatPay': {
          'monthlyIncome': 0
        },
        'militaryFlightPay': {
          'monthlyIncome': 0
        },
        'militaryHazardPay': {
          'monthlyIncome': 0
        },
        'militaryOverseasPay': {
          'monthlyIncome': 0
        },
        'militaryPropPay': {
          'monthlyIncome': 0
        },
        'militaryQuartersAllowance': {
          'monthlyIncome': 0
        },
        'militaryRationsAllowance': {
          'monthlyIncome': 0
        },
        'militaryVariableHousingAllowance': {
          'monthlyIncome': 0
        }
      },
      'selfEmployedIndicator' : {
        'monthlyIncome': 0
      }
    }
  }
  public getUpdateRequestBody() {
    const incomePrimaryBorrower = this.incomeData[this.primaryBorrowerIndex];
    this.incomeData.forEach(income => {
      income.incomeFromOtherSources.incomeSource.forEach((incomeSource, index) => {
        incomeSource.name = incomePrimaryBorrower.incomeFromOtherSources.incomeSource[index].name;
        incomeSource.incomeTypeOtherDescription = incomePrimaryBorrower.incomeFromOtherSources.incomeSource[index].incomeTypeOtherDescription;
      });
    });
    return {
        loanTxnId: this.loanId,
        incomeData: this.incomeData
    }
  }

  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {
      let totalSum = this.getborrowerGrossMonthlyIncome()
      if(this.type == 'POS' && totalSum == 0){
        this.havePosValidation = true;
        return {status:false}
      }

      for (let i = 0; i < this.incomeData[0].incomeFromOtherSources.incomeSource.length; i++) {
        await this.$refs.otherSource[i]["validateAll"]();
      }
      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };
      this.$store.state.wemloLoader = true;
      this.saveInProcess = true;
      let response = await Axios.post(
        BASE_API_URL + "los/saveIncomeData",
        requestBody);
      if(tosty){
        this.$snotify.clear();
        this.$snotify[response.data.statusCode == 200 ? "success" : "error"](response.data.message);
      }
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.state.wemloLoader = false;
      if (response.data.statusCode === 200) {
          this.initialState = this.cloneObject(requestBody);
      }
      if(!fromParent && this.$parent["checkLosFormCompleted"]) {
          this.$parent["checkLosFormCompleted"](true);
      }
      return response.data.statusCode == 200 &&
        response.data.incomeError.length > 0
        ? { status: true, requiredFields: response.data.incomeError }
        : { status: true, requiredFields: [] };
    } catch (error) {
      this.$store.state.wemloLoader = false;
      this.saveInProcess = false;
      console.log(error);
      return {status:false}
    }
  }

  /**
   *  sum of all other incomes
   */
  public AddOtherIncome() {
    this.borrowerMapping.forEach(el => {
      this.incomeData[el.borrowerIndex].incomeFromOtherSources.incomeSource.push(new IncomeSource());
      if (el.coBorrowerIndex.length) {
        el.coBorrowerIndex.forEach(e => {
          this.incomeData[e].incomeFromOtherSources.incomeSource.push(new IncomeSource());
        });
      }
    });
  }
  /**
   *   remove other income sources
   */
  public removeOtherIncomeSource(index) {
    this.borrowerMapping.forEach(el => {
      this.incomeData[
        el.borrowerIndex
      ].incomeFromOtherSources.incomeSource.splice(index, 1);
      this.setOtherIncomeValue(el.borrowerIndex);
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach(e => {
          this.incomeData[e].incomeFromOtherSources.incomeSource.splice(index,1);
          this.setOtherIncomeValue(e);
        });
      }
    });
    if(this.incomeData[this.primaryBorrowerIndex].incomeFromOtherSources.incomeSource.length == 0)
      this.AddOtherIncome();
  }

  public getSumofIncomeOfBorrowerAndCoBorrowers(incomeType) {
    let monthlyIncome = [];
    if(this.isMismo) {
      this.borrowerMapping.forEach(el => {
        this.incomeData[el.borrowerIndex].currentEmployment.forEach(employment => {
          monthlyIncome.push(
            employment.grossMonthlyIncome[incomeType].monthlyIncome
          );
        })
        if (el.coBorrowerIndex.length > 0) {
          el.coBorrowerIndex.forEach(e => {
            this.incomeData[e].currentEmployment.forEach(cEmployment => {
              monthlyIncome.push(
                cEmployment.grossMonthlyIncome[incomeType].monthlyIncome
              );
            })
          });
        }
      });
    } else {
      this.borrowerMapping.forEach(el => {
        monthlyIncome.push(
          this.incomeData[el.borrowerIndex].currentEmployment[0]
            .grossMonthlyIncome[incomeType].monthlyIncome
        );
        if (el.coBorrowerIndex.length > 0) {
          el.coBorrowerIndex.forEach(e => {
            monthlyIncome.push(
              this.incomeData[e].currentEmployment[0].grossMonthlyIncome[
                incomeType
              ].monthlyIncome
            );
          });
        }
      });
    }
    return this.getSum(monthlyIncome);
  }

  public getSumOfSelfEmployedOfBorrowerAndCoBorrowers(index) {
    let monthlyIncome = [];
    if(!_.isNull(index)) {
      this.incomeData[index].currentEmployment.forEach(employment => {
        monthlyIncome.push(
          employment.selfEmployedIndicator.monthlyIncome
        );
      });
    } else {
      this.borrowerMapping.forEach(el => {
        this.incomeData[el.borrowerIndex].currentEmployment.forEach(employment => {
          monthlyIncome.push(
            employment.selfEmployedIndicator.monthlyIncome
          );
        })
        if (el.coBorrowerIndex.length > 0) {
          el.coBorrowerIndex.forEach(e => {
            this.incomeData[e].currentEmployment.forEach(cEmployment => {
              monthlyIncome.push(
                cEmployment.selfEmployedIndicator.monthlyIncome
              );
            });
          });
        }
      });
    }
    return this.getSum(monthlyIncome);
  }

  public getSumOfIncomeOtherSources(borrowerIndex) {
    let borrowerInomeOtherSources = [];
    if(this.isMismo && borrowerIndex != null) {
      borrowerInomeOtherSources.push(
        this.getSum(
          this.incomeData[borrowerIndex].incomeFromOtherSources.incomeSource,
          "monthlyIncome"
        )
      );
    } else {
      this.borrowerMapping.forEach(el => {
      borrowerInomeOtherSources.push(
        this.getSum(
          this.incomeData[el.borrowerIndex].incomeFromOtherSources.incomeSource.filter(item => item.name),
          "monthlyIncome"
        )
      );
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach(e => {
          borrowerInomeOtherSources.push(
            this.getSum(
              this.incomeData[e].incomeFromOtherSources.incomeSource,
              "monthlyIncome"
            )
          );
        });
      }
    });
    }
    return this.getSum(borrowerInomeOtherSources);
  }

  public getSumOfIncomeOtherSourcesAgainstIndex(index) {
    let borrowerInomeOtherSources = [];
    this.borrowerMapping.forEach(el => {
      borrowerInomeOtherSources.push(
        this.getSum(
          [
            this.incomeData[el.borrowerIndex].incomeFromOtherSources
              .incomeSource[index]
          ],
          "monthlyIncome"
        )
      );
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach(e => {
          borrowerInomeOtherSources.push(
            this.getSum(
              [this.incomeData[e].incomeFromOtherSources.incomeSource[index]],
              "monthlyIncome"
            )
          );
        });
      }
    });
    return this.getSum(borrowerInomeOtherSources);
  }

  getborrowerGrossMonthlyIncome() {
    let total:any = 0;
    total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("base");
    total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("overtime");
    total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("bonus");
    total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("commission");
    total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("dividends");
    total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("netRentalIncome");
    if(this.isMismo) {
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("other");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("contractBasis");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryBasePay");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryClothesAllowance");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryCombatPay");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryFlightPay");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryHazardPay");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryOverseasPay");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryPropPay");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryQuartersAllowance");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryRationsAllowance");
      total = total + this.getSumofIncomeOfBorrowerAndCoBorrowers("militaryVariableHousingAllowance");
      total = total + this.getSumOfSelfEmployedOfBorrowerAndCoBorrowers(null);
    }
    total = total + this.getSumOfIncomeOtherSources(null);
    total = parseFloat(total).toFixed(2);
    let grossMonthlyIncome = JSON.parse(JSON.stringify(total));
    let primaryBorrowerNetRenatlIncome = null;
    if(this.isMismo) {
      primaryBorrowerNetRenatlIncome = _.sum(this.incomeData[this.primaryBorrowerIndex].currentEmployment.map(employment => employment.grossMonthlyIncome.netRentalIncome.monthlyIncome));
    } else {
      primaryBorrowerNetRenatlIncome = this.incomeData[this.primaryBorrowerIndex].currentEmployment[0].grossMonthlyIncome.netRentalIncome.monthlyIncome;
    }
    grossMonthlyIncome = grossMonthlyIncome - parseFloat(primaryBorrowerNetRenatlIncome);
    this.$emit("callMountFunction", { grossMonthlyIncome: grossMonthlyIncome });
    return total;
  }

  setOtherIncomeType(index, name) {
    this.borrowerMapping.forEach(el => {
      this.incomeData[el.borrowerIndex].incomeFromOtherSources.incomeSource[
        index
      ].name = name;
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach(e => {
          this.incomeData[e].incomeFromOtherSources.incomeSource[
            index
          ].name = name;
        });
      }
    });
  }
  /**
   *  check coBorrower have or not
   */
  haveCoborrower(index) {
    if (this.borrowerMapping[index].coBorrowerIndex.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkValidationRequired(index) {
    let validation = false;
    this.borrowerMapping.forEach(el => {
      if (
        this.incomeData[el.borrowerIndex].incomeFromOtherSources.incomeSource[
          index
        ].monthlyIncome > 0
      )
        validation = true;
      el.coBorrowerIndex.forEach(cb => {
        if (
          this.incomeData[cb].incomeFromOtherSources.incomeSource[index]
            .monthlyIncome > 0
        )
          validation = true;
      });
    });
    return validation;
  }

  setOtherIncomeValue(index) {
    if(!this.isMismo) {
      this.incomeData[index
    ].currentEmployment[0].grossMonthlyIncome.other.monthlyIncome = this.getSum(this.incomeData[index].incomeFromOtherSources.incomeSource,
      "monthlyIncome");
    }    
  }

  otherIncomeSourceList(index) {
    let otherIncome = JSON.parse(JSON.stringify(OTHER_INCOME_SOURCE));
    if(this.isMismo) {
      otherIncome = JSON.parse(JSON.stringify(MISMO_OTHER_INCOME_SOURCE));
    }
    this.incomeData[0].incomeFromOtherSources.incomeSource.forEach((el, i) => {
      if (index != i && el.name && el.name.ediValue) {
        otherIncome = otherIncome.filter(a => a.ediValue != el.name.ediValue);
      }
    });
    return otherIncome;
  }

  public getGrossMonthlyIncomeMismo(currentEmployment) {
    if(currentEmployment) {
      let value = 0;
      currentEmployment.forEach(current => {
        value += this.sumGrossMonthlyMismo(current.grossMonthlyIncome);
      });
      return value;
    }
    return 0;
  }

   sumGrossMonthlyMismo(obj) {
    let arr = [];
    for (const key in obj) {
      if (
        key != "militaryEntitlements" &&
        obj.hasOwnProperty(key) &&
        obj[key].monthlyIncome != undefined &&
        obj[key].monthlyIncome != "" &&
        obj[key].monthlyIncome != null
      )
        arr.push(obj[key].monthlyIncome);
    }
    let sum = arr.reduce(function (a, b) {
      return parseFloat(a) + parseFloat(b);
    }, 0);
    return sum;
  }

  public showOrHideIncomeItem(type) {
    if(type === 'monthlyIncome') {
      return this.currentEmploymentData.filter(current => current.selfEmployedIndicator.monthlyIncome > 0).length > 0;
    }
    return this.currentEmploymentData.filter(current => current.grossMonthlyIncome[type].monthlyIncome > 0).length > 0;
  }

  async mounted() {
    await this.getIncomeData();
  }
}
