

import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import { mixins } from "vue-class-component";
import Axios from "axios";

import { LIABILITIES_EXPENSES } from "@/constants";
import { OtherLiabilitiesAndExpenses } from "@/models/pos/borrower-info.model";
import LosRequiredField from "@/views/LosRequiredField.vue";
import customInput from "@/views/CustomInput.vue";
import LOSMixin from "@/mixins/los.mixin";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
import _ from "lodash";

@Component({
  components: {
    LosRequiredField, customInput
  }
})
export default class ExpenseComponent extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop()
  public loanId;
  @Prop()
  public type;
  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public loanIsLocked;

  public borrowerData: any = null;
  public borrowerMapping: any = null;
  public loanInformation: object = null;
  public saveInProcess = false;
  public expensesRequiredList=[];
  public isFieldMissing=false;
  public primaryBorrowerIndex = null;
  public havePosValidation = false;
  private initialState: any = {};

  get isFHA() {
    return _.get(this.loanInformation, 'productType.ediValue') === '03';
  }

  public async fetchExpenseData() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "los/getExpenseData",
        {
          loanTxnId: this.loanId
        });
      this.borrowerData = response.data.borrowerInfo.borrowerInfo;
      this.borrowerMapping = response.data.borrowerData.relationshipMapping;
      this.primaryBorrowerIndex = response.data.borrowerData.primaryBorrowerIndex;
      this.loanInformation = response.data.borrowerData.loanInformation;

      /**  set otherLiabilitiesAndExpenses array length are same for all borrowers and coBorrowers
       */
      if (this.borrowerData[this.primaryBorrowerIndex].otherLiabilitiesAndExpenses.length > 1) {
        let length = this.borrowerData[this.primaryBorrowerIndex].otherLiabilitiesAndExpenses.length;
        this.borrowerMapping.forEach((el, i) => {
          let borrowerExpensesLength = this.borrowerData[el.borrowerIndex].otherLiabilitiesAndExpenses.length
          if (borrowerExpensesLength < length) {
            for (let i = borrowerExpensesLength; i <= length; i++) {
              this.borrowerData[el.borrowerIndex].otherLiabilitiesAndExpenses.push(new OtherLiabilitiesAndExpenses());
            }
          }

          if (el.coBorrowerIndex.length > 0) {
            el.coBorrowerIndex.forEach(e => {
              let coBorrowerExpensesLength = this.borrowerData[e].otherLiabilitiesAndExpenses.length
              if (coBorrowerExpensesLength < length) {
                for (let i = coBorrowerExpensesLength; i <= length; i++) {
                  this.borrowerData[e].otherLiabilitiesAndExpenses.push(new OtherLiabilitiesAndExpenses());
                }
              }
            });
          }
        });
      }
      this.initialState = this.cloneObject(this.getUpdateRequestBody());
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public getUpdateRequestBody() {
    const expensesPrimaryBorrower = this.borrowerData[this.primaryBorrowerIndex].otherLiabilitiesAndExpenses;
    this.borrowerData.forEach(borrowerInfo => {
      expensesPrimaryBorrower.forEach((expense, index) => {
        borrowerInfo.otherLiabilitiesAndExpenses[index].otherDescription = expense.otherDescription;
        borrowerInfo.otherLiabilitiesAndExpenses[index].name = expense.name;
      });
    });
    return {
        loanTxnId: this.loanId,
        borrowerInfo: this.borrowerData
    }
  }

  public async saveInformation(tosty,fromParent, checkUnsaved = false) {
    try {
      let data = this.getTotalSumOfProposedAndCombinedMonthly();
      if(this.type == 'POS' && data.combinedMonthlyTotal == 0 && data.proposedExpenseTotal == 0){
        this.havePosValidation = true;
        return {status:false}
      }
      
      for (let i = 0; i < this.borrowerData[this.primaryBorrowerIndex].otherLiabilitiesAndExpenses.length; i++) {
        this.$refs.otherSource[i]["validateAll"]();
      }

      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };

      this.$store.state.wemloLoader = true;
      this.saveInProcess = true;

      let response = await Axios.post(BASE_API_URL + "los/saveExpenseData", requestBody);

      if (tosty) {
        this.$snotify.clear();
        this.$snotify[response.data.statusCode == 200 ? 'success' : 'error'](response.data.message);
      }
      
      if (response.data.statusCode === 200) {
        this.initialState = this.cloneObject(requestBody);
        this.$emit("saveEvent");
      }

      if (!fromParent && this.$parent["checkLosFormCompleted"]) {
        this.$parent["checkLosFormCompleted"](true);
      }

      return {
        status: true,
        requiredFields: response.data.expensesError,
      };
    } catch (error) {
      console.log(error);
    } finally {
      this.saveInProcess = false;
      this.$store.state.wemloLoader = false;
    }

    return {
      status: false,
      requiredFields: [],
    };
  }
  /**
   *   Add other expenses in all borrowers and coBorrowers
   */
  public AddOtherExpenses() {
    this.borrowerMapping.forEach(el => {
      this.borrowerData[el.borrowerIndex].otherLiabilitiesAndExpenses.push(
        new OtherLiabilitiesAndExpenses()
      );
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach(e => {
          this.borrowerData[e].otherLiabilitiesAndExpenses.push(
            new OtherLiabilitiesAndExpenses()
          );
        });
      }
    });
  }
  /**
   *    Remove other expenses in all borrowers and coBorrowers
   */
  public removeOtherExpenses(index) {
    this.borrowerMapping.forEach(el => {
      this.borrowerData[el.borrowerIndex].otherLiabilitiesAndExpenses.splice(
        index,
        1
      );
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach(e => {
          this.borrowerData[e].otherLiabilitiesAndExpenses.splice(index, 1);
        });
      }
    });
    if(this.borrowerData[this.primaryBorrowerIndex].otherLiabilitiesAndExpenses.length == 0)
      this.AddOtherExpenses();
  }

  public getTotal(name,index) {
    let total:any = 0;
    if (name == "combinedMonthlyExpense") {
      total = this.borrowerData[index].personalInfo.address.currentAddress
        .housing.rentPerMonth;
    }
    Object.values(this.borrowerData[index][name]).forEach((element: any) => {
      total = total + element.monthlyExpense;
    });
    total = total.toFixed(2);
    if(name == 'proposedCombinedMonthlyExpense'){
      this.$emit('callMountFunction', {proposedExpense: total});
    }
    return total;
  }

  public setOtherExpensesType(index, name) {
    this.borrowerMapping.forEach(el => {
      this.borrowerData[el.borrowerIndex].otherLiabilitiesAndExpenses[
        index
      ].name = name;
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach(e => {
          this.borrowerData[e].otherLiabilitiesAndExpenses[index].name = name;
        });
      }
    });
  }

  public setOtherExpenseTotal(borrowerIndex){
    this.borrowerData[borrowerIndex].combinedMonthlyExpense.other.monthlyExpense = this.getSum(this.borrowerData[borrowerIndex].otherLiabilitiesAndExpenses, 'monthlyPayment');
  }

  public setOtherProposedMonthlyPayment(index){
    const expenses = _.flatMap(this.borrowerData.map(borrowerInfo => borrowerInfo.otherLiabilitiesAndExpenses));
    const values = expenses.map(expense => _.get(expense, 'monthlyPayment', 0));
    this.borrowerData[this.primaryBorrowerIndex].otherLiabilitiesAndExpenses[index].proposedMonthlyPayment = values.reduce((total, value) => { return total + value });
  }
  /**
   *     Check coBorrower have or not
   */
  public haveCoBorrower(index) {
    if (this.borrowerMapping[index].coBorrowerIndex.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public checkValidationRequired(index){
    let validation = false;
    this.borrowerMapping.forEach(el=>{
      if(this.borrowerData[el.borrowerIndex].otherLiabilitiesAndExpenses[index].monthlyPayment > 0)
        validation = true;
      el.coBorrowerIndex.forEach(cb=>{
        if(this.borrowerData[cb].otherLiabilitiesAndExpenses[index].monthlyPayment > 0)
          validation = true;
      });
    });
    return validation;
  }

  public OtherExpensesTypeList(index){
  let otherExpense = JSON.parse(JSON.stringify(LIABILITIES_EXPENSES));
  // this.borrowerData[this.primaryBorrowerIndex].otherLiabilitiesAndExpenses.forEach((el,i)=>{
  //   if(index != i && el.name && el.name.ediValue)
  //     otherExpense = otherExpense.filter(a=> a.ediValue != el.name.ediValue)
  // })
  return otherExpense;
  }

  public getTotalSumOfProposedAndCombinedMonthly(){
    let combinedMonthlyTotal = 0;
    let proposedExpenseTotal = 0;
      this.borrowerData.forEach((element,index) => {
         let total:any = this.getTotal('combinedMonthlyExpense',index);
         combinedMonthlyTotal = combinedMonthlyTotal + parseFloat(total)
      });
     Object.values(this.borrowerData[this.primaryBorrowerIndex].proposedCombinedMonthlyExpense).forEach((e:any)=>{
        proposedExpenseTotal = proposedExpenseTotal + parseFloat(e.monthlyExpense);
     });
     return {combinedMonthlyTotal:combinedMonthlyTotal,proposedExpenseTotal:proposedExpenseTotal}
  }

 async mounted() {
   await this.fetchExpenseData();
    // this.$parent["checkLosFormCompleted"](true);
  }
}
