

import { Component, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import DisplayFiles from "@/views/DisplayDocument.vue";
import Multiselect from "vue-multiselect";
import { CreditReport, Borrower } from "@/models/order-service.model";
import { CREDIT_AGENCIES } from "@/constants";
import LosRequiredField from "@/views/LosRequiredField.vue";
import PDFViewer from "@/components/pdf/PDFViewer.vue";
import RunausRequest from "@/components/urla/los/order-service/runaus-request/RunausRequest.vue";
import _ from "lodash"; 
import saveAs from "save-as";
import print from 'print-js'
import RunAusMixin from "@/mixins/RunAusMixin.mixin";
import {mixins} from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";

@Component({
  components: {
    Multiselect,
    DisplayFiles,
    LosRequiredField,
    PDFViewer,
    RunausRequest,
  }
})
export default class OrderServiceComponent extends mixins(LOSMixin, RunAusMixin)  {
  /**contant to enable the display document child */
  public creditVendors = [];
  public borrowers: Array<Borrower> = new Array<Borrower>();
  public borrowersSelected: Array<Borrower> = null;
  public vendorInfo = new CreditReport();
  public showError = false;
  public isAuthenticationFail = false;
  public creditVendorCompanies = CREDIT_AGENCIES;
  public companies = null;
  public vendorCredential = [];
  public toggleFunc = false;
  public toggleArea = false;
  public togglePreviousRequest = false;
  public creditReportStatus = null;
  public isExpand = false;
  public creditReport = null;
  public hasCredential = false;
  public requiredFields: any = {};
  public validateFields = false;
  public hasCoborrower = false;
  public borrowerIndex = null;
  public coBorrowerIndex = null;
  public toggleLoader = false;
  public hasError = false;
  public ausCasefileId = null;
  public creditAgencies = null;
  public isExpandAus = false;
  public vendorsCredentials: any = [];
  public superAdminBrokerId = null;
  creditReportPdf = null;
  relationshipMapping = null;
  recommendation = {
    text: null,
    color: null
  };

  @Prop()
  public loanIsLocked;

  lenders = [];
  selectedLenderInstitutionIdentifier = null;

  get orderedCreditVendors() {
    return _.sortBy(_.get(this, 'creditVendors', []), 'name');
  }

  get ausCasefileIdentification() {
    return this.ausCasefileId;
  }

  get ausErrorMessages() {
    return _.get(this.ausFindings, 'responseDetails.findingErrors', [])
      .map(error => {return {'message': _.get(error, 'message', false), 'type': error.type}})
      .filter(error => error.message);
  }

  public async generateCreditreport() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.showError = true;
        return 
      }
      let isNotValid = await this.validateRequiredField(
        this.vendorInfo.selectedBorrowerId,
        this.vendorInfo.isjointCreditReportRequest
      );
      if (isNotValid) {
        this.$modal.hide("creditReportRequest");
        this.$modal.show("RequiredFieldModal");
        return;
      }

      this.hasError = false;
      this.showError = false;
      this.isAuthenticationFail = false;
      this.$store.state.wemloLoader = true;

      let response = await Axios.post(
        BASE_API_URL + "creditReport/generateCRXML",
        {
          ...this.vendorInfo,
          loanTxnId: this.$route.query.id,
          userType: this.$userType
        });

      if (response.data.statusCode == 401) {
        this.isAuthenticationFail = true;
        this.hasError = true;
        return;
      } else if (response.data.statusCode == 200) {
        if (response.data.statusDescription == "READY") {
          this.$snotify.success("Report generated successfully");
        } else if (response.data.statusDescription == "NOT READY") {
          this.hasError = true;
          this.$snotify.info("Report are not ready, please try after some time");
        } else {
          this.hasError = true;
          this.$snotify.error(response.data.statusDescription);
        }
      } else if (response.data.statusCode == 500) {
        this.hasError = true;
        this.$snotify.error("Something Went Wrong");
      } else if (response.data.statusCode == 201) {
        this.$snotify.success(response.data.statusDescription);
      }

      this.$modal.hide("creditReportRequest");
      this.getCreditVendors();
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public async fetchVendorCredential() {
    try {
      this.hasCredential = true;
      let response = await Axios.get(
        BASE_API_URL + "creditReport/fetchVendorCredential",
        {
          params: { selectedvendorId: this.vendorInfo.selectedvendorId }
        }
      );
      this.vendorInfo.userName = response.data.userName;
      this.vendorInfo.password = response.data.password;
      this.hasCredential = false;
    } catch (error) {
      console.log(error);
      this.hasCredential = false;
    }
  }

  public async updateVendorCredential() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "creditReport/updateVendorCredential",
        {
          selectedvendorId: this.vendorInfo.selectedvendorId,
          userName: this.vendorInfo.userName,
          password: this.vendorInfo.password
        });
      this.$snotify.success("Credential updated successfully");
      this.isAuthenticationFail = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchCoBorrower() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "creditReport/fetchCoBorrower",
        {
          params: {
            borrowerUserId: this.vendorInfo.selectedBorrowerId,
            loanTxnId: this.$route.query.id
          }
        }
      );
      this.vendorInfo.coBorrower = response.data.coBorrower;
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchCreditReportHistory() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.get(
        BASE_API_URL + "creditReport/fetchCreditReportHistory",
        {
          params: { loanTxnId: this.$route.query.id }
        }
      );
      this.creditReport = response.data.creditReportRequest;

      if (this.creditReport.length > 0) {
        this.creditReport.reverse();
      }

      this.toggleFunc = this.creditReport.length>0 ? true : false;
      this.toggleArea = this.creditReport.length>0 ? true : false;
      this.togglePreviousRequest = this.creditReport.length>0 ? true : false;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public async showCreditPdf() {
    try {
      if (this.creditReportPdf) {
        this.showPdfFile(this.creditReportPdf);
      } else {
        this.$snotify.error("No Document to view");
      }
    } catch (error) {}
  }

  public showPdfFile(file) {
    this.$refs.dfd["setInitialValuesForAttachmentsAndComments"](
      null,
      [file],
      null
    );
  }

  public showCreditReportRequestPopup() {
    this.vendorInfo = new CreditReport();
    this.$modal.show("creditReportRequest");
  }

  public async validateRequiredField(selectedBorrowerId, hasCoBorrower) {
    try {
      let hasValid = false;
      let response = await Axios.post(
        BASE_API_URL + "creditReport/validateRequiredField",
        {
          loanTxnId: this.$route.query.id,
          selectedBorrowerId: selectedBorrowerId,
          hasCoBorrower: hasCoBorrower
        });
      let borrower = response.data.borrower;
      let coBorrower = response.data.coBorrower;
      this.borrowerIndex = response.data.borrowerIndex;
      this.coBorrowerIndex = response.data.coBorrowerIndex;

      this.hasCoborrower = coBorrower ? true : false;
      if (coBorrower) {
        this.requiredFields.hasCoBorSSN = borrower.personalInfo.ssn
          ? true
          : false;
        this.requiredFields.hasCoBorStreet = borrower.personalInfo.address
          .currentAddress.address.street
          ? true
          : false;
        this.requiredFields.hasCoBorCity = borrower.personalInfo.address
          .currentAddress.address.city
          ? true
          : false;
        this.requiredFields.hasCoBorState = borrower.personalInfo.address
          .currentAddress.address.state
          ? true
          : false;
        this.requiredFields.hasCoBorCounty = borrower.personalInfo.address
          .currentAddress.address.county
          ? true
          : false;
        this.requiredFields.hasCoBorZip = borrower.personalInfo.address
          .currentAddress.address.zip
          ? true
          : false;
      }

      this.requiredFields.hasSSN = borrower.personalInfo.ssn ? true : false;
      this.requiredFields.hasStreet = borrower.personalInfo.address
        .currentAddress.address.street
        ? true
        : false;
      this.requiredFields.hasCity = borrower.personalInfo.address.currentAddress
        .address.city
        ? true
        : false;
      this.requiredFields.hasState = borrower.personalInfo.address
        .currentAddress.address.state
        ? true
        : false;
      this.requiredFields.hasCounty = borrower.personalInfo.address
        .currentAddress.address.county
        ? true
        : false;
      this.requiredFields.hasZip = borrower.personalInfo.address.currentAddress
        .address.zip
        ? true
        : false;

      if (
        !this.requiredFields.hasSSN ||
        !this.requiredFields.hasStreet ||
        !this.requiredFields.hasCity ||
        !this.requiredFields.hasCounty ||
        !this.requiredFields.hasZip ||
        !this.requiredFields.hasState
      ) {
        hasValid = true;
      }

      if (
        hasCoBorrower &&
        (!this.requiredFields.hasCoBorSSN ||
          !this.requiredFields.hasCoBorStreet ||
          !this.requiredFields.hasCoBorCity ||
          !this.requiredFields.hasCoBorCounty ||
          !this.requiredFields.hasCoBorState ||
          !this.requiredFields.hasCoBorZip)
      ) {
        hasValid = true;
      }

      this.requiredFields = JSON.parse(JSON.stringify(this.requiredFields));

      return hasValid;
    } catch (error) {
      console.log(error);
    }
  }

  checkLosFormCompleted(callback) {
    return this.$parent["checkLosFormCompleted"](true, callback, 'runAusRequest');
  }

  public redirectToTab(type) {
    this.$modal.hide("RequiredFieldModal");
    type == "borrower"
      ? this.$emit(
          "callMountFunction",
          null,
          "PersonalInfo",
          true,
          this.borrowerIndex
        )
      : this.$emit(
          "callMountFunction",
          null,
          "PersonalInfo",
          true,
          this.coBorrowerIndex
        );
  }

  public selectBorrower(index) {
    this.borrowers[index].isSelected = !this.borrowers[index].isSelected
  }

  runAusRequestErrorOrderService() {
    this.runAusRequestError();
    this.isExpandAus = true;
  }

  runAusRequestCompleteOrderService() {
    this.runAusRequestComplete();
    this.isExpandAus = true;
  }

  async saveAusFile(action) {
    if (!this.ausFilepath) return;

    this.$store.state.wemloLoader = true;

    try {
      const { data: fileObject } = await Axios.post(
        BASE_API_URL + 'common/downloadPDF',
        { data: this.ausFilepath },
        { responseType: "blob" }
      );
      const fileName =  _.last(_.split(this.ausFilepath, '/'));

      if (action === 'download') {
        saveAs(fileObject, fileName);
      } else if (action === 'print') {
        const url = URL.createObjectURL(fileObject);
        print(url);
      }
    } catch (error) {}

    this.$store.state.wemloLoader = false;
  }

  public mounted() {  
    this.getCreditVendors()    
  }
}
