import ApexCharts from 'apexcharts';
export class LOSRadialBar {
    public DTI: number;
    public housingRatio: number;
    public option = {};
    public element: string;
    public chartInstance: ApexCharts;

    public buildOptions(DTI: number, housingRatio: number) {
        return {
            chart: {
                width: 130,
                height: 190,
                type: 'radialBar',
                fontSize: 10,
            },
            plotOptions: {
                radialBar: {
                    track: {
                        background: '#186181',
                    },
                    hollow: {
                        margin: 10,
                        size: "70%",
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '10px',
                            fontFamily: 'Conv_Montserrat-Regular',
                            color: 'white',
                            offsetY:16
                        },
                        value: {
                            show: true,
                            fontSize: '20px',
                            fontWeight:700,
                            fontFamily: 'Conv_Montserrat-Regular',
                            color: 'white',
                            offsetY: -20,
                            formatter: function (val) {
                                return val + '%'
                            }
                        },
                        total: {
                            show: true,
                            label: 'DTI Ratio',
                            color: 'white',
                            formatter: function () {
                                return DTI + '%';
                            }
                        }
                    },
                }
            },
            series: [housingRatio],
            labels: ['Housing Ratio'],
            colors: ["#00b6ff"],
            /**
             * Handle chart's responsive
             */
            responsive: [
                {
                    breakpoint: 1190,
                    options: {
                        chart: {
                            width: 150,
                            height: 200,
                            marginRight: 10,
                        },
                        legend: {
                            position: 'bottom'
                        },
                    }
                },
                {
                    breakpoint: 991,
                    options: {
                        chart: {
                            width: 150,
                            height: 200,
                            marginRight: 15
                        },
                    }
                }
            ],
            tooltip: {
                enabled: true,
                followCursor: true,
                custom: function() {
                    return (
                      '<div class="arrow_box">' +
                      "<span>" +
                      'Housing Ratio' +
                      ": " + 
                      housingRatio + '%' +
                      "</span>" +
                      "</div>"
                    );
                },
            }
        }
    }

    constructor(DTI: number, housingRatio: number, elem: string) {
        this.element = elem;
        this.option = this.buildOptions(DTI, housingRatio);
        this.renderDefault()
    }

    renderDefault() {
        this.chartInstance = new ApexCharts(
            document.querySelector(`#${this.element}`),
            this.option
        );
        this.chartInstance.render();
    }

    updateRadialBar(dti: number, housingRatio: number) {
        this.chartInstance.updateOptions(this.buildOptions(dti, housingRatio))
    }
}