


import _ from "lodash";
import { Component, Vue, Watch } from "vue-property-decorator";
import General from "./general/General.vue";
import Asset from "./assets/Asset.vue";
import Declaration from "./declarations/Declaration.vue";
import Expense from "./expenses/Expense.vue";
import Income from "./income/Income.vue";
import Liabilities from "./liabilities/Liabilities.vue";
import LoanAndProperty from "./loan-and-property/LoanAndProperty.vue";
import PersonalInfo from "./personal-info/PersonalInfo.vue";
import BasicInfo from "./personal-info/BasicInfo.vue";
import RealEstateOwned from "./real-estate-owned/RealEstateOwned.vue";
import Transaction from "./transactions/Transaction.vue";
import Demographic from "./demographic/Demographic.vue";
import OrderService from "./order-service/OrderService.vue";
import FeeWorksheet from "./fee-worksheet/FeeWorksheet.vue";
import ManageDisclosures from "./manage-disclosures/ManageDisclosures.vue";
import { FNM_TAB_lINKS } from "@/constants";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import LOSHeader from "./los-header/LOSHeader.vue";
import vueCustomScrollbar from "vue-custom-scrollbar";
import LosRequiredField from "@/views/LosRequiredField.vue";
import saveAs from "save-as";
import checkSsn from "@/views/checkSsnForBorrower.vue";
import { mixins } from "vue-class-component";
import LOSMinxin from "@/mixins/los.mixin"
import CommonMixin from "@/mixins/common.mixin";
import BootstrapVue from "bootstrap-vue";
import LoanStateWarning from "@/views/LoanStateWarning.vue";
import UnsavedFields from "./unsaved-fields/UnsavedFields.vue";
import LoanLockBanner from "../../../views/LoanLockBanner.vue";
import { io } from 'socket.io-client';

let socket;

Vue.use(BootstrapVue);

Component.registerHooks(["beforeRouteLeave"]);

@Component({
  components: {
    General,
    Asset,
    Declaration,
    Expense,
    Income,
    Liabilities,
    LoanAndProperty,
    PersonalInfo,
    RealEstateOwned,
    Transaction,
    Demographic,
    LOSHeader,
    vueCustomScrollbar,
    OrderService,
    LosRequiredField,
    saveAs,
    checkSsn,
    FeeWorksheet,
    ManageDisclosures,
    LoanStateWarning,
    UnsavedFields,
    LoanLockBanner,
  }
})
export default class LosComponent extends mixins(CommonMixin, LOSMinxin) {
  public tabLinks = FNM_TAB_lINKS;
  public currentTab: any = null;
  public loanTxnId: any = null;
  public losRequiredList: any = null;
  public isLosFieldMissing = false;
  public nextTab: any = null;
  public nextRoute: any = null;
  public index: any = null;
  public tabRequiredFields: any = null;
  public tabValue = null;
  public isSubmitToWemlo = false;
  public borrowerCoBorrowerIndex = null;
  public ediFile: any = null;
  public isMismo: boolean = null;
  public exportCallback: boolean = false;
  public runAusCallBack: boolean = false;
  public unsavedFields: Array<string> = [];
  public showHeader: boolean = false;
  public generalData: any = null;
  public loanLockBannerIsOpened: boolean = false;
  public loanIsLocked: boolean = false;
  public loanUpdatedBy: any = null;

  public get currentTabComponent() {
    switch (this.currentTab) {
      case 'General':
        return {
          attrs: {
            is: 'General',
            ref: 'General',
            isSubmitToWemlo: this.isSubmitToWemlo,
            generalData: this.generalData,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.updateHeaderInfo,
            saveEvent: this.saveEvent
          },
        };
      case 'PersonalInfo':
        return {
          attrs: {
            is: 'PersonalInfo',
            ref: 'PersonalInfo',
            isSubmitToWemlo: this.isSubmitToWemlo,
            borrowerCoBorrowerIndex: this.borrowerCoBorrowerIndex,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            saveEvent: this.saveEvent
          },
          class: 'col-lg-12 p-0',
        };
      case 'LoanAndProperty':
        return {
          attrs: {
            is: 'LoanAndProperty',
            ref: 'LoanAndProperty',
            isSubmitToWemlo: this.isSubmitToWemlo,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.updateHeaderInfo,
            saveEvent: this.saveEvent
          },
        };
      case 'Income':
        return {
          attrs: {
            is: 'Income',
            ref: 'Income',
            isSubmitToWemlo: this.isSubmitToWemlo,
            loanId: this.loanTxnId,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.updateHeaderInfo,
            saveEvent: this.saveEvent
          },
        };
      case 'Expense':
        return {
          attrs: {
            is: 'Expense',
            ref: 'Expense',
            loanId: this.loanTxnId,
            isSubmitToWemlo: this.isSubmitToWemlo,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.updateHeaderInfo,
            saveEvent: this.saveEvent
          },
        };
      case 'Asset':
        return {
          attrs: {
            is: 'Asset',
            ref: 'Asset',
            isSubmitToWemlo: this.isSubmitToWemlo,
            loanId: this.loanTxnId,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            saveEvent: this.saveEvent
          },
        };
      case 'Liabilities':
        return {
          attrs: {
            is: 'Liabilities',
            ref: 'Liabilities',
            isSubmitToWemlo: this.isSubmitToWemlo,
            loanId: this.loanTxnId,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.updateHeaderInfo,
            saveEvent: this.saveEvent
          },
        };
      case 'RealEstateOwned':
        return {
          attrs: {
            is: 'RealEstateOwned',
            ref: 'RealEstateOwned',
            isMismo: this.isMismo,
            loanId: this.loanTxnId,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.updateHeaderInfo,
            tabChange: this.tabChangeFromChild,
            saveEvent: this.saveEvent
          },
        };
      case 'Transaction':
        return {
          attrs: {
            is: 'Transaction',
            ref: 'Transaction',
            isSubmitToWemlo: this.isSubmitToWemlo,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.updateHeaderInfo,
            saveEvent: this.saveEvent
          },
        };
      case 'Declaration':
        return {
          attrs: {
            is: 'Declaration',
            ref: 'Declaration',
            isSubmitToWemlo: this.isSubmitToWemlo,
            borrowerCoBorrowerIndex: this.borrowerCoBorrowerIndex,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            saveEvent: this.saveEvent
          },
        };
      case 'Demographic':
        return {
          attrs: {
            is: 'Demographic',
            ref: 'Demographic',
            isMismo: this.isMismo,
            isSubmitToWemlo: this.isSubmitToWemlo,
            borrowerCoBorrowerIndex: this.borrowerCoBorrowerIndex,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            saveEvent: this.saveEvent
          },
        };
      case 'OrderService':
        return {
          attrs: {
            is: 'OrderService',
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.closeRequiredFieldModel,
            
          },
        };
      case 'FeeWorksheet':
        return {
          attrs: {
            is: 'FeeWorksheet',
            ref: 'FeeWorksheet',
            checkLosFormCompleted: this.checkLosFormCompleted,
            isSubmitToWemlo: this.isSubmitToWemlo,
            borrowerCoBorrowerIndex: this.borrowerCoBorrowerIndex,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            saveEvent: this.saveEvent
          },
          class: 'col-lg-12 p-0',
        };
      case 'Forms':
        return {
          attrs: {
            is: 'ManageDisclosures',
            ref: 'Forms',
            isSubmitToWemlo: this.isSubmitToWemlo,
            loanIsLocked: this.loanIsLocked
          },
          listeners: {
            callMountFunction: this.updateHeaderInfo,
            saveEvent: this.saveEvent
          },
        };
    }
  }

  /***************************************************************************************
   *              Change tab on click or validate existing tab data.                     *
   ***************************************************************************************/
  public async tabChange(value, index) {
    if (value != this.currentTab) {
        const { unsavedFields } = await this.saveEveryTabInformation(this.currentTab, true);

        this.nextTab = value;
        this.index = index;

        if (unsavedFields && !_.isEmpty(unsavedFields) && !this.loanIsLocked){
            this.unsavedFields = unsavedFields;
            this.$modal.show("tabSwitchLostDataConfirmation");
        }else {
            this.goToTab(value, index, true);
        }
      }
  }

  public async tabChangeFromChild(value) {
    const index = this.tabLinks.findIndex(l => l.value === value)
    this.tabChange(value, index)
  }

  public cancelNavigation() {
    this.nextTab = null;
    this.nextRoute = null;
    this.$modal.hide('tabSwitchLostDataConfirmation');
  }

  public async saveDataAndContinueNavigation() {
    const { status } = await this.saveEveryTabInformation(this.currentTab, false);

    if (status) {
      this.continueNavigation();
    } else {
      Vue.prototype.$modal.show('reAuthenticationModal');
    }
  }

  public continueNavigation() {
    if (this.nextRoute) {
      this.$router.push(this.nextRoute);
    } else if (this.nextTab) {
      this.goToTab(this.nextTab, this.index, true);
    }
  }

  /*********************************************************************************
   *    Change Active tab
   *********************************************************************************/
  public async goToTab(value, index, leave) {
    try {
      if (leave) {
        this.unsavedFields = [];
        this.tabLinks.forEach((tab, tabIndex) => {
          if (tabIndex == index) {
            tab.class = "active";
            this.currentTab = value;
            let tabHash = this.$route.hash;
            let tabName = tabHash ? tabHash.slice(1) : "General";
            if (tabName != tab.value)
              this.$router.push({
                query: { id: this.$route.query.id },
                hash: "#" + tab.value
              });
          } else {
            tab.class = "";
          }
        });
        this.nextTab = null;
        this.index = null;
        this.updateHeaderInfo(null);
      }
      this.$modal.hide("tabSwitchConfirmation");
      this.$modal.hide("tabSwitchLostDataConfirmation");
    } catch (error) {
      console.log(error);
    }
  }

  public async submitToWemlo() {
    let data = await this.saveEveryTabInformation(this.currentTab, false);
    if (!data.status) {
      this.$modal.show("SubmitToWemloWarning");
      return;
    }

    await this.checkLosFormCompleted();
  }

  /*************************************************************
   *          Checking required field of 1003 form             *
   *************************************************************/
  public async checkLosFormCompleted(restrictMoveToLead = false, callback = null, type = null) {
    try {
      // Reset this value before validation to allow open the required list more times
      this.isLosFieldMissing = false;
      this.exportCallback = false;
      this.runAusCallBack = false;

      let response = await Axios.post(
        BASE_API_URL + "loan/checkLosFormCompleted",
        { loanTxnId: this.$route.query.id });
      if (response.data.status == "error") {
        this.losRequiredList = response.data.errorList;
        this.isLosFieldMissing = true;
        if (callback && typeof callback === 'function') {
          if(type && type == 'runAusRequest') { this.runAusCallBack = callback }
          else { this.exportCallback = callback; }
        }
        return;
      }

      if (!restrictMoveToLead){
        await this.checkMoveToLead();
      }
      this.getCallback(type, callback);
    } catch (error) {
      console.log(error);
    }
  }

  getCallback(type, callback) {
    if (callback && typeof callback === 'function') {
        if(type && type == 'runAusRequest') { this.runAusCallBack = callback }
        else { this.exportCallback = callback; }
        callback();
      }
  }

  public async checkMoveToLead(){
    try{
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/checkMoveToLead",
        { loanTxnId: this.$route.query.id });
      if(response.data.moveToLead)
        this.updateMoveToLead();
      else{
        let isActiveSubmitToWemlo = await this.checkSubmitToWemloButtonHaveActive();
        if(!isActiveSubmitToWemlo){
          this.$modal.show('SubmitToWemloWarningCountDownModal');
          this.$store.state.wemloLoader = false;
          return;
        }
        this.$router.push({
          path: this.$getCurrentUserTypePath("broker-loan-documents"),
          query: { id: this.$route.query.id, edit: 'true' }
        })
      }
        
    }catch(error){
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  // ******************************Code Shifting from Wemlo Header of Los header*************************//
  public async createLosPdf() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "los/create1003PDF",
        {
          loanTxnId: this.$route.query.id
        },
        {
          responseType: "blob"
        }
      );
      await this.getPrimaryBorrowerDetails(response.data);
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }
  /***************** Get Primary Borrower Details **************************/
  public async getPrimaryBorrowerDetails(data) {
    let response: any = null;
    try {
      response = await Axios.post(
        BASE_API_URL + "common/getPrimaryBorrowerName",
        {
          loanTxnId: this.$route.query.id
        });
    } catch (error) {
      console.log(error);
    }
    saveAs(
      data,
      (response.data ? response.data : "los") +
        "_" +
        this.$route.query.id +
        ".zip"
    );
  }

  public async getEdiFile() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/getEdiFile",
        { loanTxnId: this.$route.query.id });
      this.ediFile = response.data.ediFile;
    } catch (error) {
      console.log(error);
    }
  }

  // ******************************End of code Shifting from Wemlo Header of Los header*************************//

  public async updateMoveToLead() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/updateMoveToLead",
        {
          loanTxnId: this.loanTxnId,
          moveToLead: true,
          userType: this.$userType
        });
      this.$snotify.success("Data Saved Successfully");
      this.$router.push("/dashboard");
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  /**********************************************************************************************************************************************************************
   *            Save every tab information on tab switch
   **********************************************************************************************************************************************************************/
  public async saveEveryTabInformation(tabName, checkUnsaved) {
    try {
      if (tabName && this.$refs[tabName]) {
        /**
         * Update header only if it will save data
         */
        if (!checkUnsaved) {
          await this.updateHeaderInfo(null);
          //emit socket event - send msg all room users
          this.saveEvent();
        }

        return await this.$refs[tabName]["saveInformation"](false,true, checkUnsaved);
      }
      return { status: true, unsavedFields: [] };
    } catch (error) {
      console.log(error);
      console.log('message' + error.message);
      return { status: false, unsavedFields: [] };
    }
  }
  public async redirectToBorrowerTab(data) {
    try {
      await this.closeRequiredFieldModel(null, "PersonalInfo", true, data.index);
    } catch (error) {
      console.log(error);
    }
  }
  public async closeRequiredFieldModel(
    value,
    tabName = null,
    isSubmitToWemlo = false,
    borrowerCoBorrowerIndex = null
  ) {
    this.borrowerCoBorrowerIndex = borrowerCoBorrowerIndex; //Getting borrower and coBorrower indexes
    this.isSubmitToWemlo = isSubmitToWemlo;
    if (
      this.currentTab == tabName &&
      (tabName == "PersonalInfo" ||
        tabName == "Declaration" ||
        tabName == "Demographic")
    ) {
      await this.$refs[tabName]["getInformation"](borrowerCoBorrowerIndex);
    }
    if (value) {
      this.goToTab(this.nextTab, this.index, true);
    }
    if (tabName) {
      this.tabLinks.forEach((el, index) => {
        if (el.value == tabName) this.goToTab(el.value, index, true);
      });
    }
    this.isLosFieldMissing = false;
    this.nextTab = null;
    this.index = null;
    this.losRequiredList = null;
    this.tabRequiredFields = null;
  }

  async updateHeaderInfo(data) {
    if (data != null) {
      this.$refs.losHeader["updateHeaderDetails"](data, this.currentTab);
    } else {
      await this.$refs.losHeader["getHeaderData"]();
    }
  }

  removeTab(tabName) {
    if (!tabName) { return; }
    
    this.tabLinks = [ ...this.tabLinks.filter(link => link.name !== tabName) ];
  }

  async getLoanDocumentVersion(loanTxnId) {

      const { data: { version } } = await Axios.post(
          BASE_API_URL + "document/fetchBorrowerDocuments",
          { loanTxnId: this.loanTxnId, });

      return version;
  }

  async createUlad() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "mismo/createULADPDF",
        {
          loanTxnId: this.$route.query.id
        },
        {
          responseType: "blob"
        }
      );
      await this.getPrimaryBorrowerDetails(response.data);
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  async getEnumerationType() {
    const { data: { enumerationType } } = await Axios.post(
      BASE_API_URL + 'los/getEnumerationType',
      { loanTxnId: this.loanTxnId, }
    );

    return enumerationType;
  }

  public onLoanLockBannerClose() {
    this.loanLockBannerIsOpened = false;
  }

  public saveEvent() {
    let userInfo = this.$store.state.sessionObject.userInfo.firstName + ' ' + this.$store.state.sessionObject.userInfo.lastName;
    socket.emit('send-room-message', {roomID: this.loanTxnId, msg: userInfo});
  }

  /*********************************************************************************************************************************
   *                                          Fetch General Loan Information.                                                      *
   *                                        used manager :->  generalInfo.manager.js                                                   *
   *********************************************************************************************************************************/
  public async getGeneralData() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
          BASE_API_URL + "los/getGeneralData",
          {
            loanTxnId: this.$route.query.id,
          });
      if (response.status == 200 && response.data.loanInformation) {
        this.generalData = response.data;

      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public computeStreetName(address) {
    if (!address) return null;

    if (address.street) return address.street;

    if (address.city) {
      if (address.state) return `${address.city} - ${address.state.code}`;
      else return address.city;
    }

    return null;
  }

  public get computedLoanName() {
    const info = this.generalData?.loanInformation;
    const street = this.computeStreetName(info?.propertyAddress?.address)
    let loanName = '';

    if (!info) {
      return loanName;
    }

    loanName = `${info?.borrowerName} - ${info?.purpose?.name}`;
    if (street) {
      loanName += ` - ${street}`;
    }

    return loanName;
  }


  //****************Switching Tab from submitToWemlo modal & internal los Tab****************** */
  async mounted() {
    await this.getEdiFile();
    await this.getGeneralData();
    this.loanTxnId = this.$route.query.id;
    let tabHash = this.$route.hash;
    let tabName = tabHash ? tabHash.slice(1) : "General";
    let query = this.$route.query;

    this.isMismo = (await this.getEnumerationType()) === 'mismo';

    if (
      query.hasOwnProperty("requiredFields") ||
      query.hasOwnProperty("index")
    ) {
      this.isSubmitToWemlo = true;
      if (query.hasOwnProperty("index") && query.index) {
        this.borrowerCoBorrowerIndex = query.index;
      }
    }
    let tabIndex = 0;

    const usesSmartDocs = (await this.getLoanDocumentVersion(this.loanTxnId)) === '1.0';

    if (!usesSmartDocs || this.$userType === 'Broker') {
      this.removeTab('Forms');
    }

    this.tabLinks.forEach((el, index) => {
      if (tabName == el.value) tabIndex = index;
    });

    await this.goToTab(tabName, tabIndex, true);
    // await this.checkLosFormCompleted();

    this.updateWemloHeader();

    //global connection to server
    let serverDomain = BASE_API_URL.replace(/\/$/, '');
    io(serverDomain);
    //use namespace
    socket = io(`${serverDomain}/los-editting`);

    //event after connection to server
    socket.on("connect", () => {
      //emit custom event to server, create room - server will create a new room if it not exists
      socket.emit('create-room', this.loanTxnId); 
    });
 
    //handle incoming event with name 'incoming-room-message', this event is emitted by server for room sockets (except initiator socket)
    socket.on('incoming-room-message', (msg) => {
      this.loanIsLocked = true; 
      this.loanUpdatedBy = msg;
      this.loanLockBannerIsOpened = true;
      this.$snotify.info(`${this.loanUpdatedBy} updated this loan. Please reload before editing and saving.`);
    });
  }

  beforeDestroy() {
    if (socket) {
        socket.disconnect();
    }
  }

  public updateWemloHeader() {
    const wemloHeader = document.querySelector('#wemlo-header');

    if (wemloHeader) {
      wemloHeader.innerHTML = "";
      wemloHeader.appendChild(this.$refs.header as Node);

      /**
       * Make the buttons show on the wemlo header
       */
      this.showHeader = true;
    }
  }

  async beforeRouteLeave(to, from, next) {
    const isLogin = to.path === "/login";

    if (!this.nextRoute && !isLogin) {
      const { unsavedFields } = await this.saveEveryTabInformation(this.currentTab, true);

      if (!_.isEmpty(unsavedFields)) {
        this.nextRoute = to;
        this.unsavedFields = unsavedFields;
        this.$modal.show("tabSwitchLostDataConfirmation");
        return;
      }
    }

    next();
  }
}
