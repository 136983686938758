











































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { RESIDENCE_BASIS_TYPE } from "@/constants";
import Address from "@/views/LosAddress.vue";
import LOSMixin from "@/mixins/los.mixin";
@Component({ components: { Address } })
export default class ResidencyHistoryComponent extends mixins(LOSMixin) {
  @Prop()
  public residencyAddress;
  public residenceType = RESIDENCE_BASIS_TYPE;
  @Prop()
  public isSubmitToWemlo

  @Prop({default: false})
  public loanIsLocked: boolean;
}
