
















































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import stores from "@/store/store";
const store: any = stores;
import Axios from "axios";
import { HollowDotsSpinner } from "epic-spinners";

@Component({ components: { HollowDotsSpinner } })
export default class AddressComponent extends Vue {
  public stateCountyLoader = false;
  public states: any = [];
  public counties: any = [];
  public get stateList() {
    return store.state.sessionObject.states;
  }

  @Prop()
  public identifier;

  @Prop()
  public address;

  @Prop()
  public required;

  @Prop()
  public disabled;

  public async getCityAndState(mountCall) {
    try {
      let zip = this.address.zip;
      let zipValidate = await this.$validator.validate("addressZip", zip);
      if (this.address.zip == "" || !zipValidate) {
        return;
      }
      this.stateCountyLoader = true;
      let findState: any = null;
      let findCountry: any = null;
      let findCity: any = null;
      let response = await Axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=AIzaSyC4z_vM_4vNFs5_KH5wUdTjJrP8yLS9lr8&components=postal_code:" +
          zip,
          { headers: { public: true}}
      );
      if (response.status == 200 && response.data.status == "OK") {
        response.data.results[0]["address_components"].forEach(d => {
          if (d.types[0] == "administrative_area_level_1") {
            findState = d;
          }
          if (d.types[0] == "country") {
            findCountry = d.long_name;
          }
          if (d.types[0] == "locality") {
            findCity = d.long_name;
          } else {
            if (d.types[0] == "administrative_area_level_3")
              findCity = d.long_name;
          }
        });

        if (findCity) {
          this.address.city = null;
          this.address.city = findCity;
        }
        if (findCountry) {
          this.address.country = null;
          this.address.country = findCountry;
        } else {
          this.address.country = "United States";
        }
        if (findState) {
          this.address.state = null;
          this.states = [];
          this.address.state = {
            name: findState.long_name,
            code: findState.short_name
          };
          this.states = [
            {
              state: findState.long_name,
              code: findState.short_name
            }
          ];
        } else {
          this.setStates(mountCall);
        }
      } else {
        this.setStates(mountCall);
      }
      this.stateCountyLoader = false;
    } catch (error) {
      this.stateCountyLoader = false;
    }
  }
  public async setStates(mountCall) {
    if (!mountCall) {
      this.states = [];
      this.address.state = null;
      this.address.city = null;
      this.address.country = null;
    }
    this.stateList.forEach(data => {
      this.states.push(data);
    });
  }

  fetchCounties() {
    if (this.address.zip) {
      let address: any = this.address.state
        ? this.stateList.filter(a => a.code == this.address.state.code)
        : [];
      return address.length > 0 ? address[0].counties : [];
    }else{
       this.address.city = null;
      this.address.state = null;
      this.address.country = null;
    }
  }
  public async validateAll() {
    let valid = await this.$validator.validateAll();
    if (!valid) {
      return false;
    } else {
      return true;
    }
  }
   clearData(){
    if(!this.address.zip){
      this.address.state = null;
      this.address.city = null;
      this.address.country = null;
    }
  }

  async mounted() {
    // this.validateField();
    if (this.address.zip) {
      await this.getCityAndState(true);
    }
  }
  @Watch("address.zip")
  calc2(n) {
    this.getCityAndState(false);
  }
}
