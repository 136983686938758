


import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { BASE_API_URL } from "@/config";
import BasicInfo from "./BasicInfo.vue";
import Axios from "axios";

import ResidencyHistory from "./ResidencyHistory.vue";
import EmploymentHistory from "./EmploymentHistory.vue";
import CounselingEventComponent from "./CounselingEvent.vue";
import {
  CurrentEmployment,
  PreviousEmployment,
  AddressTypeDetail,
  CounselingEvent
} from "@/models/pos/borrower-info.model";
import addNewBorrower from "@/views/add-more-borrower/AddNewBorrower.vue";
import vueCustomScrollbar from "vue-custom-scrollbar";
import LOSMixin from "@/mixins/los.mixin";
import LosRequiredField from "@/views/LosRequiredField.vue";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import { MISMO_TAXPAYER_IDENTIFICATION_TYPE } from "@/constants";
import _ from "lodash";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
import UnsavedFields from "../unsaved-fields/UnsavedFields.vue";

@Component({
  components: {
    BasicInfo,
    ResidencyHistory,
    EmploymentHistory,
    CounselingEventComponent,
    vueCustomScrollbar,
    addNewBorrower,
    LosRequiredField,
    UnsavedFields,
  }
})
export default class PersonalInfoComponent extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public borrowerCoBorrowerIndex;
  @Prop()
  public loanIsLocked;

  public personalInfoData:any = null;
  public inProcess = false;
  public borrowerIndex: any = null;
  public viewBasicInfo = true;
  public viewResidencyHistory = false;  
  public viewEmploymentHistory = false;
  public viewCounselingEvent = false;
  public counselingEvents: any = {};
  public marriedToData: any = [];
  public unlinkBorrower = true;
  public borrowerData: any = null;
  public currentEmploymentIndex: any = null;
  public borrowerInfoData: any = null;
  public financialInfo = new FinancialInfo();
  public nextTabIndex: any = null;
  public isCurrentEmploymentLessThanThreeYear = false;
  public isCurrentAddDurationGreaterThanTwoYear = false;
  public unsavedFields: any = [];
  private initialState: any = {};
  loanType = null;

  public get isPrimaryBorrower() {
    return this.borrowerIndex === this.borrowerData?.primaryBorrowerIndex;
  }

  public async getInformation(index = null) {
    try {
      this.$store.commit('SET_WEMLO_LOADER', true);
      let response = await Axios.post(
        BASE_API_URL + "los/getPersonalInfoData",
        {
          loanTxnId: this.$route.query.id
        });
      if (response.status == 200) {
        this.borrowerData = response.data.borrowerData;
        this.loanType = _.get(this, 'borrowerData.loanInformation.productType.mismoValue', null);
        this.borrowerIndex = this.borrowerCoBorrowerIndex
          ? this.borrowerCoBorrowerIndex
          : this.borrowerData.primaryBorrowerIndex;
        this.personalInfoData = response.data.borrowerInfo.borrowerInfo;
        this.counselingEvents = this.personalInfoData.map(infoData => infoData.counselingEvents)
        this.personalInfoData.forEach(el => {
          el["duplicateEmail"] = false;
          if(!el.personalInfo.taxpayerIdentificationType) {
            el.personalInfo.taxpayerIdentificationType = MISMO_TAXPAYER_IDENTIFICATION_TYPE.find(type => type.name === 'SSN');
          }
          el.currentEmployment = el.currentEmployment.map(currentEmployment => ({
            isNotEmployed: false,
            ...currentEmployment,
          }));
        });
        await this.setJointWithCoBorrowerIndex();
        await this.isLessThanTwoYears(); //Checking if current employment less than three years for los field hight on tab switching .
        await this.currentAddressDurationGreaterThanTwoYear(
          index ? index : this.borrowerCoBorrowerIndex
        ); //Checking if currant address is less than two years for los field highlight on tab switching.
        this.initialState = this.cloneObject(this.getUpdateRequestBody());
      }
      if(this.isSubmitToWemlo){
        this.viewBasicInfo = true;
        this.viewResidencyHistory = true;
        this.viewEmploymentHistory = true;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.commit('SET_WEMLO_LOADER', false);
    }
  }

  public getUpdateRequestBody() {
    this.personalInfoData = this.personalInfoData.map((infoData, i) => {
      infoData.counselingEvents = this.counselingEvents[i]
      return infoData
    })
    return {
      loanTxnId: this.$route.query.id,
      borrowerData: this.personalInfoData[this.borrowerIndex],
      borrowerIndex: this.borrowerIndex,
    }
  }

  public checkEmailValidationError({ blankEmail, invalidEmail, emailExist }): void {
    if (blankEmail) {
      this.$snotify.error("Email cannot be blank.");
    }
    if (invalidEmail) {
      this.$snotify.error("Email is invalid.");
    }
    if (emailExist) {
      this.personalInfoData[this.borrowerIndex].duplicateEmail = true;
      this.$snotify.error("Email already exists.");
    }
  }
  
  /**
   * save personal Info Data
   */
  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {
      await this.$refs.basicInfo["validateAll"]();
      await this.removeEmptyFields();
      await this.removeIncomeItens();

      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };

      this.saveInProcess = true;
      this.$store.commit('SET_WEMLO_LOADER', true);

      let response = await Axios.post(
        BASE_API_URL + "los/updatePersonalInfo",
          requestBody
      );
      this.$snotify.clear();
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.commit('SET_WEMLO_LOADER', false);
      if (response.data.statusCode == 500) {
        this.checkEmailValidationError(response.data);
        this.personalInfoData = JSON.parse(JSON.stringify(this.personalInfoData));
        return { status: false };
      }
      if (response.data.statusCode == 200) {
        this.initialState = this.cloneObject(requestBody);
        this.personalInfoData = response.data.borrowerInfo;
        this.borrowerData = response.data.borrowerData;
        if(tosty)
          this.$snotify.success("Information saved successfully");
          if(!fromParent && this.$parent["checkLosFormCompleted"])  this.$parent["checkLosFormCompleted"](true);
        return response.data.personalInfoError.length > 0
          ? { status: true, requiredFields: response.data.personalInfoError }
          : { status: true, requiredFields: [] };
      }
    } catch (error) {
      this.$store.commit('SET_WEMLO_LOADER', false);
      this.saveInProcess = false;
      console.log(error);
      return { status: false };
    }
  }

  /**
   *  remove invalid income itens values
   */
  public async removeIncomeItens() {
    this.personalInfoData.forEach(personalInfo => {
      _.get(personalInfo, 'currentEmployment', []).forEach(employment => {
        if(employment.selfEmployedIndicator.isSelfEmployed) {
          employment.grossMonthlyIncome.base.monthlyIncome = null;
          employment.grossMonthlyIncome.overtime.monthlyIncome = null;
          employment.grossMonthlyIncome.bonus.monthlyIncome = null;
          employment.grossMonthlyIncome.commission.monthlyIncome = null;
          employment.grossMonthlyIncome.dividends.monthlyIncome = null;
          employment.grossMonthlyIncome.netRentalIncome.monthlyIncome = null;
          employment.grossMonthlyIncome.other.monthlyIncome = null;
          employment.grossMonthlyIncome.contractBasis.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryBasePay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryClothesAllowance.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryCombatPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryFlightPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryHazardPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryOverseasPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryPropPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryRationsAllowance.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome = null;
        } else if(employment.militaryIndicator) {
          employment.grossMonthlyIncome.base.monthlyIncome = null;
          employment.grossMonthlyIncome.overtime.monthlyIncome = null;
          employment.grossMonthlyIncome.bonus.monthlyIncome = null;
          employment.grossMonthlyIncome.commission.monthlyIncome = null;
          employment.grossMonthlyIncome.dividends.monthlyIncome = null;
          employment.grossMonthlyIncome.netRentalIncome.monthlyIncome = null;
          employment.grossMonthlyIncome.other.monthlyIncome = null;
          employment.grossMonthlyIncome.contractBasis.monthlyIncome = null;
          employment.selfEmployedIndicator.monthlyIncome = null;
        } else {
          employment.grossMonthlyIncome.militaryBasePay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryClothesAllowance.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryCombatPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryFlightPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryHazardPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryOverseasPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryPropPay.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryRationsAllowance.monthlyIncome = null;
          employment.grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome = null;
          employment.selfEmployedIndicator.monthlyIncome = null;
        }
      });
    });
  }

  /**
   *  remove empty section dependents, Employment history and residency history
   */
  public removeEmptyFields() {
    this.personalInfoData.forEach((element, i) => {
      // remove dependents empty array index
      // if (element.personalInfo.dependents.details.length > 1) {
      //   element.personalInfo.dependents.details = element.personalInfo.dependents.details.filter(a => a.age);
      // }
      // element.personalInfo.dependents.number = element.personalInfo.dependents.details.filter(a => a.age).length || null;

      // remove residential History index
      if (element.personalInfo.address.formerAddress.length > 1) {
        element.personalInfo.address.formerAddress = element.personalInfo.address.formerAddress.filter(
          a =>
            a.street ||
            a.address.zip ||
            a.address.state ||
            a.housing.type ||
            a.duration.months ||
            a.duration.years
        );
      }
      if (element.personalInfo.address.formerAddress.length == 0) {
        this.addResidencyHistory(i);
      }

      // remove Current Employment History index
      if (element.currentEmployment.length > 1) {
        element.currentEmployment = element.currentEmployment.filter(
          a =>
            a.employerName ||
            a.industry ||
            a.address.zip ||
            a.address.country ||
            a.address.state ||
            a.phone ||
            a.startDate.month ||
            a.startDate.year ||
            a.currentEmployment ||
            a.position ||
            a.workingPeriod.years
        );
      }
      if (element.currentEmployment.length == 0) {
        this.addEmploymentHistory("currentEmployment", i);
      }
      
      // remove PreviousEmployment History index
      if (element.previousEmployment.length > 1) {
        element.previousEmployment = element.previousEmployment.filter(
          a =>
            a.employerName ||
            a.industry ||
            a.address.zip ||
            a.address.country ||
            a.address.state ||
            a.phone ||
            a.startDate.month ||
            a.startDate.year ||
            a.currentEmployment ||
            a.position ||
            a.endDate.month ||
            a.endDate.year
        );
      }
      if (element.previousEmployment.length == 0) {
        this.addEmploymentHistory("previousEmployment", i);
      }
    });
  }
  /**
   * select borrower and coBorrower
   */
  public async changeBorrower(index, type = null) {
    if (index != this.borrowerIndex) {
      const { unsavedFields } = await this.saveInformation(false, false, true);

      this.unsavedFields = unsavedFields;

      if (_.isEmpty(unsavedFields)) {
        this.changeBorrowerTab(index);
      } else {
        this.nextTabIndex = index;
        this.$modal.show("personalInfoTabSwitchConfirmation");
      }
    }
    /****Function call for check current employment is less than three year and currentaddress is less than two year****/
    await this.isLessThanTwoYears();
    await this.currentAddressDurationGreaterThanTwoYear(index);
  }

  public async saveDataAndChangeTab(nextTabIndex: number) {
    const { status } = await this.saveInformation(true, false, false);

    if (status) {
      this.changeBorrowerTab(nextTabIndex);
    } else {
      Vue.prototype.$modal.show('reAuthenticationModal');
    }
  }

  public async changeBorrowerTab(index) {
    /**
     * Restore changes made and unsaved
     */
    this.$store.commit('SET_WEMLO_LOADER', true);
    this.personalInfoData[this.borrowerIndex] = this.initialState.borrowerData;

    this.borrowerIndex = index;
    this.viewBasicInfo = true;
    this.viewResidencyHistory = false;
    this.viewEmploymentHistory = false;
    this.nextTabIndex = null;
    this.$modal.hide("personalInfoTabSwitchConfirmation");

    /**
     * Reset initial state after change borrower
     */
    this.initialState = this.cloneObject(this.getUpdateRequestBody());
    this.$nextTick(() => {
      this.$store.commit('SET_WEMLO_LOADER', false);
    });
  }

  public getMarriedToNme(index) {
    let relativeBorrowerIndex: any = null;
    this.borrowerData.relationshipMapping.forEach(el => {
      if (el.borrowerIndex == index)
        relativeBorrowerIndex = el.coBorrowerIndex[0];
      el.coBorrowerIndex.forEach(cb => {
        if (cb == index) relativeBorrowerIndex = el.borrowerIndex;
      });
    });
    if (relativeBorrowerIndex != null) {
      let name =
        this.personalInfoData[relativeBorrowerIndex].personalInfo.name
          .firstName +
        " " +
        this.personalInfoData[relativeBorrowerIndex].personalInfo.name.lastName;
      return { name: name, index: relativeBorrowerIndex };
    } else return null;
  }

  /**
   * add Employment History
   */
  public addEmploymentHistory(type, index) {
    if (type == "currentEmployment") {
      let currentEmployment = new CurrentEmployment();
      this.personalInfoData[index][type].push(currentEmployment);
    } else {
      let previousEmployment = new PreviousEmployment();
      this.personalInfoData[index][type].push(previousEmployment);
    }
    this.$modal.hide("employmentHistory");
  }

  public removeEmploymentHistory(
    employmentType,
    borrowerIndex,
    employmentIndex
  ) {
    this.personalInfoData[borrowerIndex][employmentType].splice(
      employmentIndex,
      1
    );

    if(this.personalInfoData[borrowerIndex][employmentType].length == 0)
      this.addEmploymentHistory(employmentType, borrowerIndex);
  }

  /**
   * Add residency History Address
   */
  public addResidencyHistory(index) {
    let residencyHistory = new AddressTypeDetail();
    this.personalInfoData[index].personalInfo.address.formerAddress.push(
      residencyHistory
    );
  }
  /**
   * Remove residency history address
   */
  public removeResidencyHistory(borrowerIndex, addressIndex) {
    this.personalInfoData[
      borrowerIndex
    ].personalInfo.address.formerAddress.splice(addressIndex, 1);
    if(this.personalInfoData[borrowerIndex].personalInfo.address.formerAddress.length == 0)
      this.addResidencyHistory(borrowerIndex);
  }

  public addCounselingEvent(borrowerIndex) {    
    this.counselingEvents[borrowerIndex].push(new CounselingEvent());
  }

  public removeCounselingEvent(borrowerIndex, counselingIndex) {
    this.counselingEvents[borrowerIndex].splice(counselingIndex, 1);
  }

  /**
   * make current employment index always 0;
   */
  public changeCurrentEmploymentIndex() {
    this.personalInfoData.borrowerInfo.forEach(element => {
      element.currentEmployment.forEach((element, index) => {
        if (element.currentEmployment == "current" && index != 0) {
          let employmentData = this.personalInfoData.borrowerInfo[0]
            .currentEmployment[0];
          this.personalInfoData.borrowerInfo[0].currentEmployment[0] = element;
          element = employmentData;
        }
      });
    });
  }
  /**
   *   Add new borrower and co-borrowers
   */
  public async addNewBorrower() {
    try {
      this.inProcess = true;
      let value = await this.$refs.addNewBorrower["saveNewBorrower"]();
      if (value.status) {
        this.borrowerData.relationshipMapping = value.borrowerMapping;
        this.personalInfoData = value.borrowerInfo;
        this.changeBorrower(this.borrowerData.primaryBorrowerIndex);
        this.$modal.hide("borrowerInfoModal");
        this.$snotify.success("Information Save Sucessfully");
      }
      this.inProcess = false;
    } catch (error) {
      this.inProcess = false;
      console.log(error);
    }
  }

  /**
   *   Delete Borrower after confermation popup
   */
  public deleteBorrower(index) {
    try {
      let mapping = this.borrowerData.relationshipMapping.find(
        a => a.borrowerIndex == index
      );
      if (mapping) {
        if (mapping.coBorrowerIndex.length > 0) {
          this.$modal.hide("deleteBorrowerModal");
          this.personalInfoData[index].personalInfo.relationWithBorrower =
            "Partner";
          this.$modal.show("unlinkBorrowerModal");
          return;
        }
      }
      this.removeBorrower(null);
    } catch (error) {
      console.log(error);
    }
  }

  public coBorrowerIndex(index) {
    try {
      let mapping = this.borrowerData.relationshipMapping.find(
        a => a.borrowerIndex == index
      );
      if (mapping && mapping.coBorrowerIndex.length > 0)
        return mapping.coBorrowerIndex[0];
      else return null;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *   Delete borrower api call function
   */
  public async removeBorrower(unlinkBorrower) {
    try {
      let relationWithBorrower = null;
      if (unlinkBorrower) {
        let coBorrowerIndex = this.coBorrowerIndex(this.borrowerIndex);
        if (coBorrowerIndex)
          relationWithBorrower = this.personalInfoData[this.borrowerIndex]
            .personalInfo.relationWithBorrower;
      }
      let response = await Axios.post(
        BASE_API_URL + "los/deleteBorrower",
        {
          loanTxnId: this.$route.query.id,
          borrowerIndex: this.borrowerIndex,
          unlinkBorrower: unlinkBorrower,
          relationWithBorrower: relationWithBorrower
        });
      this.$modal.hide("unlinkBorrowerModal");
      this.$modal.hide("deleteBorrowerModal");
      if (response.status == 200) {
        await this.getInformation();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /********************************************************************************************************************************
   *   This function set JointWithCoBorrowerIndex with related borrower and coBorrower(that index show's related borrower Name)   *
   ********************************************************************************************************************************/
  public setJointWithCoBorrowerIndex() {
    try {
      this.borrowerData.relationshipMapping.forEach(el => {
        el.coBorrowerIndex.forEach(cb => {
          this.personalInfoData[
            el.borrowerIndex
          ].personalInfo.jointWithCoBorrowerIndex = cb;
          this.personalInfoData[cb].personalInfo.jointWithCoBorrowerIndex =
            el.borrowerIndex;
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

//********************Checking current employment is less than two year****************** */
  public isLessThanTwoYears() {
    const { currentEmployment = [] } = this.personalInfoData[this.borrowerData.primaryBorrowerIndex];

    this.isCurrentEmploymentLessThanThreeYear = currentEmployment.every((employment) => {
      const { year = "00", month = "00" } = employment.startDate;

      let currentDate = new Date();
      let currYear: number = currentDate.getFullYear();
      let currMonth: number = currentDate.getMonth() + 1;

      let currMonths = (currYear * 12) + currMonth;
      let employmentMonths = (parseInt(year) * 12) + parseInt(month);
      let diffMonths = currMonths - employmentMonths;

      return diffMonths < 24;
    });
  }
  
  //*******************Checking current address duration is greater than two year*****************/
  public currentAddressDurationGreaterThanTwoYear(index) {
    if (index != null) {
      let obj = this.personalInfoData[index].personalInfo.address.currentAddress
        .duration;
      let totalMonth = 0;
      if (obj.years == null && obj.months == null) {
        this.isCurrentAddDurationGreaterThanTwoYear = true;
      }
      if (obj.years || obj.months) {
        if (obj.years && obj.months) {
          totalMonth = parseInt(obj.years) * 12 + parseInt(obj.months);
        } else if (obj.years && !obj.months) {
          totalMonth = parseInt(obj.years) * 12;
        } else if (!obj.years && obj.months) {
          totalMonth = parseInt(obj.months);
        }
        this.isCurrentAddDurationGreaterThanTwoYear =
          totalMonth >= 24 ? true : false;
      }
    }
  }

  //**************  Check Index Have Borrower Or CoBorrower ****************************/
  getUserType(index) {
    if (this.borrowerData) {
      let mapping = this.borrowerData.relationshipMapping.find(
        a => a.borrowerIndex == index
      );
      if (mapping) return "Borrower";
      else return "CoBorrower";
    }
  }

  borrowerList() {
    let borrowerList = [];
    this.borrowerData.relationshipMapping.forEach(el => {
      if (el.coBorrowerIndex.length == 0) {
        let name =
          this.personalInfoData[el.borrowerIndex].personalInfo.name.firstName +
          " " +
          this.personalInfoData[el.borrowerIndex].personalInfo.name.lastName;
        borrowerList.push({ borrowerName: name, index: el.borrowerIndex });
      }
    });
    return borrowerList;
  }

  async mounted() {
    await this.getInformation();
    // this.$parent["checkLosFormCompleted"](true);
  }
}
