

import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { Assets, OtherAssets } from "@/models/pos/financial-info.model";
import {
  ASSET_TYPE,
  MISMO_ASSET_TYPE,
  MISMO_OTHER_ASSET_TYPE,
  MISMO_PURCHASE_CREDIT_TYPE,
  MISMO_GIFTS_TYPE,
  MISMO_GIFTS_FUNDS_SOURCE_TYPE,
  MISMO_ASSET_TYPE_OTHER_ENUM,
  MISMO_PURCHASE_CREDITS_TYPE_OTHER_ENUM,
  MISMO_GIFTS_FUNDS_SOURCE_TYPE_FHA
} from "@/constants";
import customInput from "@/views/CustomInput.vue";
import LosRequiredField from "@/views/LosRequiredField.vue";
import { mixins } from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
import _ from "lodash";


@Component({ components: { customInput, LosRequiredField } })
export default class AssetComponent extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop()
  public loanId;
  @Prop()
  public type;
  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public loanIsLocked;

  public loanTxnId: any = null;
  public financialInfo: any = null;
  public borrowerMapping: any = null;
  public assetsRequiredList = [];
  public isFieldMissing = false;
  public loanPurpose: any = null;
  public enumerationType: string = null;
  public creditInfo: any = null;
  private isMismo: boolean = false;
  public giftsInfo: any = null;
  private initialState: any = {};
  private assetIndex: number = 0;
  public otherAssetsTypeEnum = MISMO_ASSET_TYPE_OTHER_ENUM;
  public otherPurchaseCreditsTypeEnum = MISMO_PURCHASE_CREDITS_TYPE_OTHER_ENUM;
  public isFHAOrVA = false;
  public isFHA = false;
  public giftOtherDescriptionTypes = MISMO_GIFTS_FUNDS_SOURCE_TYPE_FHA;
  public showOtherDescription = false;

  public async getAssetsData() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "los/getAssetsData",
        {
          loanTxnId: this.loanTxnId,
        }
      );

      this.setIsMismo(_.get(response, 'data.borrowerMapping.loanInformation.enumerationType', 'fannieMae'));

      this.giftsInfo = [];

      const splitedAssets = this.splitOtherAssetsFromAssets(
        _.get(response, 'data.assetsData.assets', []),
        _.get(response, "data.assetsData.purchaseCredits", [])
      );

      this.financialInfo = splitedAssets && splitedAssets.assets || [];
      this.borrowerMapping = response.data.borrowerMapping.relationshipMapping;
      this.loanPurpose = response.data.borrowerMapping.loanInformation.purpose;
      this.isFHAOrVA = (response.data.borrowerMapping.loanInformation.productType && 
        (response.data.borrowerMapping.loanInformation.productType.value == 'FHA' ||
        response.data.borrowerMapping.loanInformation.productType.value == 'VA')) || false;
      this.isFHA = (response.data.borrowerMapping.loanInformation.productType && 
        response.data.borrowerMapping.loanInformation.productType.value == 'FHA') || false;
        
      this.financialInfo.forEach(element => this.verifyMappings(element));

      this.creditInfo = splitedAssets.others;
      const giftsInfo = splitedAssets.gifts;
      this.creditInfo.forEach(creditInfo => this.verifyMappings(creditInfo));
      giftsInfo.forEach(gifInfo => {
        this.verifyMappings(gifInfo);
      });
      if (giftsInfo && giftsInfo.length){
        this.giftsInfo = await Promise.all(giftsInfo.map((element, index) => {
          return this.getGiftsValueFromLoanAndProperty(element, index);
        }));
      }

      this.initialState = this.cloneObject(this.getUpdateRequestBody());
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  private setIsMismo(enumerationType) {
    this.enumerationType = enumerationType;
    this.isMismo = (this.enumerationType && this.enumerationType === 'mismo') || false;
  }

  private async getGiftsValueFromLoanAndProperty(gift, index) {  
    let response = await Axios.post(
      BASE_API_URL + "los/getLoanAndPropertyData",
      {
        loanTxnId: this.loanTxnId,
      }
    );
    const loanAndProperty = response.data.loanAndPropertyData;

    const loangift = loanAndProperty.gifts[index];

    if (loangift) {
      gift.isDeposited = loangift.isDeposited === 'true';
      gift.source = loangift.source;
      gift.otherSource = loangift.otherSource;
    }

    return gift;
  }

  private verifyMappings(element) {
    this.borrowerMapping.forEach((el) => {
      if (element.assetsMapping.length == 0) {
        element.assetsMapping.push({
          borrowerIndex: el.borrowerIndex,
          jointStatus: el.borrowerIndex == 0 ? true : false,
        });
      } else {
        let result = element.assetsMapping.find(
          (a) => a.borrowerIndex == el.borrowerIndex
        );
        if (!result) {
          element.assetsMapping.push({
            borrowerIndex: el.borrowerIndex,
            jointStatus: false,
          });
        }
      }
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach((e) => {
          let result = element.assetsMapping.find((a) => a.borrowerIndex == e);
          if (!result) {
            element.assetsMapping.push({
              borrowerIndex: e,
              jointStatus: false,
            });
          }
        });
      }
    });
  }

  public getUpdateRequestBody() {
      let creditInfoAux = [];
      let financialInfoAux = this.financialInfo;
      let giftsInfoAux = [];

      if(this.creditInfo) {
          this.creditInfo.forEach((el) => this.setBorrowerIndex(el));
          creditInfoAux = this.creditInfo;
      }
      if(this.giftsInfo) {
          this.giftsInfo.forEach((el) => this.setBorrowerIndex(el));
          giftsInfoAux = this.giftsInfo;
      }

      let other = creditInfoAux.filter(
          (asset) =>
              MISMO_OTHER_ASSET_TYPE.find(
                  (type) => type.name === _.get(asset, "accountType.name", null)
              ) != undefined
      );

      financialInfoAux = [...other, ...financialInfoAux, ...giftsInfoAux];

      creditInfoAux = creditInfoAux.filter(
          (asset) =>
              MISMO_PURCHASE_CREDIT_TYPE.find(
                  (type) => type.name === _.get(asset, "accountType.name", null)
              ) != undefined
      );

      return {
          loanTxnId: this.loanTxnId,
          assetsInfoDetails: financialInfoAux,
          assetsCreditDetails: creditInfoAux,
          giftsInfoDetails: giftsInfoAux
      }
  }

  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {
      /**
       *    if assets are not joint with other borrowers and CoBorrower's(Not checked any joint button) then assets active index is zero index;
       */
      if(!this.isMismo) {
        this.financialInfo.forEach((el) => this.setBorrowerIndex(el));
      }

      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };

      this.saveInProcess = true;
      this.$store.state.wemloLoader = true;

      let response = await Axios.post(
        BASE_API_URL + "los/saveAssetsData",
          requestBody,
      );
      if (tosty) {
        this.$snotify.clear();
        this.$snotify[response.data.statusCode == 200 ? "success" : "error"](
          response.data.message
        );
      }
      if (response.data.statusCode === 200) {
        this.initialState = this.cloneObject(this.getUpdateRequestBody());
      }
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.state.wemloLoader = false;
      if (!fromParent && this.$parent["checkLosFormCompleted"])
        this.$parent["checkLosFormCompleted"](true);
      return response.data.statusCode == 200 &&
        response.data.assetsError.length > 0
        ? { status: true, requiredFields: response.data.assetsError }
        : { status: true, requiredFields: [] };
      // return true;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      this.saveInProcess = false;
      console.log(error);
      return false;
    }
  }

  private setBorrowerIndex(el) {
    let jointStatus = el.assetsMapping.filter((a) => a.jointStatus == true);
    if (jointStatus.length == 0) {
      el.borrowerIndex = 0;
      el.assetsMapping.forEach((e) => {
        if (e.borrowerIndex == 0) e.jointStatus = true;
      });
    }
  }

  public add(property) {
    let assets = null;
    if (property === "financialInfo") {
      assets = new Assets();
    } else {
      assets = new OtherAssets();
    }

    assets["assetsMapping"] = [];
    this.borrowerMapping.forEach((el) => {
      assets.assetsMapping.push({
        borrowerIndex: el.borrowerIndex,
        jointStatus: el.borrowerIndex == 0 ? true : false,
      });
      if (el.coBorrowerIndex.length > 0) {
        el.coBorrowerIndex.forEach((e) => {
          assets.assetsMapping.push({ borrowerIndex: e, jointStatus: false });
        });
      }
    });
    assets.assetIndex = this.assetIndex++;
    this[property].push(assets);
  }

  /**
   *    check borrower and coBorrower on the basis of index
   */
  getBorrower(index) {
    let haveBorrower = false;
    this.borrowerMapping.forEach((e) => {
      if (index == e.borrowerIndex) {
        haveBorrower = true;
      }
    });
    return haveBorrower ? "Borrower" : "Co-Borrower";
  }

  getAssetTypeList(index) {
    let assetTypes = this.isMismo ? MISMO_ASSET_TYPE : ASSET_TYPE;

    if (_.get(this, "financialInfo[0].accountType.ediValue") === "NE" && index) {
      assetTypes = assetTypes.filter((type) => type.ediValue !== "NE");
    }

    return assetTypes;
  }

  getOtherAssetsOrPurchaseCreditsTypeList() {
    return [...MISMO_PURCHASE_CREDIT_TYPE, ...MISMO_OTHER_ASSET_TYPE];
  }

  getGiftsTypeList() {
    return MISMO_GIFTS_TYPE;
  }

  getGiftsSourceTypeList() {
    return MISMO_GIFTS_FUNDS_SOURCE_TYPE;
  }

  splitOtherAssetsFromAssets(originalAssets, purchaseCredits = []) {
    const others = originalAssets
      ? [
        ...originalAssets.filter(asset =>
          MISMO_OTHER_ASSET_TYPE.find(
            (type) => type.name === _.get(asset, "accountType.name", null)
          ) != undefined
        ),
        ...purchaseCredits
      ]
      : [];

    const assets = originalAssets
      ? originalAssets.filter(asset => {
        return !_.get(asset, "accountType.name", false) ||
        (this.isMismo ? MISMO_ASSET_TYPE : ASSET_TYPE)
          .map(type => type.name)
          .includes(_.get(asset, "accountType.name", null));
      })
      : [];

    const gifts = originalAssets
      ? originalAssets.filter(
          asset =>
            MISMO_GIFTS_TYPE.find(
              (type) => type.name === _.get(asset, "accountType.name", null)
            ) != undefined
        )
      : [];

    return { assets, others, gifts };
  }

  removeAsset(index, splice) {
    this.financialInfo.splice(index,splice);
    if (this.financialInfo.length == 0) {
      this.add('financialInfo');
    }
  }

  checkAssetRequiredFields(asset) {
    if (asset.accountType == null && asset.financialInstitution == null) {
      return false;
    }
    return true;
  }

  async mounted() {
    this.loanTxnId = this.loanId;
    await this.getAssetsData();
  }

  toggleOtherDescription(asset) {
    this.showOtherDescription = asset && asset['source'] && asset['source'].mismoValue === 'Other';
  }
}
