
































import _ from "lodash";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    name: 'UnsavedFields',
    components: { UnsavedFields }
})
class UnsavedFields extends Vue {
    @Prop({ required: true })
    depth!: number;

    @Prop()
    label!: string;

    @Prop()
    nodes: Array<any>;

    public indent() {
        return {
            textIndent: `${this.depth * 20}px`,
        }
    }

    public isNumber(value) {
        return !_.isNaN(value) && !_.isNaN(_.toNumber(value));
    }

    public toNumber(value) {
        return _.toNumber(value);
    }

    public isLastNode() {
        return this.nodes.every(node => _.isEmpty(node.nested));
    }

    public getTitle() {
        return this.nodes.length ? '- '+ this.nodes.map(node => node.title).join(", ") : '';
    }
}

export default UnsavedFields;
