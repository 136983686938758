
const agencyConstant = require('../constants');

export class CreditReport {
    constructor() {
        this.isjointCreditReportRequest = false;
        this.coBorrower = new CoBorrowerDetail();
        // this.agencies = new Array<any>();
        this.agencies = agencyConstant.CREDIT_AGENCIES;
        this.autoPopulateLiabilities = true;
        this.selectedBorrowerId = null;
        this.selectedvendorId = null;
        this.requestType = null;
        this.creditAgency = null;
        this.creditUnderwritingOptions = null;
        this.borrower = null;
        this.method = null;
        this.userNameCredit = null;
        this.passwordCredit = null;
    }
    public selectedvendorId: string;
    public userName: string;
    public password: string;
    public requestType: string;
    public referenceNumber: number;
    public selectedBorrowerId: string;
    public isjointCreditReportRequest: boolean;
    public coBorrower: CoBorrowerDetail;
    public agencies: Array<any>;
    public autoPopulateLiabilities: boolean;
    public creditAgency: number;
    public creditUnderwritingOptions: string;
    public borrower: string;
    public method: string;
    public userNameCredit: string;
    public passwordCredit: string;
}

class CoBorrowerDetail {
    constructor() {
        this.name = null;
        this.userId = null
    }
    public name: string;
    public userId: string;
}

export class Borrower {
    constructor() {
        this.name = null;
        this.userId = null;
        this.isBorrower = null;
        this.isSelected = null;
    }
    public name: string;
    public userId: string;
    public isBorrower: boolean;
    public isSelected: boolean;
}