


import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { OtherCredit } from "@/models/pos/transaction-detail.model";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import { TRANSACTION_OTHER_CREDITS, MISMO_TRANSACTION_OTHER_CREDITS, DOWN_PAYMENT_SOURCE } from "@/constants";
import {
  SourceOfDownPayment,
  ConstructionCase,
  RefinanceCase
} from "@/models/pos/loan-and-property.model";
import LosRequiredField from "@/views/LosRequiredField.vue";
import { mixins } from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
const _ = require("lodash");


@Component({ components: {LosRequiredField } })
export default class TransactionComponent extends mixins(LOSMixin, SaveInformationMixin) {
  public transactionOtherCredits = [];
  public sourceOfDownpayment = DOWN_PAYMENT_SOURCE;
  public transactionDetailValueAtoHArr = new Array(8);
  public transactionDetailValueMToNArr = new Array(2);
  public cashFromToBorrowerValueArr = new Array(5);
  public otherCreditArray = new OtherCredit();
  public sourceOfDownPaymentArray = new SourceOfDownPayment();
  public transactionDetails = null;
  public loanAndProperty: any = null;
  public leApproved = false;
  public viewTransactionDetails = true;
  public viewDownPayment = true;
  public transactionRequiredList = [];
  public isFieldMissing = false;
  public isMismo: boolean = null;
  private initialState: any = {};
  public productType: any = {};

  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public loanIsLocked;

  async getEnumerationType() {
    this.$store.commit('SET_WEMLO_LOADER', true);
    const { data: { enumerationType } } = await Axios.post(
      BASE_API_URL + 'los/getEnumerationType',
      { loanTxnId: this.$route.query.id }
    );
    this.$store.commit('SET_WEMLO_LOADER', false);

    return enumerationType;
  }

  get loanAmount() {
    return _.get(this, 'loanAndProperty.loanAmount', 0);
  }

  get purchasePrice() {
    return _.get(this, 'transactionDetails.purchasePrice', 0);
  }

  get isLoanAmountBiggerThanPurchasePrice() {
    return this.loanAmount > this.purchasePrice;
  }

  get isPurchasePriceValid() {
    if (this.errors.has('purchasePrice')) {
      return false;
    }

    if (this.isLoanAmountBiggerThanPurchasePrice) {
      return false;
    }

    return true;
  }

  get isRefinanceInitialValueIsNotZero() {
    const { loanPurpose } = this.loanAndProperty;
    return loanPurpose && loanPurpose.ediValue === '05';
  }

  isRefinanceImprovementCostsAmountVisible() {
    const { loanPurpose } = this.loanAndProperty;
    return loanPurpose && loanPurpose.ediValue === '05' && this.productType.mismoValue === 'FHA';
  }

  public async getTransactionData() {
    try {
      this.$store.commit('SET_WEMLO_LOADER', true);
      let response = await Axios.post(
        BASE_API_URL + "los/getTransactionData",
        {
          loanTxnId: this.$route.query.id
        });
      if (response.status == 200) {
        this.transactionDetails = response.data.transactionDetails;
        this.loanAndProperty = response.data.loanAndProperty;
        this.leApproved = response.data.leApproved;
        this.productType = response.data.productType;

        if (_.isEmpty(this.transactionDetails.otherCredits)) {
          this.newCreditType();
        }
      }
      if(this.isSubmitToWemlo){
          this.viewTransactionDetails = true;
          this.viewDownPayment = true;
      }
      this.initialState = this.cloneObject(this.getUpdateRequestBody());
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.commit('SET_WEMLO_LOADER', false);
    }
  }

  public getUpdateRequestBody(){
    return {
      loanTxnId: this.$route.query.id,
      transactionDetails: this.transactionDetails,
      loanAndProperty: this.loanAndProperty,
    };
  }

  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid || (this.loanAndProperty.loanPurpose && this.loanAndProperty.loanPurpose.ediValue != '05' ? this.loanAndProperty.loanAmount > this.transactionDetails.purchasePrice : false)) {
        this.viewDownPayment = true;
      }
      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };

      this.$store.commit('SET_WEMLO_LOADER', true);
      this.saveInProcess = true;
      let response = await Axios.post(
        BASE_API_URL + "los/updateTransactionData",
          requestBody);
      if(tosty){
        this.$snotify.clear();
        this.$snotify[response.data.statusCode == 200 ? 'success' : 'error'](response.data.message);
      }
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.commit('SET_WEMLO_LOADER', false);
      if (response.data.statusCode === 200) {
          this.initialState = this.cloneObject(requestBody);
      }
      if(!fromParent  && this.$parent["checkLosFormCompleted"]){
          this.$parent["checkLosFormCompleted"](true);
      }
      return response.data.statusCode == 200 && response.data.transactionRequiredList.length>0 ? {status: true, requiredFields: response.data.transactionRequiredList} : {status: true, requiredFields: [] };
    } catch (error) {
      this.$store.commit('SET_WEMLO_LOADER', false);
      this.saveInProcess = false;
      console.log(error);
      return {status: false};
    }
  }

  public onLoanAmountInput() {
    const { loanAmount } = this.loanAndProperty;
    this.cashFromToBorrowerValueArr[3] = loanAmount;
    this.transactionDetailValueMToNArr[0] = loanAmount;
    this.updateLosHeaderInfo();
  }

  public onFundingFeeFinancedInput() {
    const { fundingFeeFinanced } = this.transactionDetails;
    this.cashFromToBorrowerValueArr[4] = fundingFeeFinanced;
    this.transactionDetailValueMToNArr[1] = fundingFeeFinanced;
  }
// 

  /**
   *    Add credit type
   */
  public newCreditType() {
    let newOtherCreditType = new OtherCredit();
    this.transactionDetails.otherCredits.push(newOtherCreditType);
  }

  /**
   *    Remove credit type
   */
  public removeCreditType(index) {
    this.transactionDetails.otherCredits.splice(index,1)
    if (_.isEmpty(this.transactionDetails.otherCredits)) {
      this.newCreditType();
    }
  }

  public shouldValidateCreditType() {
    if (this.transactionDetails.otherCredits.length > 1) {
      return true;
    }

    if (_.get(this.transactionDetails, "otherCredits[0].amount", 0)) {
      return true;
    }
    
    return false;
  }

  /**
   *   Add sourceOfDownPayment
   */
  public newSourceOfDownPayment() {
    let sourceOfDownPaymentTypeObj = new SourceOfDownPayment();
    this.loanAndProperty.sourceOfDownPayment.push(
      sourceOfDownPaymentTypeObj
    );
  }

  public removeSourceOfDownPayment(index) {
    this.loanAndProperty.sourceOfDownPayment.splice(index,1);
    if(this.loanAndProperty.sourceOfDownPayment.length == 0)
      this.newSourceOfDownPayment();
  }
  
  public getCashFromToBorrower(){
    // if(!this.transactionDetails.estimatedCashToClose || !this.leApproved){
      return this.getSum(this.transactionDetailValueAtoHArr)-(this.getSum(this.cashFromToBorrowerValueArr)+  this.getSum(this.transactionDetails.otherCredits,'amount'))
    // }else{
      // return this.transactionDetails.estimatedCashToClose
    // }
  }

  updateLosHeaderInfo(){
    let data = {
      loanAmount: parseFloat(this.loanAndProperty.loanAmount).toFixed(2),
      purchasePrice: parseFloat(this.loanAndProperty.propertyAddress.propertyValue).toFixed(2) 
    }
    this.$emit("callMountFunction",data);
  }

  public async calculateTransactionFundingFee() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "los/calculateTransactionFundingFee",
        {
          loanTxnId: this.$route.query.id,
        });
      if (response.status == 200) {
        this.transactionDetails.fundingFee = response.data.value;
        this.transactionDetailValueAtoHArr[6] = this.transactionDetails.fundingFee;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    await this.getTransactionData();

    this.isMismo = (await this.getEnumerationType()) === 'mismo';
    this.transactionOtherCredits = this.isMismo ? MISMO_TRANSACTION_OTHER_CREDITS : TRANSACTION_OTHER_CREDITS;
    this.viewDownPayment = false;
  }
}
