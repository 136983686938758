






import { Component, Vue, Prop } from "vue-property-decorator";
import DisclosuresPackage from "@/components/disclosures-package/DisclosuresPackage.vue";

@Component({ components: { DisclosuresPackage } })
export default class ManageDisclosures extends Vue {
    @Prop()
    public loanIsLocked;

    public async saveInformation(tosty, fromParent, checkUnsaved = false) {
        try {
            const { status, unsavedFields = [] } = await this.$refs.child["submit"](checkUnsaved);
            return {
                status,
                unsavedFields,
            };
        } catch(error) {
            console.log(error);
        }
    }
}
