


















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import DtiAndHousingRatioDetails from "@/views/DtiAndHousingRatioDetails.vue";
import LOSMixin from "@/mixins/los.mixin";
import { LOSRadialBar } from "@/views/charts/radial-bar/los-radial-bar";
import _ from "lodash";


@Component({
  components: {
    DtiAndHousingRatioDetails
  }
})
export default class losHeaderDataComponent extends mixins(LOSMixin) {
  public radialBar: LOSRadialBar;
  public loanTxnId = null;
  public losHeaderData: any = null;
  public ShowDtiValue = false;

  public async getHeaderData() {
    this.$store.state.wemloLoader = true;
    try {
      if (!this.$store.state.sessionObject.token) {
        this.$store.state.wemloLoader = false;
        return;
      }

      let response = await Axios.post(
        BASE_API_URL + "los/getLOSHeaderData",
        {
          loanTxnId: this.$route.query.id
        }
      );
      if (response.status == 200) {
        this.losHeaderData = response.data;
      }

      if (!this.losHeaderData) { return; }
      
      if (this.radialBar) {
        this.radialBar.updateRadialBar(
          this.losHeaderData.DTIRatio > 1000 ? 0 : this.losHeaderData.DTIRatio,
          this.losHeaderData.housingRatio
        );
      } else {
        this.radialBar = new LOSRadialBar(
          this.losHeaderData.DTIRatio > 1000 ? 0 : this.losHeaderData.DTIRatio,
          this.losHeaderData.housingRatio,
          "radialBar"
        );
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  public updateHeaderDetails(data, tabName) {
    try {
      if (this.losHeaderData) {
        if (
          tabName == "General" ||
          tabName == "LoanAndProperty" ||
          tabName == "Expense" ||
          tabName == "Income" ||
          tabName == "Transaction" ||
          tabName == "RealEstateOwned"
        ) {
          Object.keys(data).forEach(key => {
            this.losHeaderData[key] = data[key];
          });
          this.calculateCLTV();
          this.calculateHcltv();
        } else if (tabName == "Liabilities") {
          this.losHeaderData.liabilities = data.liabilitiesTotal;
          this.losHeaderData.helocLiabilitiesMaxAmount =
            data.helocLiabilitiesMaxAmount;
          this.losHeaderData.helocLiabilitiesAmount =
            data.helocLiabilitiesAmount;
          this.losHeaderData.mortgageLoanLiabilitiesAmount =
            data.mortgageLoanLiabilitiesAmount;
          this.calculateCLTV();
        }
        this.calculateDtiAndHousingRatio();
      }
    } catch (error) {
      console.log(error);
    }
  }

  calculateCLTV() {
    let cltv: any = 0.0;
    let ltv: any = 0.0;
    if (this.losHeaderData.loanAmount && this.losHeaderData.purchasePrice) {
      ltv =
        (this.losHeaderData.loanAmount / this.losHeaderData.purchasePrice) *
        100;
      ltv = ltv && !isNaN(ltv) && isFinite(ltv) ? ltv : "0.00";
      let loanAmount: any = parseFloat(this.losHeaderData.loanAmount);
      if (this.losHeaderData.helocLiabilitiesAmount) {
        loanAmount =
          loanAmount + parseFloat(this.losHeaderData.helocLiabilitiesAmount);
      }
      cltv = (loanAmount / this.losHeaderData.purchasePrice) * 100;
      cltv = cltv && !isNaN(cltv) && isFinite(cltv) ? cltv : "0.00";
    }
    ltv = parseFloat(ltv).toFixed(2);
    cltv = parseFloat(cltv).toFixed(2);
    this.losHeaderData.LTV = ltv;
    this.losHeaderData.CLTV = cltv;
    this.calculateHcltv();
  }

  calculateHcltv() {
    let hcltv: any = 0.0;
    let loanAmount: any = parseFloat(this.losHeaderData.loanAmount);
    let purchasePrice: any = parseFloat(this.losHeaderData.purchasePrice);
    if (loanAmount && purchasePrice) {
      if (
        this.losHeaderData.helocLiabilitiesMaxAmount ||
        this.losHeaderData.mortgageLoanLiabilitiesAmount
      ) {
        loanAmount =
          loanAmount +
          parseFloat(this.losHeaderData.helocLiabilitiesMaxAmount) +
          parseFloat(this.losHeaderData.mortgageLoanLiabilitiesAmount);
      }
      hcltv = (loanAmount / this.losHeaderData.purchasePrice) * 100;
      hcltv = hcltv && !isNaN(hcltv) && isFinite(hcltv) ? hcltv : "0.00";
    }
    hcltv = parseFloat(hcltv).toFixed(2);
    this.losHeaderData.HCLTV = hcltv;
  }

  public calculateDtiAndHousingRatio() {
    try {
      let housingRatio: any = 0;
      let dtiRatio: any = 0;
      //************* Calculate housing Ratio ***********/
      let totalIncome: any =
        parseFloat(this.losHeaderData.grossMonthlyIncome) +
        parseFloat(this.losHeaderData.rentalIncomeGrossAmount);
      if (isFinite(this.losHeaderData.proposedExpense) && isFinite(totalIncome))
        housingRatio = (this.losHeaderData.proposedExpense / totalIncome) * 100;
      housingRatio =
        !isNaN(housingRatio) && isFinite(housingRatio) && housingRatio
          ? housingRatio
          : 0;
      this.losHeaderData.housingRatio = housingRatio.toFixed(2);

      //********* Calculate DTI Ratio ****************/
      let liabilities: any = 0;
      // if(this.losHeaderData.loanPurpose ? this.losHeaderData.loanPurpose.ediValue == '05' : false){
      liabilities = this.losHeaderData.liabilities;
      // }
      let totalExpense: any =
        parseFloat(this.losHeaderData.proposedExpense) +
        parseFloat(this.losHeaderData.maintenanceExpenseAmount) +
        parseFloat(liabilities);
      // let totalIncome:any = parseFloat(this.losHeaderData.grossMonthlyIncome) + parseFloat(this.losHeaderData.rentalIncomeGrossAmount);
      if (isFinite(totalExpense) && isFinite(totalIncome)) {
        dtiRatio = (totalExpense / totalIncome) * 100;
        dtiRatio = parseFloat(dtiRatio).toFixed(2);
        if (!(dtiRatio - Math.floor(dtiRatio))) {
          dtiRatio = Math.round(dtiRatio);
        }
        dtiRatio =
          !isNaN(dtiRatio) && isFinite(dtiRatio) && dtiRatio ? dtiRatio : 0;
        this.losHeaderData.DTIRatio = dtiRatio;
      }
      
      this.updatelosHeaderData();
    } catch (error) {
      console.log(error);
    }
  }

  /*********************************************************************************************************************************
   *        This function update radial bar(los-radial-bar) DTI and Housing ratio                                                  *
   *********************************************************************************************************************************/

  lastDTIValues = null;

  public async updatelosHeaderData() {
    try {

      const loanInformation = {
        loanAmount: this.losHeaderData.loanAmount,
        interestRate: this.losHeaderData.interestRate,
        loanTerm: this.losHeaderData.loanTerm,
      };

      if (_.isEqual(this.lastDTIValues, loanInformation)) {
        return;
      }

      this.lastDTIValues = loanInformation;

      const { data: { dtiValue, housingRatio } } = await Axios.post(
        BASE_API_URL + "los/calculateOrSimulateDtiValue",
        {
          loanTxnId: this.$route.query.id,
          loanInformation: {
            loanAmount: this.losHeaderData.loanAmount,
            interestRate: this.losHeaderData.interestRate,
            loanTerm: this.losHeaderData.loanTerm,
         }
        }
      );

      this.losHeaderData.DTIRatio = dtiValue;
      this.losHeaderData.housingRatio = housingRatio;

      if (this.radialBar) {
        this.radialBar.updateRadialBar(dtiValue > 1000 ? 0 : this.losHeaderData.DTIRatio, this.losHeaderData.housingRatio);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async modalForDtiRatio() {
    try {
      this.ShowDtiValue = true;
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.lastDTIValues = null;
    await this.getHeaderData();
  }
}
