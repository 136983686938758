

import {Component, Prop} from "vue-property-decorator";
import {BASE_API_URL} from "@/config";
import Axios from "axios";

import {
  PROPERTY_TYPE,
  REAL_ESTATE_STATUS_TYPE,
  MISMO_REAL_ESTATE_STATUS_TYPE,
  REAL_ESTATE,
  ANOTHER_PROPERTY_TYPE
} from "@/constants";
import { Properties } from "@/models/pos/financial-info.model";
import { Address } from "@/models/pos/common.model";
import AddressComponent from "@/views/Address.vue";
import { mixins } from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";
import Multiselect from "vue-multiselect";
import _ from "lodash";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";


@Component({ components: { AddressComponent, Multiselect } })
export default class RealEstateOwnedComponent extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop({default: false})
  public isMismo!: boolean;
  @Prop()
  public loanId;
  @Prop()
  public type;
  @Prop()
  public loanIsLocked;

  public loanTxnId: any = null;
  public property = PROPERTY_TYPE;
  public realEstateStatus = this.isMismo ? MISMO_REAL_ESTATE_STATUS_TYPE : REAL_ESTATE_STATUS_TYPE;
  public realEstate = REAL_ESTATE;
  public financialInfo: any = null;
  public numbers = [1, 2, 3, 4];
  public realEstateIndex: any = null;
  public address = new Address();
  public mortgage = [];
  public loanPurpose:any = null;
  public borrowerMapping: any = {
    relationshipMapping : []
  };
  public borrowers = [];
  public enumerationType: string = null;
  private initialState: any = {};
  public usageTypes = ANOTHER_PROPERTY_TYPE;

  public async getRealEstateOwnedData() {
    try {
      this.$store.state.wemloLoader = true;

      const response = await Axios.post(
        BASE_API_URL + "los/getRealEstateOwnedData",
        {
          loanTxnId: this.loanTxnId
        }
      );
      if (response.status == 200) {
        this.borrowerMapping = _.get(response, 'data.borrowerMapping.relationshipMapping', []);
        this.financialInfo = response.data.financialInfo;
        this.loanPurpose = response.data.loanPurpose;

        this.borrowers = _.get(response, 'data.borrowers', []);

        this.financialInfo.realEstate.properties.forEach(property => {
          property.reoMapping = this.borrowers.map((borrower, borrowerIndex) => {
            const mapping = _.get(property, 'reoMapping', []).find(mapping => _.get(mapping, 'borrowerIndex', false) === borrowerIndex);

            if (mapping) { return mapping; }

            return {
              borrowerIndex,
              jointStatus: false
            }
          });

          return property;
        });
      }
      this.initialState = this.cloneObject(this.getUpdateRequestBody());
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
    }
  }

  public getUpdateRequestBody(){
      return {
          loanTxnId: this.loanTxnId,
          financialInfo: this.financialInfo
      }
  }

  public async saveInformation(tosty, fromParent, checkUnsaved = false) {
    try {
      await this.$validator.validateAll("reo");

      const requestBody = this.getUpdateRequestBody();
      const unsavedFields = this.difference(requestBody, this.initialState);
      if (unsavedFields && checkUnsaved) return { unsavedFields };
      this.$store.state.wemloLoader = true;
      this.saveInProcess = true;
      let response = await Axios.post(
        BASE_API_URL + "los/saveRealEstateOwned",
          requestBody,
        );
      if(tosty){
          this.notifyTosty(response.data.statusCode, response.data.message);
      }
      this.$emit("saveEvent");
      this.saveInProcess = false;
      this.$store.state.wemloLoader = false;
      if (response.data.statusCode === 200){
          this.initialState = this.cloneObject(requestBody);
      }
       if(!fromParent && this.$parent["checkLosFormCompleted"]){
           this.$parent["checkLosFormCompleted"](true);
       }
      return { status: true, requiredFields: [] }; // for POS
    } catch (error) {
      this.saveInProcess = false;
      console.log(error);
      return { status: false };
    }
  }

  public shouldValidate() {
    const REOs = _.get(this.financialInfo, "realEstate.properties", []);
    const firstREO = REOs[0];
    
    if (REOs.length >= 2) {
      return true;
    }

    if (!this.isREOEmpty(firstREO)) {
      return true;
    }

    return false;
  }

  public isREOEmpty(REO) {
    if (!REO) {
      return true;
    }

    const {address, currentProperty, mortgageLoans = [], numberOfUnits, maintenanceExpenseAmount, propertyType, propertyValue, rentalIncomeGrossAmount, status} = REO;

    if (this.checkFieldsAddress(address)) {
      return false;
    }
    
    if (currentProperty || numberOfUnits || maintenanceExpenseAmount || propertyValue || rentalIncomeGrossAmount || propertyType || status || mortgageLoans.length) {
      return false;
    }

    return true;
  }

  public resetAddress(data) {
    data.city = null;
    data.country = null;
    data.county = null;
    data.street = null;
    data.street = null;
    data.unit = null;
    data.zip = null;
    if (data.state != null) {
      data.state = null;
      data.code = null;
    }
  }

  public checkFieldsAddress(address) {
    let fieldsEmpty = true;
    let allNull = Object.values(address).every(
      value => value == null || value == ""
    );
    if (allNull) {
      return (fieldsEmpty = false);
    } else {
      return (fieldsEmpty = true);
    }
  }

  public haveAddress(address) {
    return Object.values(address).some(value => value !== null);
  }

  public handleEditAddress(realState, index) {
    if (this.loanIsLocked) return;
    this.showAddressModal(realState.address, index);
  }

  public handleAddAddress(realState, index) {
    if (this.loanIsLocked) return;
    this.showAddressModal(realState.address, index);
    this.financialInfo.realEstate.properties[index].haveAddress = true;
  }

  public async showAddressModal(data, realEstateIndex) {
    this.$modal.show("addressPopup");
    this.realEstateIndex = JSON.parse(JSON.stringify(realEstateIndex));
    this.address = JSON.parse(JSON.stringify(data));
  }

  public async addBorrowerAddress() {
    try {
      let valid:any = await this.$refs.address['$validator'].validateAll();
      if(!valid)
        return;
      this.financialInfo.realEstate.properties[this.realEstateIndex].address = JSON.parse(JSON.stringify(this.address));
      this.$modal.hide("addressPopup");
      this.realEstateIndex = null;
      this.address = null;
    } catch (error) {
      console.log(error);
    }
  }

  public addProperty() {
    let realEstate = new Properties();
    realEstate.mortgageLoans = [];
    realEstate.reoMapping = this.borrowers.map((borrower, borrowerIndex) => {
      return {
        borrowerIndex,
        jointStatus: false,
      }
    });

    this.financialInfo.realEstate.properties.push({
      haveAddress: true,
      ...realEstate
    });
  }

  removeRealEstate(index) {
    this.financialInfo.liabilities.forEach(el => {
      if (index == el.reoAssetId) el.reoAssetId = null;
    });

    this.financialInfo.realEstate.properties.splice(index, 1);
    this.updateLosHeaderInfo();

    if (!this.financialInfo.realEstate.properties.length) {
      this.addProperty();
    }
  }

  getBorrowerTypeByIndex(index) {
    let haveBorrower = false;

    this.borrowerMapping.forEach(e => {
      if (index == e.borrowerIndex) {
        haveBorrower = true;
      }
    });

    return haveBorrower ? "Borrower" : "Co-Borrower";
  }

  /***********************************************************************************************************************************
   *
   ***********************************************************************************************************************************/
  addLiabilitiesWithREO(index) {
    this.financialInfo.liabilities.forEach(el => {
      if (index == el.reoIndex) el.reoIndex = null;
    });
    let liabilitiesIndex = this.financialInfo.realEstate.properties[index]
      .liabilitiesIndex;
    if (liabilitiesIndex != null)
      this.financialInfo.liabilities[liabilitiesIndex].reoIndex = index;
  }

  updateLosHeaderInfo() {
    let data = {
      maintenanceExpenseAmount: 0,
      monthlyPayment: 0,
      rentalIncomeGrossAmount: 0
    };
    let realEstate = this.financialInfo.realEstate.properties.filter(a => a.status && a.status.ediValue != 'S' && a.status.ediValue != 'P');
    data.maintenanceExpenseAmount = this.getSum(realEstate,"maintenanceExpenseAmount");
    realEstate = realEstate.filter(a=> a.status.ediValue != 'R' || !a.currentProperty);
    data.rentalIncomeGrossAmount = this.getSum(realEstate,"rentalIncomeGrossAmount");
    this.$emit("callMountFunction", data);
  }

  getLiabilitiesName(index) {
    try {
      let selectedLiabilitiesName = this.financialInfo.liabilities.filter(
        a =>
          (a.accountType && a.accountType.name == "Mortgage Loan") ||
          (a.accountType && a.accountType.name == "HELOC")
      );
      let loan = [];
      selectedLiabilitiesName.forEach(e => {
        if(e.companyName){
          let mortgageData = {
          unpaidBalance: {
            toBePaidAtOrOffClosing: null,
            amount: e.unpaidBalance.amount
          },
          mortgageType: e.accountType ? e.accountType.value : null,
          accountNumber: e.accountNumber,
          creditorName: e.companyName,
          currentFirstMortgageHolderType: null,
          proposedMonthlyPaymentAmount: null,
          monthlyPaymentAmount: parseFloat(e.monthlyPayment),
          creditLimit: null,
          _id: e._id
        };
        loan.push(mortgageData);
        }
      });
      let arr = [];
      this.financialInfo.realEstate.properties.forEach(a=>{
        a.mortgageLoans.forEach(s=>arr.push(s));
      });
      loan = loan.filter(l=>arr.find(m=>l.creditorName == m.creditorName && l.accountNumber == m.accountNumber)?false:true);
    return loan;
    } catch (error) {
      console.log(error);
    }
  }

  getMonthlyPaymentTotal() {
    let total = 0;
    this.financialInfo.realEstate.properties.forEach(element => {
      let sum = this.getSum(element.mortgageLoans, "monthlyPaymentAmount");
      total = total + sum;
    });
    return total;
  }

  getRentalIncome(index) {
    const realEstate = this.financialInfo.realEstate.properties[index];

    if (
      (this.isMismo && _.get(realEstate, 'status.mismoValue', '') === 'Retain'
      && _.get(realEstate, 'propertyUsageType.mismoValue', '') === 'Investment')
      || (!this.isMismo && _.get(realEstate, 'status.ediValue', '') === "R")
    ) {
      const mortgagePayment = this.getSum(realEstate.mortgageLoans, "monthlyPaymentAmount");
      this.financialInfo.realEstate.properties[index].rentalIncomeNetAmount =
          realEstate.rentalIncomeGrossAmount - (mortgagePayment + realEstate.maintenanceExpenseAmount);
      return;
    }
    
    this.financialInfo.realEstate.properties[index].rentalIncomeNetAmount = 0;
  }

  setSubjectPropertyValue(index){
    this.financialInfo.realEstate.properties.forEach((el,i)=>{
      if(i != index)
        el.subjectProperty = false;
    });
  }

  async mounted() {
    this.loanTxnId = this.loanId;
    await this.getRealEstateOwnedData();
  }
}
